
import styled from 'styled-components';

const textbox_Height = "15";
const textbox_Margin = "5px 0 5px 0";

const TextboxWithLabelContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : textbox_Margin};

    .text-box-label {
        display: grid;
        grid-column: ${props => props.labelStart}/${props => props.labelEnd};
        justify-self: right;
        align-self: center;
        padding-right: 5px;
    }

    .text-box-input {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        height: ${textbox_Height}px;
    }
`

// TEMPLATE
// <TextboxWithLabel    className="Class name of entire box"
//                      inputType="Type of input box"
//                      name="Name of input"
//                      label="Label to display"
//                      placeholder="Placeholder display"
//                      value="Controlling value for the text box"
//                      handleTextbox="Function that controls the input"
//                      columns="Number of columns in label/textbox row"
//                      labelStart="Start of label, right aligned"
//                      labelEnd="End of label, right aligned"
//                      inputStart="Start of textbox, left aligned" 
//                      inputEnd="End of textbox, left aligned"/>
// END

export const TextboxWithLabel = (props) => {
    const inputType = props.inputType ? props.inputType : "text";
    return (
        <TextboxWithLabelContainer className={props.className}  margin={props.margin} columns={props.columns} labelStart={props.labelStart} labelEnd={props.labelEnd} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <label className="text-box-label" htmlFor={props.name}>{props.label}</label>
            <input className="text-box-input" id={props.inputId} type={inputType} autoComplete="on" name={props.name} placeholder={props.placeholder} value={props.value} onClick={props.onClick} onChange={props.handleTextbox}/>
        </TextboxWithLabelContainer>
    )
}

const TextboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : textbox_Margin};

    .text-box-input {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        height: ${textbox_Height}px;
    }
`

// TEMPLATE
// <TextboxWithLabel    className="Class name of entire box"
//                      inputType="Type of input box"
//                      name="Name of input"
//                      placeholder="Placeholder display"
//                      value="Controlling value for the text box"
//                      handleTextbox="Function that controls the input"
//                      columns="Number of columns in label/textbox row"
//                      inputStart="Start of textbox, left aligned" 
//                      inputEnd="End of textbox, left aligned"/>
// END

export const Textbox = (props) => {
    const inputType = props.inputType ? props.inputType : "text";
    return (
        <TextboxContainer className={props.className} margin={props.margin} columns={props.columns} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <input className="text-box-input" type={inputType} autoComplete="on" name={props.name} placeholder={props.placeholder} value={props.value} onChange={props.handleTextbox}/>
        </TextboxContainer>
    )
}
