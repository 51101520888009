
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// const dropdown_Height = "22";
const datebox_Margin = "5px 0 5px 0";
const datebox_Height = "20";
const datebox_Width = "200";

const DateboxWithLabelContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : datebox_Margin};

    .datebox-label {
        display: grid;
        grid-column: ${props => props.labelStart}/${props => props.labelEnd};
        justify-self: right;
        align-self: center;
        padding-right: 5px;
    }

    .datebox-date-select {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        z-index: 40;
    }

    .date-picker{
        width: ${props => props.width ? props.width : datebox_Width};
        height: ${props => props.height ? props.height : datebox_Height};
    }
`

const DateboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : datebox_Margin};

    .datebox-date-select {
        display: grid;
        justify-content: center;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        z-index: 40;
    }

    .date-picker{
        width: ${props => props.width ? props.width : datebox_Width};
        height: ${props => props.height ? props.height : datebox_Height};
    }
`

// TEMPLATE
// <DateboxWithLabel className="Class name of entire box" 
//                   label="Label to display" 
//                   selected="Value to set the Datebox to"
//                   handleDatebox="Function that processes the selection" 
//                   enableTabLoop="Controls if the date box will be included in tabbing through the page"
//                   margins="Top right bottom left margins"
//                   columns="Number of columns in label/textbox row"
//                   labelStart="Start of label, right aligned"
//                   labelEnd="End of label, right aligned"
//                   inputStart="Start of textbox, left aligned" 
//                   inputEnd="End of textbox, left aligned"/>
// END

export const DateboxWithLabel = (props) => {
    const enableTabLoop = props.enableTabLoop ? props.enableTabLoop : false;
    return (
        <DateboxWithLabelContainer className={props.className} width={props.width} height={props.height} margin={props.margin} columns={props.columns} labelStart={props.labelStart} labelEnd={props.labelEnd} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <label className="datebox-label">{props.label}</label>
            <div className="datebox-date-select">
                <DatePicker className="date-picker" enableTabLoop={enableTabLoop} selected={props.selected} onChange={props.handleDatebox}/>
            </div>
        </DateboxWithLabelContainer>
    );
}

export const Datebox = (props) => {
    const enableTabLoop = props.enableTabLoop ? props.enableTabLoop : false;
    return (
        <DateboxContainer className={props.className} width={props.width} height={props.height} margin={props.margin} columns={props.columns} labelStart={props.labelStart} labelEnd={props.labelEnd} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <div className="datebox-date-select">
                <DatePicker className="date-picker" enableTabLoop={enableTabLoop} selected={props.selected} onChange={props.handleDatebox}/>
            </div>
        </DateboxContainer>
    );
}