import {useState} from 'react';
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import EWAs from '../../classes/Ewas';
import Employees from '../../classes/Employees.js';
import Auth from '../authentication/Auth.js';
import { Button } from '../utilities/Button.js';
import PagesData, { Pages } from '../utilities/Pages.js';
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable';
import svcLogo from './../../img/HeaderLogo.png'
import { LoadingMessage } from '../utilities/LoadingMessage';
import MainHeader from '../utilities/MainHeader.js';
import { DropDownWithLabel, DropDown } from '../utilities/DropDown.js';
import {DateboxWithLabel} from '../utilities/Datebox.js';
import { TextboxWithLabel, Textbox } from '../utilities/Textbox.js';
import Popup, {PopupFailureColor, PopupWarningColor, PopupSuccessColor} from '../utilities/Popup.js';
import PopupOk, {PopupOkWarningColor} from '../utilities/PopupOk';
import HoverPopup from '../utilities/HoverPopup.js';
import EWAContainer from './styles/EWAContainer';
import Globals from '../utilities/Globals.js';
import dayjs from "dayjs";
import Phases from "../../classes/Phases";
import printjs from 'print-js';

const EWA = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        jobId: '',
        lotId: '',
        company: '',
        employeeId: '',
        approvalStatus: '',
        completedStatus: '',
        dateType: "schedule.schedule_date",
        startDate: null,
        endDate: null
    });

    const [sortOption, setSortOption]= useState({
        type: 'jobNumber',
        order: 'ascending'
    });

    const [crews, setCrews] = useState([]);

    const [ewaCodes, setEwaCodes] = useState([])
    const [ewaCodesNewEWA, setEwaCodesNewEWA] = useState([])
    
    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);

    const [lotsForFilterDropDown, setLotsForFilterDropDown] = useState([]);

    const [ewas, setEWAs] = useState([]);
    const [createNewEWAs, setCreateNewEWAs] = useState(false);
    const [newEWA, setNewEWA] = useState([]);
    const [editEWA, setEditEWA] = useState(null);
    const [approvedEWA, setApprovedEWA] = useState(null);
    const [declinedEWA, setDeclinedEWA] = useState(null);
    const [completedEWA, setCompletedEWA] = useState(null);
    
    const [ewasToPrint, setEWAsToPrint] = useState([]);
    const [reprintEWA, setReprintEWA] = useState(false);

    const [allUsers, setAllUsers] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [employeesForFilterDropDown, setEmployeesForFilterDropDown] = useState([]);
    const [employeesForEditEWADropDown, setEmployeesForEditEWADropDown] = useState([]); 

    const [displayLoadingMessage, setDisplayLoadingMessage] = useState(false);

    const [approvalPopupDisplay, setApprovalPopupDisplay] = useState(null); 
    const [completionPopupDisplay, setCompletionPopupDisplay] = useState(false); 

    const [pagesData, setPagesData] = useState(PagesData.createPagesData());

    const [warningNoLotsFromSelectedFilters, setWarningNoLotsFromSelectedFilters] = useState(false);
    const [errorNoFiltersSelected, setErrorNoFiltersSelected] = useState(false);
    const [errorsInNewEWA, setErrorsInNewEWA] = useState(false);
    const [errorNoLotSelected, setErrorNoLotSelected] = useState(false);
    const [errorNoPhaseCodeSelected, setErrorNoPhaseCodeSelected] = useState(false);
    const [errorNoJobDescription, setErrorNoJobDescription] = useState(false);
    const [errorNoInternalEstimateOrBuilderPO, setErrorNoInternalEstimateOrBuilderPO] = useState(false);
    const [errorNoScheduledDate, setErrorNoScheduledDate] = useState(false);
    const [errorNoAmountEntered, setErrorNoAmountEntered] = useState(false);
    const [errorNoEmployeeSelected, setErrorNoEmployeeSelected] = useState(false);

    const [newEwaIsBillable, setNewEwaIsBillable] = useState(false);
    const [editEwaIsBillable, setEditEwaIsBillable] = useState(false);

    const [newEWAsuccess, setNewEWASuccess] = useState(false);
    const [editEWAsuccess, setEditEWASuccess] = useState(false);

    const canPrintFirstCopy = props.isAuthorized('ewa', 'WRITE');
    const canCreateEditEWA = props.isAuthorized('ewa', 'WRITE');
    const canReprintEWA = props.isAuthorized('ewaReprint', 'WRITE');
    
    // const canEditApproveDeclineCompleteEWA = props.isAuthorized('ewaApproveAndComplete', 'WRITE');
    // const canEditCompleteEWA = props.isAuthorized('ewa', 'WRITE');

    const canApproveEWA = props.isAuthorized('ewaApprove', 'WRITE');
    const canCompleteEWA = props.isAuthorized('ewaComplete', 'WRITE');

    const [phases, setPhases] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('')

    const togglePopup = () => {
        setWarningNoLotsFromSelectedFilters(false); 
        setErrorNoFiltersSelected(false);
        setErrorsInNewEWA(false);
        setErrorNoLotSelected(false);
        setErrorNoPhaseCodeSelected(false);
        setErrorNoJobDescription(false);
        setErrorNoInternalEstimateOrBuilderPO(false);
        setErrorNoScheduledDate(false);
        setErrorNoAmountEntered(false);
        setErrorNoEmployeeSelected(false);
        setNewEWASuccess(false);
        setEditEWASuccess(false);
    }

    const getEmployeeName = (id) => {
        const employee = allEmployees.find(employee => employee.id === id);
        let rtn = employee ? `${employee.alias !== null ? employee.alias : ''} ${employee.first_name} ${employee.last_name}` : '';
        return rtn.trim()
    }

    const getUserName = (id) => {
        const user = allUsers.find(user => user.id === id);
        return user ? `${user.firstname} ${user.lastname}` : '';
    }

    const getUserInitals = (id) => {
        const user = allUsers.find(user => user.id === id);
        return user ? `${user.firstname[0]}.${user.lastname[0]}.` : '';
    }

    const loading = (isLoading) => {
        setDisplayLoadingMessage(isLoading);
    }

    const sortAndSetEWAs = (ewas) => {
        const firstValue = sortOption.order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        const tempEWA =  ewas.sort((eli1, eli2) => {
            if(sortOption.type === "jobNumber"){
                if(Number.parseInt(eli1.line_items[0].lot.job.number) === Number.parseInt(eli2.line_items[0].lot.job.number)){
                    return Number.parseInt(eli1.line_items[0].lot.number) < Number.parseInt(eli2.line_items[0].lot.number) ? firstValue : secondValue;
                }
                return Number.parseInt(eli1.line_items[0].lot.job.number) < Number.parseInt(eli2.line_items[0].lot.job.number) ? firstValue : secondValue;
            }
            if(sortOption.type === "ewaEmployee"){
                if(eli1.line_items[0].crew.employees[0].employee.first_name === eli2.line_items[0].crew.employees[0].employee.first_name){
                    return eli1.line_items[0].crew.employees[0].employee.last_name < eli2.line_items[0].crew.employees[0].employee.last_name ? firstValue : secondValue;
                }
                return eli1.line_items[0].crew.employees[0].employee.first_name < eli2.line_items[0].crew.employees[0].employee.first_name ? firstValue : secondValue;
            }
            if(sortOption.type === "ewaScheduledDate"){
                return (new Date(eli1.line_items[0].schedule_date)) < (new Date(eli2.line_items[0].schedule_date)) ? firstValue : secondValue;
            }
            if(sortOption.type === "ewaCreatedDate"){
                return (new Date(eli1.created_at)) < (new Date(eli2.created_at)) ? firstValue : secondValue;
            }
            return firstValue;
        });

        setEWAs([...tempEWA]);
    }

    const applyFilters = async () => {
        loading(true);

        if(filterOptions.jobId.toString() === '' &&
           filterOptions.lotId === '' &&
           filterOptions.company === '' &&
           filterOptions.employeeId === '' &&
           filterOptions.approvalStatus === '' &&
           filterOptions.completedStatus === '' &&
           filterOptions.startDate === null &&
           filterOptions.endDate === null){
            setErrorNoFiltersSelected(true);
            loading(false);
            return;
        }

        let whereStr = '';
        if(filterOptions.jobId !== ""){ whereStr += `job.id=${filterOptions.jobId} and `; }
        if(filterOptions.lotId !== ""){ whereStr += `lot.id=${filterOptions.lotId} and `; }
        if(filterOptions.employeeId !== ""){ whereStr += `schedule_crew_employee.employee_id=${filterOptions.employeeId} and `; }
        if(filterOptions.approvalStatus === "Needs Review"){ whereStr += `ewa.approval_by is null and `; }
        if(filterOptions.approvalStatus === "Approved"){ whereStr += `ewa.approved=1 and ewa.approval_by is not null and `; }
        if(filterOptions.approvalStatus === "Declined"){ whereStr += `ewa.approved=0 and ewa.approval_by is not null and `; }
        if(filterOptions.completedStatus === "Completed"){ whereStr += `schedule.complete=1 and `; }
        if(filterOptions.completedStatus === "Not Completed"){ whereStr += `(schedule.complete=0 or schedule.complete is null) and (schedule.approved=1 or schedule.approval_by is null) and `; }
        if(filterOptions.company === '1'){ whereStr += `(employee.svc=1 or employee.sub_concrete=1) and `}
        if(filterOptions.company === '2'){ whereStr += `(employee.svp=1 or employee.sub_plumbing=1) and `}
        if(filterOptions.startDate !== null){
            if(filterOptions.dateType === 'approval_date'){ whereStr += `schedule.approved=1 and schedule.approval_date>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD')}' and `; }
            else if(filterOptions.dateType === 'declined'){ whereStr += `schedule.approved=0 and schedule.approval_date>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD')}' and `; }
            else{ whereStr += `${filterOptions.dateType}>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD')}' and `; }
        }
        if(filterOptions.endDate !== null){
            if(filterOptions.dateType === 'approval_date'){ whereStr += `schedule.approved=1 and schedule.approval_date<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD')}' and `; }
            else if(filterOptions.dateType === 'declined'){ whereStr += `schedule.approved=0 and schedule.approval_date<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD')}' and `; }
            else{ whereStr += `${filterOptions.dateType}<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD')}' and `; }
        }

        // Trim 'and' off end of whereStr
        if(whereStr !== ''){
            whereStr = whereStr.slice(0, whereStr.length - 5);
        }

        const tempEWA = await Auth.hitEndpointNew("GET", "ewas/ewa-page", `whereStr=${whereStr}`);
        if(tempEWA.length === 0){
            setWarningNoLotsFromSelectedFilters(true);
            setEWAs([]);
        }
        else{
            sortAndSetEWAs(tempEWA);
        }

        pagesData.currentPage = 1;
        loading(false);
    }

    const loadData = async () => {
        setDidLoadData(true);

        setEwaCodes(await EWAs.loadEWACodes(null));

        setNewEWA([{
            newEWA: await EWAs.createNewEWA(null),
            jobId: "",
            lotsForDropDown: [],
            employeesForDropDown: []
        }]);

        setAllUsers(await Auth.hitEndpoint("GET", "USERS"));

        setAllEmployees(await Employees.loadEmployees(null));
        setEmployeesForFilterDropDown(await Employees.loadEmployeesForDropDown(null));

        setPhases(await Phases.loadPhases(null))
        setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));

        setCrews(await Auth.hitEndpointNew("GET", "crews"));
    }

    if(!didLoadData){
        loadData();
    }

    const selectJobforEWAFilter = async (e) => {
        if(e.target.value === ''){
            setLotsForFilterDropDown([]);
            setFilterOptions({...filterOptions, jobId: "", lotId: ""});
            return;
        }
        setFilterOptions({...filterOptions, jobId: e.target.value, lotId: ""});
        setLotsForFilterDropDown(await Lots.loadLotsForDropDown({jobId: e.target.value}, e.target.value));
    }

    const handleAddingNewEWALineItem = async () => {
        setNewEWA([
            ...newEWA, 
            {
                newEWA: await EWAs.createNewEWA(null),
                jobId: "",
                lotsForDropDown: [],
                employeesForDropDown: []
            }
        ]);
    } 

    const handleRemovingNewEWALineItem = (index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA.splice(index, 1);
        setNewEWA([...tempNewEWA]);
    }

    const selectJobForNewEWA = async (e, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].jobId = e.target.value;
        tempNewEWA[index].lotsForDropDown = await Lots.loadLotsForDropDown({jobId: e.target.value}, e.target.value);
        setNewEWA([...tempNewEWA]);
    }

    const selectLotForNewEWA = async (e, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].newEWA.lot_id = e.target.value;
        tempNewEWA[index].newEWA.line_items[0].lot_id = e.target.value;
        setNewEWA([...tempNewEWA]);
    }

    const selectPhaseForNewEWA = async (e, index) => {
        const value = Number.parseInt(e.target.value);
        const company = phases.find(phase => phase.id === value).company;
        let tempEmployeesList;
        // if(company === "1" || company === 'SVC'){// 1 is SVC
        //     const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
        //     tempEmployeesList = Employees.convertEmployeesToDropDown(tempEmp);
        // }
        // if(company === "2" || company === "SVP"){// 2 is SVP
        //     const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
        //     tempEmployeesList = Employees.convertEmployeesToDropDown(tempEmp);
        // }
        setEwaCodesNewEWA(await EWAs.loadEWACodes({"phase_task_phases.phase_id": e.target.value}))
        const tempNewEWA = [...newEWA];
        // tempNewEWA[index].employeesForDropDown = tempEmployeesList;
        tempNewEWA[index].newEWA.line_items[0].phase_id = value;
        setNewEWA([...tempNewEWA]);
    }

    const selectPhaseCodeForNewEWA = async (e, index) => {
        const value = Number.parseInt(e.target.value);
        let code = ewaCodes.find(code => code.id === value);
        const company = code.phases[0].field_id;
        if(!newEwaIsBillable) {
            setNewEwaIsBillable(code.billable === 1)
        }
        let tempEmployeesList;
        if(company === "1" || company === "3" || company === "4" || company === "5" || company === "6" || company === "7" || company === "8" ){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            tempEmployeesList = Employees.convertEmployeesToDropDown(tempEmp);
        }
        if(company === "2"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            tempEmployeesList = Employees.convertEmployeesToDropDown(tempEmp);
        }
        if(company === "17"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({framing: 1})}`);
            tempEmployeesList = Employees.convertEmployeesToDropDown(tempEmp);
        }
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].employeesForDropDown = tempEmployeesList;
        tempNewEWA[index].newEWA.line_items[0].phase_task_id = value;
        setNewEWA([...tempNewEWA]);
    }

    const setDescriptionNewEWA = (e, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].newEWA.line_items[0].notes = e.target.value;
        setNewEWA([...tempNewEWA]);
    }

    const setInternalEstimateNumberNewEWA = (e, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].newEWA.internal_estimate_number = e.target.value;
        setNewEWA([...tempNewEWA]);
    }

    const setBuilderPONumberNewEWA = (e, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].newEWA.builder_po_number = e.target.value;
        setNewEWA([...tempNewEWA]);
    }

    const setDateNewEWA = (date, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].newEWA.line_items[0].schedule_date = date;
        setNewEWA([...tempNewEWA]);
    }

    const setAmountNewEWA = (e, index) => {
        const tempNewEWA = [...newEWA];
        tempNewEWA[index].newEWA.line_items[0].payout = e.target.value;
        setNewEWA([...tempNewEWA]);
    }

    const selectEmployeeNewEWA = (e, index) => {
        const tempNewEWA = [...newEWA];
        let employee = allEmployees.find(x => parseInt(x.id) === parseInt(e.target.value))
        if(employee !== undefined) {
            tempNewEWA[index].newEWA.line_items[0].crew.employees[0].employee_id = e.target.value;
            tempNewEWA[index].newEWA.line_items[0].crew.crew_id = employee.membership.crew_id;
        }
        else{
            tempNewEWA[index].newEWA.line_items[0].crew.employees[0].employee_id = null;
            tempNewEWA[index].newEWA.line_items[0].crew.crew_id = null;
        }
        setNewEWA([...tempNewEWA]);
    }

    const createNewEWAErrorObj = (errorCode) => {
        return {
            lot: errorCode === "ERROR_NO_LOT_SELECTED" ? "error" : "",
            phaseCode: errorCode === "ERROR_NO_PHASE_CODE" ? "error" : "",
            description: errorCode === "ERROR_NO_DESCRIPTION" ? "error" : "",
            scheduledDate: errorCode === "ERROR_NO_SCHEDULED_DATE" ? "error" : "",
            payout: errorCode === "ERROR_NO_PAYOUT_AMOUNT" ? "error" : "",
            employee: errorCode === "ERROR_NO_EMPLOYEE_SELECTED" ? "error" : "",
        }
    }

    const handleAddingNewEWAs = async () => {
        const tempNewEWA = [...newEWA];
        
        let hasErrors = false;
        for(let i = 0; i < tempNewEWA.length; i++){
            let codeId = tempNewEWA[i].newEWA.line_items[0].ewa_code_id
            let code = ewaCodes.find(x => x.id === codeId)
            let billable = false
            if(code !== undefined){
                billable = code.billable === 1
            }
            let result = await EWAs.checkEWAForErrorsNew(tempNewEWA[i], billable);
            if(result !== "SUCCESS"){
                tempNewEWA[i].errors = createNewEWAErrorObj(result);
                hasErrors = true;
            }
        }

        if(hasErrors){ 
            setNewEWA([...tempNewEWA]);
            setErrorsInNewEWA(true);
            return; 
        }

        for(let i = 0; i < tempNewEWA.length; i++){
            tempNewEWA[i].newEWA.created_at = moment(new Date(tempNewEWA[i].newEWA.created_at)).format('YYYY-MM-DD H:mm:ss');
            tempNewEWA[i].newEWA.line_items[0].schedule_date = moment(new Date(tempNewEWA[i].newEWA.line_items[0].schedule_date)).format('YYYY-MM-DD H:mm:ss');
        }
        await Auth.hitEndpointNew("POST", `ewas`, '', tempNewEWA.map(lineItem => lineItem.newEWA));

        setCreateNewEWAs(false);
        setEwaCodesNewEWA([])
        setNewEWA([{
            newEWA: await EWAs.createNewEWA(null),
            jobId: "",
            lotsForDropDown: [],
            employeesForDropDown: []
        }]);
        setNewEWASuccess(true);

        await applyFilters();
    }

    const handleCancelNewEWAs = async () => {
        setNewEWA([{
            newEWA: await EWAs.createNewEWA(null),
            jobId: "",
            lotsForDropDown: [],
            employeesForDropDown: []
        }]);
        setCreateNewEWAs(false);
        setEwaCodesNewEWA([])
    }

    const setupEWAEdit = async (ewa) => {
        await loadEmployeesForEdit(ewa.line_items[0].phase_task_id)
        ewa.line_items[0].schedule_date = new Date(ewa.line_items[0].schedule_date);
        setEditEWA({...ewa});
    }

    const selectPhaseEditEWA = async (e) => {
        const value = Number.parseInt(e.target.value);
        const company = phases.find(phase => phase.id === value).company;
        if(company === "1" || company === "SVC"){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            setEmployeesForEditEWADropDown(Employees.convertEmployeesToDropDown(tempEmp));
        }
        if(company === "2" || company === "SVP"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            setEmployeesForEditEWADropDown(Employees.convertEmployeesToDropDown(tempEmp));
        }

        const tempEditEWALineItem = {...editEWA};
        tempEditEWALineItem.line_items[0].phase_id = value;
        setEditEWA({...tempEditEWALineItem});
    }

    const selectPhaseCodeEditEWA = async (e) => {
        const value = Number.parseInt(e.target.value);
        await loadEmployeesForEdit(value)
        const tempEditEWALineItem = {...editEWA};
        tempEditEWALineItem.line_items[0].phase_task_id = value;
        tempEditEWALineItem.line_items[0].task = ewaCodes.find(code => code.id === value);
        setEditEWA({...tempEditEWALineItem});
    }

    const loadEmployeesForEdit = async (taskId) => {
        let code = ewaCodes.find(code => code.id === taskId);
        const company = code.phases[0].field_id;
        setEditEwaIsBillable(code.billable === 1)

        if(company === "1" || company === "3" || company === "4" || company === "5" || company === "6" || company === "7" || company === "8"){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            setEmployeesForEditEWADropDown(Employees.convertEmployeesToDropDown(tempEmp));
        }
        if(company === "2"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            setEmployeesForEditEWADropDown(Employees.convertEmployeesToDropDown(tempEmp));
        }
        if(company === "17"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({framing: 1})}`);
            setEmployeesForEditEWADropDown(Employees.convertEmployeesToDropDown(tempEmp));
        }
    }

    const setEditEWAValue = (memberName, value, isOnLineItem) => {
        const tempEditEWALineItem = editEWA;
        if(isOnLineItem) {
            tempEditEWALineItem.line_items[0][memberName] = value;
        }
        else{
            tempEditEWALineItem[memberName] = value;
        }
        setEditEWA({...tempEditEWALineItem});
    }

    const selectEmployeeEditEWA = (e) => {
        const value = Number.parseInt(e.target.value);
        const selectedEmployee = employeesForEditEWADropDown.find(emp => emp.id === value);
        let selectedCrew = crews.find(c => {
            for(let i = 0; i < c.members.length; i++){
                if(parseInt(c.members[i].employee.id) === parseInt(selectedEmployee.id)){
                    return true;
                }
            }
            return false;
        })
        if(selectedCrew === undefined){
            selectedCrew = {
                id: null
            }
        }

        const tempEditEWALineItem = {...editEWA};
        tempEditEWALineItem.line_items[0].crew.crew_id = selectedCrew.id;
        tempEditEWALineItem.line_items[0].crew.employees[0].employee_id = selectedEmployee.id;
        tempEditEWALineItem.line_items[0].crew.employees[0].employee = selectedEmployee;
        setEditEWA({...tempEditEWALineItem});
    }

    const handleErrorCode = (errorCode) => {
        switch(errorCode){
            case "ERROR_NO_LOT_SELECTED": setErrorNoLotSelected(true);break;
            case "ERROR_NO_PHASE_CODE": setErrorNoPhaseCodeSelected(true);break;
            case "ERROR_NO_DESCRIPTION": setErrorNoJobDescription(true);break;
            case "ERROR_NO_INTERNAL_ESTIMATE_OR_BUILDER_PO" : setErrorNoInternalEstimateOrBuilderPO(true);break;
            case "ERROR_NO_SCHEDULED_DATE": setErrorNoScheduledDate(true);break;
            case "ERROR_NO_PAYOUT_AMOUNT": setErrorNoAmountEntered(true);break;
            case "ERROR_NO_EMPLOYEE_SELECTED": setErrorNoEmployeeSelected(true);break;
            default: break;
        }
    }

    const saveEditedEWA = async (ewa) => {
        ewa.modified_by = await Auth.getUserId();
        ewa.modified_at = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
        ewa.created_at = dayjs(new Date(ewa.created_at)).format('YYYY-MM-DD H:mm:ss');

        await Auth.hitEndpointNew("PATCH", 'ewa', '', ewa);
        await applyFilters();
    }

    const handleUpdatingEditEWA = async () => {
        let codeId = editEWA.line_items[0].phase_task_id
        let code = ewaCodes.find(x => x.id === codeId)
        let billable = false
        if(code !== undefined){
            billable = code.billable === 1
        }
        const result = await EWAs.checkEWAForErrorsNew(editEWA, billable);
        if(result !== "SUCCESS"){
            handleErrorCode(result);
            return;
        }
        await saveEditedEWA(editEWA);

        setEditEWA(null); 
        setEditEWASuccess(true);
    }

    const markEWAApproved = async (e) => {
        let approvalDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setApprovedEWA(null);
            return;
        }

        const tempApproveEWA = approvedEWA;
        tempApproveEWA.approved = 1;
        tempApproveEWA.approval_by = await Auth.getUserId();
        tempApproveEWA.approval_at = approvalDate;
        tempApproveEWA.line_items[0].approved = 1;
        tempApproveEWA.line_items[0].approval_by = await Auth.getUserId();
        tempApproveEWA.line_items[0].approval_date = approvalDate;

        await saveEditedEWA(tempApproveEWA);
        
        setApprovedEWA(null);
    }

    const markEWADeclined = async (e) => {
        let approvalDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setDeclinedEWA(null);
            return;
        }

        const tempDeclinedEWA = declinedEWA;
        tempDeclinedEWA.approved = 0;
        tempDeclinedEWA.approval_by = await Auth.getUserId();
        tempDeclinedEWA.approval_at = approvalDate;
        tempDeclinedEWA.line_items[0].approved = 0;
        tempDeclinedEWA.line_items[0].approval_by = await Auth.getUserId();
        tempDeclinedEWA.line_items[0].approval_date = approvalDate;
        
        await saveEditedEWA(tempDeclinedEWA);

        setDeclinedEWA(null);
    }

    const markEWACompleted = async (e) => {
        let completedDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setCompletedEWA(null);
            return;
        }

        const tempCompletedEWA = completedEWA;
        tempCompletedEWA.line_items[0].complete = 1;
        tempCompletedEWA.line_items[0].completed_by = await Auth.getUserId();
        tempCompletedEWA.line_items[0].completed_date = completedDate;
        
        await saveEditedEWA(tempCompletedEWA);

        setCompletedEWA(null);
    }

    const addEWAToPrint = (ewa) => {
        if(!canPrintFirstCopy){return;}

        let tempEWAToPrint = [...ewasToPrint];
        const onListToPrint = tempEWAToPrint.find(eli => eli.id === ewa.id);
        if(onListToPrint === undefined){
            tempEWAToPrint.push(ewa);
        }
        else{
            tempEWAToPrint = tempEWAToPrint.filter(eli => eli.id !== ewa.id);
        }
        setEWAsToPrint([...tempEWAToPrint]);
    }

    const printEWAs = async (isReprint) => {
        if(ewasToPrint.length === 0){
            return;
        }

        const columns = [
            {dataKey: 'project_name',               header: `Project Name`},
            {dataKey: 'job_number',                 header: `Job #`},
            {dataKey: 'lot_number',                 header: `Lot #`},
            {dataKey: 'lot_phase',                  header: `Lot Phase`},
            {dataKey: 'task',                       header: `Phase Code`},
            {dataKey: 'notes',                      header: `Notes`},
            {dataKey: 'internal_estimate_number',   header: `Internal Estimate #`},
            {dataKey: 'builder_po_number',          header: `Builder PO #`},
            {dataKey: 'created_by',                 header: `Created`},
            {dataKey: 'approval_by',                header: `Approved`},
            {dataKey: 'payout',                     header: `Payout`},
        ];

        const columnStyles = {
            project_name: {cellWidth: 25},
            job_number: {cellWidth: 11, halign: 'center'},
            lot_number: {cellWidth: 11, halign: 'center'},
            lot_phase: {cellWidth: 16, halign: 'center'},
            task: {cellWidth: 20, halign: 'center'},
            notes: {cellWidth: 68},
            internal_estimate_number: {cellWidth: 28, halign: 'center'},
            builder_po_number: {cellWidth: 28, halign: 'center'},
            created_by: {cellWidth: 13, halign: 'center'},
            approval_by: {cellWidth: 13, halign: 'center'},
            payout: {cellWidth: 18, halign: 'right'},
        }

        // Sort ewa's into employee names
        let ewaLines = []
        let leads = []
        for(let i = 0; i < ewasToPrint.length; i++) {
            let ewa = ewasToPrint[i]
            for (let ii = 0; ii < ewa.line_items.length; ii++) {
                let item = ewa.line_items[ii]
                let lead = item.crew.employees.filter(x => x.is_lead === 1)
                if (lead === undefined || lead.length === 0) {
                    lead = [item.crew.employees[0]]
                }
                let employee = lead[0].employee
                let existingEmp = leads.find(x => x.employee_id === employee.id)
                if(existingEmp === undefined){
                    leads.push({
                        employee_id: employee.id,
                        crew_id: item.crew.crew_id,
                        employee: employee,
                    })
                }

                ewaLines.push({
                    id: item.id,
                    employee_id: employee.id,
                    project_name: item.lot.job.project_name,
                    job_number: item.lot.job.number,
                    lot_number: item.lot.number,
                    lot_phase: item.lot.phase,
                    task: item.task.code,
                    notes: item.notes,
                    internal_estimate_number: ewa.internal_estimate_number === null ? '' : ewa.internal_estimate_number,
                    builder_po_number: ewa.builder_po_number === null ? '' : ewa.builder_po_number,
                    created_by: getUserInitals(ewa.created_by),
                    approval_by: getUserInitals(item.approval_by),
                    payout: item.payout.toString(),
                })
            }
        }
        ewaLines = ewaLines.sort((a, b) => a.employee_id - b.employee_id || a.id - b.id)

        // Set up printData for autotable display
        const tableData = leads.map(l => {
            let lines = ewaLines.filter(x => x.employee_id === l.employee_id)
            // Calculate totals of each employee's payouts
            let total = lines.reduce(function(prev, cur) {
                return prev + parseFloat(cur.payout);
            }, 0);
            return {
                employee: {
                    name: `${l.employee.first_name} ${l.employee.last_name}`,
                    crews: l.employee.crews

                },
                lineItems: lines,
                total: total.toString()
            }
        })

        // Create pdf
        // autotable only supports pt units
        let doc = new jsPDF({ format: [280, 216], orientation: 'l'});
        for(let i = 0; i < tableData.length; i++){
            autoTable(doc, {
                rowPageBreak: 'avoid',
                columns: columns,
                body: tableData[i].lineItems,
                foot:[{
                    "approval_by": 'Total', 
                    "payout": tableData[i].total
                }],
                showFoot: 'lastPage',
                theme: 'grid',
                headStyles: {
                    fillColor: [180, 180, 180],
                    lineColor: 0,
                    lineWidth: .4,
                    halign: 'center'
                },
                columnStyles: columnStyles,
                bodyStyles: {
                    lineColor: 0,
                    lineWidth: .4,
                },
                footStyles: {
                    fillColor: 'white',
                    textColor: [0, 0, 0],
                    lineColor: 0,
                    lineWidth: .4,
                    fontSize: 10,
                    fontStyle: 'normal',
                    payout: {halign: 'right'}
                },
                margin: {
                    top: 40
                },
                didParseCell: (data) => {
                    if(data.section === 'foot'){
                        data.cell.styles.fillColor = 0;
                    }
                    if(data.column.dataKey === 'approval_by' && data.section === 'foot'){
                        data.cell.styles.fillColor = 'white';
                        data.cell.styles.halign = 'right';
                    }
                    if(data.column.dataKey === 'payout') {
                        if(parseFloat(data.cell.text) < 0){
                            data.cell.styles.fontStyle = 'bold'
                        }
                        if (data.section === 'foot') {
                            data.cell.styles.fillColor = 'white';
                            data.cell.styles.halign = 'right';
                            data.cell.styles.lineColor = 0;
                        }
                    }
                },
                didDrawCell: (data) => {
                    if(data.column.dataKey === 'payout'){
                        const posX = data.cell.x + 2;
                        const posY = data.cell.y + (data.cell.contentHeight / 2);
                        doc.text('$', posX, posY, {align: 'left', baseline: 'middle'});
                        if(parseFloat(data.cell.text) < 0){
                            doc.setFont(doc.getFont().fontName, 'bold')
                            doc.setFontSize(15)
                            doc.text('*', posX + data.cell.width, posY, {align: 'left', baseline: 'middle'});
                            doc.setFont(doc.getFont().fontName, 'normal')
                            doc.setFontSize(10)
                        }
                    }
                },
                didDrawPage: (data) => {
                    const middleOptions = {align: 'center', baseline: 'middle'};
                    const bottomLeftOptions = {align: 'left', baseline: 'bottom'};
                    const bottomRightOptions = {align: 'right', baseline: 'bottom'};

                    if(isReprint){
                        doc.setTextColor('#bbbbbb');
                        doc.saveGraphicsState();
                        doc.setGState(new doc.GState({opacity: 0.3}));
                        doc.setFontSize(168);
                        doc.setFont(doc.getFont().fontName, 'bold');
                        doc.text('REPRINT', 140, 65, middleOptions);
                        doc.setFont(doc.getFont().fontName, 'normal');
                        doc.restoreGraphicsState();
                    }

                    // Header
                    doc.addImage(svcLogo, 'PNG', 10, 5, 39, 20, 'NONE', 'NONE', 0);

                    doc.setFontSize(22);
                    doc.setFont(doc.getFont().fontName, 'bold');
                    doc.setTextColor('#000000');
                    doc.text('Extra Work Authorization', 140, 15, middleOptions);
                    doc.setFont(doc.getFont().fontName, 'normal');

                    doc.setFontSize(10);
                    doc.text("Employee:", 20, 38, bottomLeftOptions);
                    doc.text(tableData[i].employee.name, 38, 38, bottomLeftOptions);

                    doc.text("Crew(s):", 120, 38, bottomLeftOptions);
                    doc.text(tableData[i].employee.crews, 135, 38, bottomLeftOptions);

                    doc.text(dayjs(new Date()).format('MM/DD/YYYY'), 260, 38, bottomRightOptions);


                    doc.text('page', 265, 211, bottomRightOptions);
                    doc.text(data.pageNumber.toString(), 265, 211, bottomLeftOptions);
                }
            });

            if(i + 1 < tableData.length){
                doc.addPage({ orientation: 'l'});
            }
        }
        
        const data = doc.output('blob');
        const blobURL = URL.createObjectURL(data);
        printjs(blobURL);

        // Mark ewa line items as printed and save
        const tempEWAToPrint = [...ewasToPrint];
        let printedDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
        for(let i = 0; i < tempEWAToPrint.length; i++){
            tempEWAToPrint[i].printed = 1;
            tempEWAToPrint[i].printed_by = await Auth.getUserId();
            tempEWAToPrint[i].printed_at = printedDate;

            tempEWAToPrint[i].created_at = dayjs(new Date(tempEWAToPrint[i].created_at)).format('YYYY-MM-DD H:mm:ss');
            tempEWAToPrint[i].modified_by = await Auth.getUserId();
            tempEWAToPrint[i].modified_at = printedDate;

            await Auth.hitEndpointNew("PATCH", 'ewa', '', tempEWAToPrint[i]);
        }
        
        setEWAsToPrint([]);
        await applyFilters();
    }

    const startReprintEWA = (ewa) => {
        if(!canReprintEWA){return;}
        setReprintEWA(true);
        setEWAsToPrint([ewa]);
    }

    const handleReprintEWA = (e) => {
        if(e.target.value === 'cancel'){
            setEWAsToPrint([]);
            setReprintEWA(false);
            return;
        }

        setReprintEWA(false);
        printEWAs(true);
    }

    const exportEWAsToXLSX = async (e) => {
        document.getElementById('id-export-to-xlsx-button').innerHTML = 'Exporting...';

        const exportData = [];
        for(let i = 0; i < ewas.length; i++){
            for(let li = 0; li < ewas[i].line_items.length; li++) {
                let item = ewas[i].line_items[li]
                let emp = item.crew.employees.filter(x => x.is_lead === 1)
                if(emp === undefined || emp.length === 0){ emp = [item.crew.employees[0]] }
                let employee = emp[0].employee
                exportData.push({
                    "Printed By": getUserName(ewas[i].printed_by),
                    "Printed Date": Globals.formatDateToDisplay(ewas[i].printed_at),
                    "Job Name": item.lot.job.project_name,
                    "Job #": item.lot.job.number,
                    "Lot #": item.lot.number,
                    "Lot Phase": item.lot.phase,
                    "Phase Code": item.task.code,
                    "Description": item.notes,
                    "Payout": item.payout,
                    "Internal Est #": ewas[i].internal_estimate_number,
                    "Builder PO #": ewas[i].builder_po_number,
                    "Scheduled On": Globals.formatDateToDisplay(item.schedule_date),
                    "Crew #": employee.crews,
                    "Employee": getEmployeeName(employee.id),
                    "Created By": getUserName(ewas[i].created_by),
                    "Created Date": Globals.formatDateToDisplay(ewas[i].created_at),
                    "Approved By": ewas[i].approved === 1 && ewas[i].approval_by ? getUserName(ewas[i].approval_by) : '',
                    "Approved Date": ewas[i].approved === 1 && ewas[i].approval_by ? Globals.formatDateToDisplay(ewas[i].approval_date) : '',
                    "Declined By": ewas[i].approved === 0 && ewas[i].approval_by ? getUserName(ewas[i].approval_by) : '',
                    "Declined Date": ewas[i].approved === 0 && ewas[i].approval_by ? Globals.formatDateToDisplay(ewas[i].approval_date) : '',
                    "Completed By": getUserName(item.completed_by),
                    "Completed Date": Globals.formatDateToDisplay(item.completed_date),
                });
            }
        }

        Globals.exportToXLSXFileFormat(exportData, 'EWAs', './EWAs.xlsx');

        document.getElementById('id-export-to-xlsx-button').innerHTML = 'Export To XLSX';
    }

    const printingContent = (ewa, position) => {
        const className = ewa.printed_by !== null ? `grid-table-row-data ${position} far-left gray-out` : `grid-table-row-data ${position} far-left`;

        // "D" for declined ewa's
        if(ewa && ewa.approved === 0 && ewa.approval_by !== null){
            return (
                <div className={className}>
                    <div>D</div>
                </div>
            )
        }

        // Empty, needs approved, declined and/or completed
        if(ewa && (ewa.approval_by === null || ewa.line_items[0].completed_by === null)){
            return (
                <div className={className}>
                    <div></div>
                </div>
            )
        }

        // Checkbox for printing
        if(ewa && ewa.printed_by === null && ewa.approved === 1 && ewa.line_items[0].complete === 1){
            const onListToPrint = ewasToPrint.find(ewaToFind => ewaToFind.id === ewa.id);
            return (
                <div className={className}>
                    <input className="print" type="checkbox" name='print' checked={onListToPrint ? true : false} onChange={(e) => addEWAToPrint(ewa)}/>
                </div>
            )
        }
        
        // Grayed out after printing
        if(ewa && ewa.printed_by !== null){
            return (
                <div className={className} onClick={(e) => startReprintEWA(ewa)}>
                    <div></div>
                </div>
            )
        }

        // Fallback display error
        return (
            <div className={className}>
                <div className='red'>ERR</div>
            </div>
        )
    }

    const approvalContent = (ewa, position) => {
        // Not approved and no permissions to approve
        if(ewa && ewa.approval_by === null && !canApproveEWA){
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div>Needs Approval</div>
                </div>
            )
        }

        // Not approved, and has permissions to approve
        if(ewa && ewa.approval_by === null && canApproveEWA){
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div className='approve-decline-box'>
                        <Button 
                            className="approve-ewa" 
                            handleKeyPress={((e) => {if(e.key === 'Enter'){setApprovedEWA(ewa)}})}
                            handleClick={((e) => setApprovedEWA(ewa))}
                            buttonText="Approve" 
                            buttonMargin="0 0 0 0" 
                            width="80"  
                            height="22"
                        />
                        <Button 
                            className="decline-ewa" 
                            handleKeyPress={((e) => {if(e.key === 'Enter'){setDeclinedEWA(ewa)}})}
                            handleClick={((e) => setDeclinedEWA(ewa))}
                            buttonText="Decline" 
                            buttonMargin="0 0 0 0" 
                            width="80" 
                            height="22"
                        />
                    </div>
                </div>
            )
        }

        // Has been approved
        if(ewa && ewa.approved === 1 && ewa.approval_by !== null){
            const approvedBy = allUsers.find(user => user.id === Number.parseInt(ewa.approval_by));
            const showPopup = approvalPopupDisplay && approvalPopupDisplay.id === ewa.id;
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div onMouseEnter={(e) => setApprovalPopupDisplay(ewa)} onMouseLeave={(e) => setApprovalPopupDisplay(null)}>
                        {`${Globals.formatDateToDisplay(new Date(ewa.line_items[0].approval_date))}`}
                        {showPopup && 
                        <HoverPopup 
                            text={`Approved by ${approvedBy.firstname} ${approvedBy.lastname} on ${Globals.formatDateToDisplay(new Date(ewa.line_items[0].approval_date))}`}
                        />}
                    </div>
                </div>
            )
            
        }

        // Has been declined
        if(ewa && ewa.approved === 0 && ewa.approval_by !== null){
            const declinedBy = allUsers.find(user => user.id === Number.parseInt(ewa.approval_by));
            const showPopup = approvalPopupDisplay && approvalPopupDisplay.id === ewa.id;
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div onMouseEnter={(e) => setApprovalPopupDisplay(ewa)} onMouseLeave={(e) => setApprovalPopupDisplay(null)}>
                        {`${Globals.formatDateToDisplay(new Date(ewa.line_items[0].approval_date))}`}
                        {showPopup && <HoverPopup text={`Declined by ${declinedBy.firstname} ${declinedBy.lastname} on ${Globals.formatDateToDisplay(new Date(ewa.line_items[0].approval_date))}`}/>}
                    </div>
                </div>
            )
            
        }

        // Fallback display error
        return (
            <div className={`grid-table-row-data ${position}`}>
                <div className='red'>ERR</div>
            </div>
        )
    }

    const completionContent = (ewa, position) => {
        // Needs Approved
        if(ewa && ewa.approval_by === null){
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div>Needs Approved</div>
                </div>
            )
        }

        // Approved, needs completed and no permissions to complete
        if(ewa && ewa.approved === 1 && ewa.approval_by !== null && ewa.line_items[0].completed_by === null && !canCompleteEWA){
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div>Need Approval</div>
                </div>
            )
        }

        // Approved, needs completed and has permissions to complete
        if(ewa && ewa.approved === 1 && ewa.approval_by !== null && ewa.line_items[0].completed_by === null && canCompleteEWA){
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div className='completed-box'>
                        <Button className="complete-ewa" handleKeyPress={((e) => {if(e.key === 'Enter'){setCompletedEWA(ewa)}})} handleClick={((e) => setCompletedEWA(ewa))} buttonText="Complete" buttonMargin="0 0 0 0" width="80" height="22"/>
                    </div>
                </div>
            )
        }

        // Declined
        if(ewa && ewa.approved === 0 && ewa.approval_by !== null){
            return (
                <div className={`grid-table-row-data ${position}`}>Declined</div>
            )
        }

        // Has been completed
        if(ewa && ewa.completed_by !== null){
            const completedBy = allUsers.find(user => user.id === Number.parseInt(ewa.line_items[0].completed_by));
            const showPopup = completionPopupDisplay && completionPopupDisplay.id === ewa.id;
            return (
                <div className={`grid-table-row-data ${position}`}>
                    <div onMouseEnter={(e) => setCompletionPopupDisplay(ewa)} onMouseLeave={(e) => setCompletionPopupDisplay(null)}>
                        {`${Globals.formatDateToDisplay(new Date(ewa.line_items[0].completed_date))}`}
                        {showPopup && <HoverPopup text={`Marked completed by ${completedBy.firstname} ${completedBy.lastname} on ${Globals.formatDateToDisplay(new Date(ewa.line_items[0].completed_date))}`}/>}
                    </div>
                </div>
            )
            
        }

        // Fallback display error
        return (
            <div className={`grid-table-row-data ${position}`}>
                <div className='red'>ERR</div>
            </div>
        )
    }

    const columns = ['\u{1f5b6}', 'Job Name', 'Job #', 'Lot #', 'Lot Phase', 'Phase Code', 'Description', 'Internal Est #', 'Builder PO #', 'Payout', 'Scheduled On', 'Crew #', 'Employee', 'Approval Status', 'Completed', "Created By"];
    const newEWAColumns = ['', 'Job', 'Lot', 'Phase', 'Phase Code', 'Description', 'Internal Est #', 'Builder PO #', 'Scheduled Date', 'Amount', 'Employee'];
    const ewasToDisplay = PagesData.getCurrentPageData(pagesData, ewas);

    const selectPhase = async (e) => {
        const phase = phases.find(phaseToFind => phaseToFind.id.toString() === e.target.value.toString())
        setSelectedPhase(phase)
        setEwaCodes(await EWAs.loadEWACodes({"phase_task_phases.phase_id": e.target.value}))

        const company = phase !== undefined ? phase.company : null;
        let employees = []
        if(company === null){
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", null);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVC"){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVP"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        setEmployeesForEditEWADropDown(employees)
    }

    return (
        <EWAContainer>
            <MainHeader/>
            <div className='ewa-top-boxes'>
                <div className='ewa-filter'>
                    <div className='ewa-filter-header'>Filter</div>
                    <div className='ewa-filter-options'>
                        <div className='job-lot-filter-box'>
                            <DropDownWithLabel className="job-filter-select" id="job-filter-select-id" name="job-filter-select" label="Job" handleDropDown={selectJobforEWAFilter} addBlankOption={true} selectionList={jobsForDropDowns} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                            <DropDownWithLabel className="lot-filter-select" id="lot-filter-select-id" name="lot-filter-select" label="Lot" handleDropDown={(e) => setFilterOptions({...filterOptions, lotId: e.target.value})} addBlankOption={true} selectionList={lotsForFilterDropDown} margin="0 0 0 0" columns={20} labelStart={1} labelEnd={6} inputStart={6} inputEnd={17}/>
                        </div>
                        <div className='company-employee-filter-box'>
                            <DropDownWithLabel className="company-filter-select" id="company-filter-select-id" name="company-filter-select" label="Company" handleDropDown={(e) => setFilterOptions({...filterOptions, company: e.target.value})}addBlankOption={true} selectionList={[{id: "1", display: "SVC"}, {id: "2", display: "SVP"}]} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                            <DropDownWithLabel className="employee-filter-select" id="employee-filter-select-id" name="employee-filter-select" label="Employee" handleDropDown={(e) => setFilterOptions({...filterOptions, employeeId: e.target.value})} margin="0 0 0 0" addBlankOption={true} selectionList={employeesForFilterDropDown} columns={20} labelStart={1} labelEnd={6} inputStart={6} inputEnd={17}/>
                        </div>
                        <div className='approval-status-filter-box'>
                            <DropDownWithLabel className="approval-status-filter-select" id="approval-status-filter-select-id" name="approval-status-filter-select" label="Approval Status" value={filterOptions.approvalStatus} handleDropDown={(e) => setFilterOptions({...filterOptions, approvalStatus: e.target.value})}addBlankOption={true} selectionList={[{id: "Needs Review", display: "Needs Review"}, {id: "Approved", display: "Approved"}, {id: "Declined", display: "Declined"}]} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                        </div>
                        <div className='completion-status-filter-box'>
                            <DropDownWithLabel className="completion-status-filter-select" id="completion-status-filter-select-id" name="completion-status-filter-select" label="Completion Status" value={filterOptions.completedStatus} handleDropDown={(e) => setFilterOptions({...filterOptions, completedStatus: e.target.value})}addBlankOption={true} selectionList={[{id: "Completed", display: "Completed"}, {id: "Not Completed", display: "Not Completed"}]} 
                            margin="0 0 0 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                        </div>
                        <div className='date-search-type-filter-box'>
                            <DropDownWithLabel className="date-search-type-filter-select" id="date-search-type-filter-select-id" name="date-search-type-filter-select" label="Filter Date By" value={filterOptions.dateType} handleDropDown={(e) => setFilterOptions({...filterOptions, dateType: e.target.value})} selectionList={Globals.getEWADateSearchType()} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                        </div>
                        <div className='date-range-filter-box'>
                            <DateboxWithLabel className="start-date-filter" label="Start Date" selected={filterOptions.startDate} handleDatebox={date => setFilterOptions({...filterOptions, startDate: date})} enableTabLoop={false} margin="0 0 0 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                            <DateboxWithLabel className="end-date-filter" label="End Date" selected={filterOptions.endDate} handleDatebox={date => setFilterOptions({...filterOptions, endDate: date})} enableTabLoop={false} margin="0 0 0 0" columns={20} labelStart={1} labelEnd={6} inputStart={6} inputEnd={17}/>
                        </div>
                        <Button className="apply-filters" handleKeyPress={((e) => {if(e.key === 'Enter'){applyFilters()}})} handleClick={((e) => applyFilters())} buttonText="Apply Filters" width="120"/>
                    </div>
                </div>
                <div className='ewa-sort'>
                    <div className='ewa-sort-header'>Sort</div>
                    <div className='ewa-sort-options'>
                        <DropDownWithLabel className="sort-type-select" id="sort-type-select-id" name="sort-type-select" label="Sort By" value={sortOption.type} handleDropDown={(e) => setSortOption({...sortOption, type: e.target.value})} addBlankOption={true} selectionList={[{id: "jobNumber", display: "Job/Lot"}, {id: "ewaEmployee", display: "Employee"}, {id: "ewaScheduledDate", display: "Scheduled Date"}, {id: "ewaCreatedDate", display: "Created Date"}]} margin="3px 0 3px 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                        <DropDownWithLabel className="sort-order-select" id="sort-order-select-id" name="sort-order-select" label="Order" value={sortOption.order} handleDropDown={(e) => setSortOption({...sortOption, order: e.target.value})} addBlankOption={true} selectionList={[{id: "ascending", display: "Ascending"}, {id: "descending", display: "Descending"}]} margin="3px 0 3px 0" columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                        <Button className="apply-sort" handleKeyPress={((e) => {if(e.key === 'Enter'){sortAndSetEWAs(ewas)}})} handleClick={((e) => sortAndSetEWAs(ewas))} buttonText="Apply Sort" width="120"/>
                    </div>
                </div>
            </div>
            <div className='table-header'>
                <div className='print-ewas'>
                    <div className='print-ewas-button' onClick={((e) => printEWAs(false))}>Print EWA</div>
                </div>
                {canCreateEditEWA && 
                <div className='add-new-ewa'>
                    <div className='add-new-ewa-button' onClick={() => setCreateNewEWAs(true)}>Add New EWA</div>
                </div>} 
                {(canCreateEditEWA) &&
                <div className='export-to-xlsx'>
                    <div id='id-export-to-xlsx-button' className='export-to-xlsx-button' onClick={exportEWAsToXLSX}>Export To XLSX</div>
                </div>}
                <div className='header-title'>EWA's</div>
            </div>
            {createNewEWAs && canCreateEditEWA &&
            <div className='new-ewa-box'>
                <div className='new-ewa-header'>New EWA's</div>
                <div className='new-ewa-table-header'>
                    {newEWAColumns.map((column, index)=> {
                        return <div key={`new-ewa-header-${index}`} className={`new-ewa-table-header-data index${index}`}>{column}</div>
                    })}
                </div>
                {newEWA.map((newEWALineItem, index) => {
                    const errorLot = newEWALineItem.errors ? newEWALineItem.errors.lot : "";
                    const errorPhaseCode = newEWALineItem.errors ? newEWALineItem.errors.phaseCode : "";
                    const errorDescription = newEWALineItem.errors ? newEWALineItem.errors.description : "";
                    const errorInternalEstimateNumber = newEWALineItem.errors ? newEWALineItem.errors.internalEstimateNumber : "";
                    const errorBuilderPONumber = newEWALineItem.errors ? newEWALineItem.errors.builderPONumber : "";
                    const errorScheduledDate = newEWALineItem.errors ? newEWALineItem.errors.scheduledDate : "";
                    const errorAmount = newEWALineItem.errors ? newEWALineItem.errors.payout : "";
                    const errorEmployee = newEWALineItem.errors ? newEWALineItem.errors.employee : "";
                    const position = (index + 1) === newEWA.length ?  'last' : '';

                    return (
                        <div key={`new-ewa-${index}`} className={`new-ewa-table-row ${position}`}>
                            <div className='new-ewa-row-data'>
                                <Button className="add-new-ewa-remove-line-item" handleKeyPress={((e) => {if(e.key === 'Enter'){handleRemovingNewEWALineItem(index)}})} 
                                handleClick={((e) => handleRemovingNewEWALineItem(index))} buttonText="-" buttonMargin="0" width="30"/>
                            </div>
                            <div className='new-ewa-row-data'>
                                <DropDown className="new-ewa-job-drop-down" id="new-ewa-job-drop-down-id" name="job-drop-down" value={newEWALineItem.jobId} 
                                handleDropDown={(e) => selectJobForNewEWA(e, index)} addBlankOption={true} selectionList={jobsForDropDowns} 
                                margin="0" height="23" columns={10} inputStart={1} inputEnd={11}/>
                            </div>
                            <div className={`new-ewa-row-data ${errorLot}`}>
                                <DropDown className="new-ewa-lot-drop-down" id="new-ewa-lot-drop-down-id" name="lot-drop-down" value={newEWALineItem.newEWA.lot_id} 
                                handleDropDown={(e) => selectLotForNewEWA(e, index)} addBlankOption={true} selectionList={newEWALineItem.lotsForDropDown} 
                                margin="0" height="23" columns={10} inputStart={1} inputEnd={11}/>
                            </div>
                            <div className={`new-ewa-row-data ${errorPhaseCode}`}>
                                <DropDown className="new-ewa-phase-drop-down" id="new-ewa-phase-drop-down-id" name="phase-drop-down"
                                          value={newEWALineItem.newEWA.line_items[0].phase_id} handleDropDown={(e) => selectPhaseForNewEWA(e, index)}
                                          addBlankOption={true} selectionList={phases.map(phase => ({id: phase.id, display: `${phase.name}`}))}
                                          margin="0" height="23" columns={10} inputStart={1} inputEnd={11}/>
                            </div>
                            <div className={`new-ewa-row-data ${errorPhaseCode}`}>
                                <DropDown className="new-ewa-phase-code-drop-down" id="new-ewa-phase-code-drop-down-id" name="phase-code-drop-down" 
                                value={newEWALineItem.newEWA.line_items[0].task_id} handleDropDown={(e) => selectPhaseCodeForNewEWA(e, index)}
                                addBlankOption={true} selectionList={ewaCodesNewEWA.map(code => ({id: code.id, display: `${code.code} - ${code.description}`}))}
                                margin="0" height="23" columns={10} inputStart={1} inputEnd={11}/>
                            </div>
                            <div className={`new-ewa-row-data ${errorDescription}`}>
                                <Textbox className="new-ewa-job-description" name="new-ewa-job-description" label="Job Description" 
                                value={newEWALineItem.newEWA.line_items[0].notes} handleTextbox={(e) => setDescriptionNewEWA(e, index)} height="23px"
                                margin="0 auto" columns="10" inputStart="1" inputEnd="11"/>
                            </div>
                            <div className={`new-ewa-row-data ${errorInternalEstimateNumber}`}>
                                {newEwaIsBillable &&
                                <Textbox className="new-ewa-job-internal-estimate-number" name="new-ewa-job-internal-estimate-number" label="Internal Est #"
                                         value={newEWALineItem.newEWA.internal_estimate_number} handleTextbox={(e) => setInternalEstimateNumberNewEWA(e, index)} height="23px"
                                         margin="0 auto" columns="10" inputStart="1" inputEnd="11"/>}
                            </div>
                            <div className={`new-ewa-row-data ${errorBuilderPONumber}`}>
                                {newEwaIsBillable &&
                                    <Textbox className="new-ewa-job-builder-po-number" name="new-ewa-job-builder-po-number" label="Builder PO #"
                                         value={newEWALineItem.newEWA.builder_po_number} handleTextbox={(e) => setBuilderPONumberNewEWA(e, index)} height="23px"
                                         margin="0 auto" columns="10" inputStart="1" inputEnd="11"/>}
                            </div>
                            <div className={`new-ewa-row-data ${errorScheduledDate}`}>
                                <div className="new-ewa-scheduled-datebox">
                                    <DatePicker className="new-ewa-scheduled-date-picker" enableTabLoop={false} selected={newEWALineItem.newEWA.line_items[0].schedule_date} onChange={date => setDateNewEWA(date, index)}/>
                                </div>
                            </div>
                            <div className={`new-ewa-row-data ${errorAmount}`}>
                                <Textbox className="new-ewa-payout" inputId={`id-new-ewa-payout`} name="new-ewa-payout" label="Payout $" inputType="number"
                                value={newEWALineItem.newEWA.payout} onClick={() => document.getElementById(`id-new-ewa-payout`).select()}
                                handleTextbox={(e) => setAmountNewEWA(e, index)} height="23px" margin="0 auto" columns="10" inputStart="1" inputEnd="11"/>
                            </div>
                            <div className={`new-ewa-row-data ${errorEmployee}`}>
                                <DropDown className="new-ewa-employee-select" id="new-ewa-employee-select-id" name="new-ewa-employee-select" label="Employee" 
                                value={newEWALineItem.employeeId} handleDropDown={(e) => selectEmployeeNewEWA(e, index)} 
                                addBlankOption={true} selectionList={newEWALineItem.employeesForDropDown} 
                                margin="0" height="23" columns={10} inputStart={1} inputEnd={11}/>
                            </div>
                        </div>
                    );
                })}
                <div className='add-new-ewa-line-box'>
                    <Button className="add-new-ewa-line" handleKeyPress={((e) => {if(e.key === 'Enter'){handleAddingNewEWALineItem()}})} handleClick={((e) => handleAddingNewEWALineItem())} buttonText="+" buttonMargin="0" width="30"/>
                </div>
                <div className="two-button-box">
                    <Button className="submit-new-ewa" handleKeyPress={((e) => {if(e.key === 'Enter'){handleAddingNewEWAs()}})} 
                    handleClick={((e) => handleAddingNewEWAs())} buttonText="Submit EWA's" buttonMargin="0" width="120"/>
                    <Button className="cancel-add-new-ewa" handleKeyPress={((e) => {if(e.key === 'Enter'){handleCancelNewEWAs()}})} 
                    handleClick={((e) => handleCancelNewEWAs())} buttonText="Cancel" buttonMargin="0" width="120"/>
                </div>
            </div>}
            {displayLoadingMessage && <LoadingMessage message="Loading Data, this may take several minutes..." width="600px"/>}
            {(ewas.length > 0) && !displayLoadingMessage && 
            <div className='grid-table'>
                <div className='grid-table-header'>
                    {columns.map((column, index)=> {
                        return <div key={`header-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                    })}
                </div>
                {ewasToDisplay.map((ewa, index) => {
                    if((canCreateEditEWA) && editEWA && (ewa.id === editEWA.id) && ewa.line_items[0].complete !== 1){
                        return (
                            <div key={`edit-ewa-${index}`} className='edit-ewa-box'>
                                <div className='edit-ewa-header'>Edit EWA</div>
                                <div className='edit-ewa-job-description'>
                                    <div className='label'>Job:</div>
                                    <div className='value'>{`${editEWA.line_items[0].lot.job.builder.name} - ${editEWA.line_items[0].lot.job.number} - ${editEWA.line_items[0].lot.job.project_name}`}</div>
                                </div>
                                <div className='edit-ewa-lot-number'>
                                    <div className='label'>Lot Number:</div>
                                    <div className='value'>{editEWA.line_items[0].lot.number}</div>
                                </div>
                                <div className='edit-ewa-lot-phase'>
                                    <div className='label'>Lot Phase:</div>
                                    <div className='value'>{editEWA.line_items[0].lot.phase}</div>
                                </div>
                                <DropDownWithLabel className="edit-ewa-phase-drop-down" id="edit-ewa-phase-drop-down-id" name="phase-drop-down" label="Phase" value={editEWA.line_items[0].phase_id} handleDropDown={selectPhaseEditEWA} addBlankOption={true} selectionList={phases.map(phase => ({id: phase.id, display: `${phase.name}`}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                                <DropDownWithLabel className="edit-ewa-phase-code-drop-down" id="edit-ewa-phase-code-drop-down-id" name="phase-code-drop-down" label="Phase Code" value={editEWA.line_items[0].task.id} handleDropDown={selectPhaseCodeEditEWA} addBlankOption={true} selectionList={ewaCodes.map(code => ({id: code.id, display: `${code.code} - ${code.description}`}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                                <TextboxWithLabel className="edit-ewa-job-description" name="edit-ewa-job-description" label="Job Description" value={editEWA.line_items[0].notes} handleTextbox={(e) => setEditEWAValue("notes", e.target.value, true)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                                {editEwaIsBillable && <TextboxWithLabel className="edit-ewa-job-internal-estimate-number" name="edit-ewa-job-internal-estimate-number" label="Internal Est #" value={editEWA.internal_estimate_number} handleTextbox={(e) => setEditEWAValue("internal_estimate_number", e.target.value, false)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>}
                                {editEwaIsBillable && <TextboxWithLabel className="edit-ewa-job-builder-po-number" name="edit-ewa-job-builder-po-number" label="Builder PO #" value={editEWA.builder_po_number} handleTextbox={(e) => setEditEWAValue("builder_po_number", e.target.value, false)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>}
                                <DateboxWithLabel className="edit-ewa-scheduled-date" label="Scheduled Date" selected={editEWA.line_items[0].schedule_date} handleDatebox={date => setEditEWAValue("scheduled_date", date, true)} enableTabLoop={false} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                                <TextboxWithLabel className="edit-ewa-payout" inputId="id-edit-ewa-payout" name="edit-ewa-payout" label="Payout $" inputType="number" value={editEWA.line_items[0].payout} onClick={() => document.getElementById('id-edit-ewa-payout').select()} handleTextbox={(e) => setEditEWAValue("payout", e.target.value, true)} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                                <DropDownWithLabel className="edit-ewa-employee-select" id="edit-ewa-employee-select-id" name="neditew-ewa-employee-select" label="Employee" value={editEWA.line_items[0].crew !== undefined && editEWA.line_items[0].crew !== null && editEWA.line_items[0].crew.employees[0] !== null && editEWA.line_items[0].crew.employees[0].employee_id !== null ? editEWA.line_items[0].crew.employees[0].employee_id : null} handleDropDown={selectEmployeeEditEWA} addBlankOption={true} selectionList={employeesForEditEWADropDown} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                                <div className="two-button-box">
                                    <Button className="submit-edit-ewa" handleKeyPress={((e) => {if(e.key === 'Enter'){handleUpdatingEditEWA()}})} handleClick={((e) => handleUpdatingEditEWA())} buttonText="Submit" width="120"/>
                                    <Button className="cancel-edit-ewa" handleKeyPress={((e) => {if(e.key === 'Enter'){setEditEWA(null)}})} handleClick={((e) => setEditEWA(null))} buttonText="Cancel" width="120"/>
                                </div>
                            </div>
                        );
                    }

                    // Display EWA's
                    const position = index === 0 && ewasToDisplay.length === 1 ? "first last" : index === 0 ? 'first' : index === (ewasToDisplay.length - 1) ? 'last' : '';
                    return (
                        ewa.line_items.map(li => {
                            let crews = ''
                            let name = ''
                            let employee = li.crew !== undefined && li.crew !== null && li.crew.employees.filter(x => x.is_lead === 1).length > 0 ? li.crew.employees.filter(x => x.is_lead === 1)[0].employee
                                : li.crew !== undefined && li.crew !== null && li.crew.employees[0] !== null && li.crew.employees[0].employee !== null ?  li.crew.employees[0].employee
                                    : null
                            if(employee !== null){
                                crews = employee.crews
                                name = `${employee.alias !== null ? employee.alias : ''} ${employee.first_name} ${employee.last_name}`
                            }
                            return (
                                <div key={`${li.id}-${index}`} className={`grid-table-row`}>
                                    {printingContent(ewa, position)}
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{li.lot.job.project_name}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{li.lot.job.number}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{li.lot.number}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{li.lot.phase}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{li.task.code}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{li.notes}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{ewa.internal_estimate_number}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{ewa.builder_po_number}</div>
                                    <div className={`grid-table-row-data ${position} payout-display`}>
                                        <div className='payout-display-prefix' onClick={(e) => setupEWAEdit(ewa)}>$</div>
                                        <div className='payout-display-payout' onClick={(e) => setupEWAEdit(ewa)}>{Number.parseFloat(li.payout).toFixed(2)}</div>
                                    </div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{Globals.formatDateToDisplay(li.schedule_date)}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{crews}</div>
                                    <div className={`grid-table-row-data ${position}`} onClick={(e) => setupEWAEdit(ewa)}>{name.trim()}</div>
                                    {approvalContent(ewa, position)}
                                    {completionContent(ewa, position)}
                                    <div className={`grid-table-row-data ${position} far-right`} onClick={(e) => setupEWAEdit(ewa)}>{getUserName(ewa.created_by)}</div>
                                </div>
                            )
                            })
                    );
                })}
            </div>}

            {(ewasToDisplay.length > 0) && !displayLoadingMessage && <Pages contentLength={ewas.length} pagesData={pagesData} pageBack={() => setPagesData({...pagesData, currentPage: PagesData.pageBack(pagesData)})} pageForward={() => setPagesData({...pagesData, currentPage: PagesData.pageForward(pagesData, ewas.length)})} handleInputPageNumber={(value) => setPagesData({...pagesData, currentPage: PagesData.handleInputPageNumber(value, pagesData, ewas.length)})}/>}
            {approvedEWA && <PopupOk color={PopupOkWarningColor} message={`Confirm approval of this EWA?`} handlePopup={markEWAApproved}/>}
            {declinedEWA && <PopupOk color={PopupOkWarningColor} message={`Confirm declining this EWA?`} handlePopup={markEWADeclined}/>}
            {completedEWA && <PopupOk color={PopupOkWarningColor} message={`Confirm completion of this EWA?`} handlePopup={markEWACompleted}/>}
            {reprintEWA && <PopupOk color={PopupOkWarningColor} message={`You are requesting to print an EWA that has already been printed, do you wish to reprint this EWA?`} handlePopup={handleReprintEWA}/>}
            {warningNoLotsFromSelectedFilters && <Popup color={PopupWarningColor} message={"No ewa's match the filter options"} handlePopup={togglePopup}/>}
            {errorNoFiltersSelected && <Popup color={PopupWarningColor} message={'Please select and apply at least 1 filter.'} handlePopup={togglePopup}/>}
            {errorsInNewEWA && <Popup color={PopupFailureColor} message={"Errors exist in some or all of the new EWA's. Please correct or remove the EWA's and resubmit."} handlePopup={togglePopup}/>}
            {errorNoLotSelected && <Popup color={PopupFailureColor} message={'No Lot Selected. Please select a lot.'} handlePopup={togglePopup}/>}
            {errorNoPhaseCodeSelected && <Popup color={PopupFailureColor} message={'No Phase Code Selected. Please select a phase code.'} handlePopup={togglePopup}/>}
            {errorNoJobDescription && <Popup color={PopupFailureColor} message={'No job description entered. Please enter a job description.'} handlePopup={togglePopup}/>}
            {errorNoInternalEstimateOrBuilderPO && <Popup color={PopupFailureColor} message={'No Internal Estimate Number or Builder PO Number entered. Please enter an Internal Estimate Number or Builder PO Number.'} handlePopup={togglePopup}/>}
            {errorNoScheduledDate && <Popup color={PopupFailureColor} message={'No scheduled date selected. Please select a schedule date.'} handlePopup={togglePopup}/>}
            {errorNoAmountEntered && <Popup color={PopupFailureColor} message={'No amount entered. Please enter an amount.'} handlePopup={togglePopup}/>}
            {errorNoEmployeeSelected && <Popup color={PopupFailureColor} message={'No employee selected. Please select an employee.'} handlePopup={togglePopup}/>}
            {newEWAsuccess && <Popup color={PopupSuccessColor} message={`New Extra Work Authorization has been submitted`} handlePopup={togglePopup}/>}
            {editEWAsuccess && <Popup color={PopupSuccessColor} message={`Edited Extra Work Authorization has been updated`} handlePopup={togglePopup}/>}
        </EWAContainer>
    )
};

export default EWA;
