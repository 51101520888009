import { useEffect, useState, useRef } from 'react';
import Reaptcha from 'reaptcha';

import Auth from '../authentication/Auth.js';

import { capSiteKey } from '../../api/index.js';

import Globals from '../utilities/Globals';
import logo from './../../img/HeaderLogo.png';
import WorkForSVCContainer from './styles/WorkForSVCContainer';
import dayjs from "dayjs";

const WorkForSVC = () => {
    const [didLoadCaptcha, setDidLoadCaptcha] = useState(false);

    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null);

    const [prospectiveEmployee, setProspectiveEmployee] = useState({
        status: 'New',
        firstname: '',
        lastname: '',
        phone_number: '',
        email: '',
        job: {
            category: '',
            position: ''
        },
        skill_level: '',
        referred_by: '',
        work_history: {
            employer1: '',
            employer2: '',
            employer3: ''
        },
        has_driver_license: false,
        has_cdl: false,
        comments: ''
    });

    const [selectedSkills, setSelectedSkills] = useState([]);

    const [required, setRequired] = useState({
        firstname: '',
        lastname: '',
        phone_number: '',
        position: '', // This check the job member of the prospective employee
        skill_level: '',
        captcha: '',
    })
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const loadCaptcha = () => {
            setDidLoadCaptcha(true);
        }
        if(!didLoadCaptcha){
            loadCaptcha();
        }
    }, [didLoadCaptcha])

    const clearForm = () => {
        setSuccess(false);
    }

    const verify = () => {
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res);
        })
    }

    const expire = () => {
        setCaptchaToken(null);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSubmit = prospectiveEmployee;
        dataToSubmit.date = dayjs(new Date()).format('YYYY-MM-DD');
        
        // Check all required fileds are filled out
        // Reset error codes
        const tempRequired = {
            firstname: '',
            lastname: '',
            phone_number: '',
            position: '', // This check the job member of the prospective employee
            skill_level: '',
            captcha: '',
        }
        let isError = false;
        if(dataToSubmit.firstname === ''){tempRequired.firstname = 'warning-border'; isError = true;}
        if(dataToSubmit.lastname === ''){tempRequired.lastname = 'warning-border'; isError = true;}
        if(dataToSubmit.phone_number === ''){tempRequired.phone_number = 'warning-border'; isError = true;}
        if(dataToSubmit.job.position === ''){tempRequired.position = 'warning-border'; isError = true;}
        if(dataToSubmit.skill_level === ''){tempRequired.skill_level = 'warning-border'; isError = true;}
        if(captchaToken === null){tempRequired.captcha = 'warning-border'; isError = true;}
        setRequired(tempRequired);
        
        if(isError){return;}

        // Submit data to database
        await Auth.hitEndpoint("POST", "PROSPECTIVE_EMPLOYEES", "", dataToSubmit);

        // Display success message
        setSuccess(true);

        // Reset prospective employee on successful submit
        setProspectiveEmployee({
            status: 'New',
            firstname: '',
            lastname: '',
            phone_number: '',
            email: '',
            job: {
                category: '',
                position: ''
            },
            skill_level: '',
            referred_by: '',
            work_history: {
                employer1: '',
                employer2: '',
                employer3: ''
            },
            has_driver_license: false,
            has_cdl: false,
            comments: ''
        });

        setTimeout(clearForm, 5000);
    }

    const toggleRadioButton = (value, type) => {
        if(type === 'DL'){
            setProspectiveEmployee({...prospectiveEmployee, has_driver_license: value})
        }
        else if(type === 'CDL'){
            setProspectiveEmployee({...prospectiveEmployee, has_cdl: value})
        }
    }

    const setPosition = (e) => {
        e.preventDefault();

        const category = e.target.value.split('^')[0];
        const position = e.target.value.split('^')[1];
        
        setProspectiveEmployee({ ...prospectiveEmployee, job: {
            category: category,
            position: position
        }})

        if(category === 'Field'){
            setSelectedSkills(Globals.getProspectiveEmployeeSkillLevels().find(element => element.category === 'Field').levels);
        }
        if(category === 'Office'){
            setSelectedSkills(Globals.getProspectiveEmployeeSkillLevels().find(element => element.category === 'Office').levels);
        }
    }

    if(success){
        return (
            <WorkForSVCContainer>
                <div className="pe-header-logo"><img src={logo} alt="SVC Logo"/></div>
                <div className='success-message'>Thank You For Your Interest In SVC</div>
                <div className='success-message'>You Information Has Been Successfully Submitted</div>
            </WorkForSVCContainer>
        )
    }

    return (
        <WorkForSVCContainer>
            <div className="pe-header-logo"><img src={logo} alt="SVC Logo"/></div>
            <div className='pe-form-header'>SVC Job Interest Form</div>
            {/* <div className='pe-form-legal-disclaimer'>LEGAL DISCLAIMER NEEDED</div> */}
            <div className='pe-form-required-note'>Fields marked with an * are required</div>
            <div className='pe-info-box'>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='firstname'>Enter First Name*</label>
                    <input className={`text-box-input ${required.firstname}`} type='text' autoComplete='on' name='firstname' placeholder='First Name' value={prospectiveEmployee.firstname} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, firstname: e.target.value }))}/>
                </div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='lastname'>Enter Last Name*</label>
                    <input className={`text-box-input ${required.lastname}`} type='text' autoComplete='on' name='lastname' placeholder='Last Name' value={prospectiveEmployee.lastname} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, lastname: e.target.value }))}/>
                </div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='phone_number'>Enter Phone Number*</label>
                    <input className={`text-box-input ${required.phone_number}`} type='tel' autoComplete='on' name='phone_number' placeholder='XXX-XXX-XXXX' value={prospectiveEmployee.phone_number} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, phone_number: e.target.value }))}/>
                </div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='email'>Enter Email</label>
                    <input className="text-box-input" type='email' autoComplete='on' name='email' placeholder='Enter Email' value={prospectiveEmployee.email} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, email: e.target.value }))}/>
                </div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='referred-by'>Referred By</label>
                    <input className="text-box-input" type='text' autoComplete='on' name='referred-by' placeholder='Referred By' value={prospectiveEmployee.referred_by} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, referred_by: e.target.value }))}/>
                </div>
                <div className='drop-down-box'>
                    <label className="drop-down-label" htmlFor='pe-scope-of-work'>Position*</label>
                    <select className={`drop-down-select ${required.position}`} name='pe-scope-of-work' value={`${prospectiveEmployee.job.category}^${prospectiveEmployee.job.position}`} onChange={setPosition}>
                        <option className="drop-down-option" value=''></option>
                        <option className="drop-down-option" value=''>FIELD JOBS</option>
                        {Globals.getProspectiveEmployeeJobs().filter(job => job.category === 'Field').sort((j1, j2) => j1.position < j2.position ? -1 : 1).map((job, index) => {
                            return (
                                <option key={`${job.position}-${index}`} className="drop-down-option" value={`Field^${job.position}`}>{`- ${job.position}`}</option>
                            )
                        })}
                        <option className="drop-down-option" value=''>OFFICE JOBS</option>
                        {Globals.getProspectiveEmployeeJobs().filter(job => job.category === 'Office').sort((j1, j2) => j1.position < j2.position ? -1 : 1).map((job, index) => {
                            return (
                                <option key={`${job.position}-${index}`} className="drop-down-option" value={`Office^${job.position}`}>{`- ${job.position}`}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='drop-down-box'>
                    <label className="drop-down-label" htmlFor='pe-skill-level'>Experience*</label>
                    <select className={`drop-down-select ${required.skill_level}`} name='pe-skill-level' value={prospectiveEmployee.skill_level} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, skill_level: e.target.value }))}>
                        <option className="drop-down-option" value=''></option>
                        {selectedSkills.map(skill => {
                            return (
                                <option key={skill} className="drop-down-option" value={skill}>{skill}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='radio-button-box'>
                    <div className='radio-button-question'>Do you have a driver license?</div>
                    <input className="radio-button-yes" type="radio" name="driver-license" value="true" checked={prospectiveEmployee.has_driver_license === true} onChange={(e) => toggleRadioButton(true, 'DL')}/>
                    <label className="radio-button-label-yes" htmlFor="yes">Yes</label>
                    <input className="radio-button-no" type="radio" name="driver-license" value="false" checked={prospectiveEmployee.has_driver_license === false} onChange={(e) => toggleRadioButton(false, 'DL')}/>
                    <label className="radio-button-label-no" htmlFor="no">No</label>
                </div>
                <div className='radio-button-box'>
                    <div className='radio-button-question'>Do you have a CDL?</div>
                    <input className="radio-button-yes" type="radio" name="cdl" value="true" checked={prospectiveEmployee.has_cdl === true} onChange={(e) => toggleRadioButton(true, 'CDL')}/>
                    <label className="radio-button-label-yes" htmlFor="yes">Yes</label>
                    <input className="radio-button-no" type="radio" name="cdl" value="false" checked={prospectiveEmployee.has_cdl === false} onChange={(e) => toggleRadioButton(false, 'CDL')}/>
                    <label className="radio-button-label-no" htmlFor="no">No</label>
                </div>
                <div className='work-experience-header'>Work Experience</div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='work-experience'>Employer 1</label>
                    <input className="text-box-input" type='text' autoComplete='on' name='work-experience' placeholder='Employer Name' value={prospectiveEmployee.work_history.employer1} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, work_history: { ...prospectiveEmployee.work_history, employer1: e.target.value} }))}/>
                </div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='work-experience'>Employer 2</label>
                    <input className="text-box-input" type='text' autoComplete='on' name='work-experience' placeholder='Employer Name' value={prospectiveEmployee.work_history.employer2} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, work_history: { ...prospectiveEmployee.work_history, employer2: e.target.value} }))}/>
                </div>
                <div className='input-box'>
                    <label className="text-box-label" htmlFor='work-experience'>Employer 3</label>
                    <input className="text-box-input" type='text' autoComplete='on' name='work-experience' placeholder='Employer Name' value={prospectiveEmployee.work_history.employer3} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, work_history: { ...prospectiveEmployee.work_history, employer3: e.target.value} }))}/>
                </div>
                <div className='text-area'>
                    <label className="text-area-label" htmlFor='additional-inforamation'>Additional Information</label>
                    <textarea className="text-area-input" name='additional-inforamation' rows='6' cols='47' placeholder='Additional Information' value={prospectiveEmployee.comments} onChange={((e) => setProspectiveEmployee({ ...prospectiveEmployee, comments: e.target.value }))}/>
                </div>
            </div>
            <Reaptcha className={`captcha ${required.captcha}`} sitekey={capSiteKey} ref={captchaRef} onVerify={verify} onExpire={expire}/>
            <div className='submit'>
                <div className='button' onClick={handleSubmit}>Submit</div>
            </div>
        </WorkForSVCContainer>
    );
}

export default WorkForSVC;