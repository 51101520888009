import { useState, useMemo } from 'react';

import Auth from '../../authentication/Auth.js';
import MainHeader from '../../utilities/MainHeader.js';
import Schedules from '../../../classes/Schedules.js';

import Grid from '@mui/material/Grid';
import {MaterialReactTable} from "material-react-table";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button  from "@mui/material/Button";
import { Switch } from '@mui/material';
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from '@mui/material/FormControlLabel';

import Globals from '../../utilities/Globals.js'
import dayjs from "dayjs";

const SVCScheduleRequest = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [lotFilter, setLotFilter] = useState('');

    const [user, setUser] = useState(null);
    const [supers, setSupers] = useState([]);

    const [crews, setCrews] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);
    const [filteredLots, setFilteredLots] = useState([]);
    const [lastIndex, setLastIndex] = useState('');

    const [phases, setPhases] = useState([]);
    const [useEWAPhaseTasks, setUseEWAPhaseTasks] = useState(false);
    const [phaseTasks, setPhaseTasks] = useState([]);

    const [schedule, setSchedule] = useState([]);
    const [scheduleRequestsDate, setScheduleRequestsDate] = useState(null);
    const [scheduleRequests, setScheduleRequests] = useState([]);

    const [rowSelection, setRowSelection] = useState({});
    const [scheduleRequestRowsToEdit, setScheduleRequestRowsToEdit] = useState([]);
    const [multiRowEditBoxDisplayData, setMultiRowEditBoxDisplayData] = useState({
        requestedDate: null,
        crew: null,
        employees: [],
    })

    const [openCreateScheduleRequestForm, setOpenCreateScheduleRequestForm] = useState(false);
    const [openEditScheduleRequestForm, setOpenEditScheduleRequestForm] = useState(false);
    const [openDeleteScheduleRequestForm, setOpenDeleteScheduleRequestForm] = useState(false);
    const [openMultiRowChangeForm, setOpenMultiRowChangeForm] = useState(false);

    const [scheduleRequest, setScheduleRequest] = useState({
        id: '',
        requestedDate: null,
        requestedBy: null,
        phase: null,
        phaseTask: null,
        crew: null,
        employees: [],
        notes: '',
        job: null,
        lots: []
    });

    const [error, setError] = useState('');

    const isSuper = user !== null && user.position_id === 2 ? true : false;

    const columns = useMemo(
        () => [
            {
                accessorKey: 'routing_seq',
                header: 'Sequence',
                size: 50,
                enableEditing: true,
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    onBlur: async (e) => {
                        const newRoutingSeq = parseInt(e.target.value);
                        if(isNaN(newRoutingSeq) || newRoutingSeq < 1){
                            return;
                        }
                        row.original.routing_seq = newRoutingSeq;

                        const scheduleRequestWithSameRoutingSequenceNumber = scheduleRequests.find(sr => {
                            return parseInt(sr.routing_seq) === parseInt(row.original.routing_seq)
                                    && parseInt(sr.crew.crew_id) === parseInt(row.original.crew.crew_id);
                        })
                        let hoveredIndex = -1;
                        let draggedIndex = scheduleRequests.findIndex(sr => parseInt(sr.id) === parseInt(row.original.id));
                        if(scheduleRequestWithSameRoutingSequenceNumber){
                            hoveredIndex = scheduleRequests.findIndex(sr => parseInt(sr.id) === parseInt(scheduleRequestWithSameRoutingSequenceNumber.id));
                        }
                        else{
                            for(let i = 0; i < scheduleRequests.length; i++){
                                if(parseInt(scheduleRequests[i].crew.crew_id) === parseInt(row.original.crew.crew_id)
                                    && parseInt(scheduleRequests[i].routing_seq) < parseInt(row.original.routing_seq)){
                                    hoveredIndex = i;
                                }
                            }
                        }

                        if(hoveredIndex < 0 || draggedIndex < 0) {
                            return;
                        }

                        scheduleRequests.splice(hoveredIndex, 0, scheduleRequests.splice(draggedIndex, 1)[0]);
                        const scheduleRequestsWithSameCrew = scheduleRequests.filter(sr => parseInt(sr.crew.crew_id) === parseInt(row.original.crew.crew_id));
                        scheduleRequestsWithSameCrew.forEach((sr, index) => {
                            sr.routing_seq = index + 1;
                        })
                        scheduleRequests.sort((sr1, sr2) => {
                            if(parseInt(sr1.crew.crew.number) === parseInt(sr2.crew.crew.number)){
                                return sr1.routing_seq < sr2.routing_seq ? -1 : 1;
                            }
                            return parseInt(sr1.crew.crew.number) < parseInt(sr2.crew.crew.number) ? -1 : 1;
                        });
                        setScheduleRequests([...scheduleRequests]);

                        for(let i = 0; i < scheduleRequestsWithSameCrew.length; i++){
                            const dbObj = {
                                id: scheduleRequestsWithSameCrew[i].id,
                                routing_seq: scheduleRequestsWithSameCrew[i].routing_seq
                            }

                            await Schedules.saveSchedule(dbObj);
                        }
                    }
                }),
            },
            {
                accessorKey: 'crew.crew.number',
                header: 'Crew #',
                enableEditing: false,
                size: 50,
                filterFn: 'filterCrews'
            },
            {
                accessorFn: (row) => {
                    const lead = row.crew.employees.find(e => {
                        if(e.employee.membership && e.employee.membership.position){
                            return e.employee.membership.position === 'Leader';
                        }
                        return null;
                    })
                    return lead ? `${lead.employee.first_name} ${lead.employee.last_name}` : '';
                },
                header: 'Crew Lead',
                enableEditing: false,
                size: 200,
            },
            {
                accessorKey: 'lot.job.project_name',
                header: 'Project Name',
                enableEditing: false,
                size: 200,
            },
            {
                accessorKey: 'lot.job.number',
                header: 'Job #',
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: 'lot.number',
                header: 'Lot #',
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: 'lot.phase',
                header: 'Lot Phase',
                enableEditing: false,
                size: 80,
            },
            {
                accessorFn: (row) => {
                    return `${row.task.code} - ${row.task.description}`;
                },
                header: 'Phase Task',
                enableEditing: false,
                size: 150,
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
                enableEditing: false,
                size: 200,
            },
        ], [scheduleRequests]);

    const loadScheduleRequests = async (date) => {
        if(date !== null) {
            let jsonString = `jsonString=requested=1 and deleted_at is null and schedule_date = '${date.format('YYYY-MM-DD')}'`;
            const tempScheduleRequests = await Auth.hitEndpointNew("GET", "schedule/detail/requested", jsonString);
            tempScheduleRequests.sort((sr1, sr2) => {
                if (parseInt(sr1.crew.crew.number) === parseInt(sr2.crew.crew.number)) {
                    return sr1.routing_seq < sr2.routing_seq ? -1 : 1;
                }
                return parseInt(sr1.crew.crew.number) < parseInt(sr2.crew.crew.number) ? -1 : 1;
            });
            colorScheduleRequests(tempScheduleRequests);
            setScheduleRequests(tempScheduleRequests);
        }
        else{
            setScheduleRequests([])
        }
    }

    const colorScheduleRequests = (sr) => {
        const colors = ['rgb(137, 207, 240)', 'rgb(125, 249, 255)'];
        let currentColor = 0;
        sr.forEach((r, index, arr) => {
            if(index > 0){
                if(parseInt(arr[index - 1].crew.crew_id) !== parseInt(r.crew.crew_id)){
                    currentColor++;
                    if(currentColor > 1){
                        currentColor = 0;
                    }
                }
            }
            r.bgColor = colors[currentColor];

            return r;
        })
    }

    const loadData = async () => {
        setDidLoadData(true);

        const tempUser = await Auth.hitEndpointNew("GET", "users-new", `jsonString=id=${await Auth.getUserId()}`);
        tempUser.value = tempUser.id;
        tempUser.label = `${tempUser.firstname} ${tempUser.lastname}`
        if(tempUser.position_id === 2){
            setScheduleRequest({...scheduleRequest, requestedBy: {...tempUser}});
        }
        setUser(tempUser);
        setSupers(await Schedules.loadSupers());

        const tempPhases = await Auth.hitEndpointNew("GET", "phases")
        setPhases(tempPhases.map(p => ({...p, value: p.id, label: p.description})));

        setJobs(await Schedules.loadAvailableJobs());
    }

    if(!didLoadData){
        loadData();
    }

    const handleLoadSchedule = async(date) => {
        setSchedule([]);
        // setSchedule(await Schedules.loadSchedule(date, {'schedule.requested': 1, 'schedule.scheduled': 0}, null));
    }

    const handleScheduleRequestsDateSelect = async (date) => {
        const tempDate = date;
        setScheduleRequestsDate(tempDate);
        await loadScheduleRequests(tempDate);
    }

    const resequenceAndSaveFromMove = async (dragged, hovered) => {
        if(!hovered && !dragged) {
            return;
        }

        scheduleRequests.splice(hovered.index, 0, scheduleRequests.splice(dragged.index, 1)[0]);
        const scheduleRequestsWithSameCrew = scheduleRequests.filter(sr => parseInt(sr.crew.crew_id) === parseInt(dragged.original.crew.crew_id));
        scheduleRequestsWithSameCrew.forEach((sr, index) => {
            sr.routing_seq = index + 1;
        })
        scheduleRequests.sort((sr1, sr2) => {
            if(parseInt(sr1.crew.crew.number) === parseInt(sr2.crew.crew.number)){
                return sr1.routing_seq < sr2.routing_seq ? -1 : 1;
            }
            return parseInt(sr1.crew.crew.number) < parseInt(sr2.crew.crew.number) ? -1 : 1;
        });
        setScheduleRequests([...scheduleRequests]);

        for(let i = 0; i < scheduleRequestsWithSameCrew.length; i++){
            const dbObj = {
                id: scheduleRequestsWithSameCrew[i].id,
                routing_seq: scheduleRequestsWithSameCrew[i].routing_seq
            }

            await Schedules.saveSchedule(dbObj);
        }
    }

    const handleMultiRowChange = async () => {
        for(let i = 0; i < scheduleRequestRowsToEdit.length; i++){
            const dbObj = {
                id: scheduleRequestRowsToEdit[i].id,
                schedule_date: dayjs(multiRowEditBoxDisplayData.requestedDate).format('YYYY-MM-DD'),
                crew: {
                    crew_id: multiRowEditBoxDisplayData.crew.id,
                    employees: multiRowEditBoxDisplayData.employees.map(ae => {
                        return {
                            employee_id: ae.value
                        }
                    })
                },
            }
            await Schedules.saveSchedule(dbObj);
        }
        await loadScheduleRequests(scheduleRequestsDate);
        handleMultiRowChangeCancel();
    }

    const handleMultiRowChangeCancel = () => {
        setScheduleRequestRowsToEdit([]);
        setMultiRowEditBoxDisplayData({
            requestedDate: null,
            crew: null,
            employees: [],
        })
        setOpenMultiRowChangeForm(false);
    }

    const handleDateSelect = async (date) => {
        const tempDate = new Date(date);
        await handleLoadSchedule(tempDate)
       // setSchedule(await Schedules.loadSchedule(tempDate, {'schedule.requested': 1, 'schedule.scheduled': 0}, null));
        setScheduleRequest({...scheduleRequest, requestedDate: tempDate});
    }

    const handlePhaseSelect = async (phase) => {
        if(scheduleRequest.requestedDate === null || scheduleRequest.requestedDate === ''){
            alert('Please Select A Date First')
        }
        else {
            if (phase === null) {
                setScheduleRequest({...scheduleRequest, phase: null});
                return;
            }
            setScheduleRequest({...scheduleRequest, phase: phase});
            if (useEWAPhaseTasks) {
                setPhaseTasks(await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task_phases.phase_id=${phase.id} and type='ewa'`));
            } else {
                setPhaseTasks(await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task_phases.phase_id=${phase.id} and type='work'`));
            }

            const tempCrews = await Auth.hitEndpointNew("GET", "crews-new", `jsonString=crew.company='${phase.company}'`);
            let date = dayjs(scheduleRequest.requestedDate).format('YYYY-MM-DD')
            setCrews(tempCrews.map(c => {
                const leader = c.members.find(m => m.position === "Leader");
                if (leader === undefined) {
                    c.leader = "No Members"
                } else {
                    c.leader = `${leader.employee.first_name} ${leader.employee.last_name}`;
                }
                c.members.map(m => {
                    m.employee.scheduled_off = m.employee.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
                    return (m)
                })
                return c;
            }).sort((c1, c2) => parseInt(c1.number) < parseInt(c2.number) ? -1 : 1));
        }
    }

    const handlePhaseTaskSelect = async (phaseTask) => {
        if(phaseTask === null){
            setScheduleRequest({...scheduleRequest, phaseTask: null});
            return;
        }
        setScheduleRequest({...scheduleRequest, phaseTask: phaseTask});
        let whereStr = 'jsonString=';
        for(let i = 0; i < phaseTask.phases.length; i++){
            whereStr += `${phaseTask.phases[i].company.toLowerCase()}=1`;
            if(i + 1 !== phaseTask.phases.length){
                whereStr += " and ";
            }
        }

        let date = dayjs(scheduleRequest.requestedDate).format('YYYY-MM-DD')
        const tempEmployees = await Auth.hitEndpointNew("GET", "employees-new", whereStr);
        setEmployees(tempEmployees.map(e => {
            e.scheduled_off = e.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
            return(e)
        }).sort((e1, e2) => e1.first_name < e2.first_name ? -1 : 1));
    }

    const handleCrewSelect = (crew) => {
        if(crew === null){
            setScheduleRequest({
                ...scheduleRequest,
                crew: null,
                employees: []
            });
            return;
        }
        setScheduleRequest({
            ...scheduleRequest,
            crew: crew,
            employees: crew.members.filter(x => !x.employee.scheduled_off).map(m => {
                return {
                    original: m,
                    value: m.employee.id,
                    label: `${m.employee.first_name} ${m.employee.last_name}`,
                    isLeader: m.position === "Leader"
                }
            })
        });
    }

    const handleJobSelect = async (job) => {
        if(!job){
            setScheduleRequest({...scheduleRequest, job: null});
            setLots([]);
            setFilteredLots([]);
            return;
        }
        setScheduleRequest({...scheduleRequest, job: job});
        const tempLots = await Schedules.loadAvailableLots(
            job.id,
            scheduleRequest.phase.id,
            [scheduleRequest.phaseTask.id],
            schedule
        );
        setLots(tempLots);
        setFilteredLots(tempLots);
    }

    const handleScheduleRequestFormOK = async () => {
        if(scheduleRequest.requestedDate === null){
            setError("ERROR_MISSING_DATE");
            return;
        }
        if(scheduleRequest.requestedBy === null){
            setError("ERROR_MISSING_SUPER");
            return;
        }
        if(scheduleRequest.phase === null){
            setError("ERROR_MISSING_PHASE");
            return;
        }
        if(scheduleRequest.phaseTask === null){
            setError("ERROR_MISSING_PHASE_TASK");
            return;
        }
        if(scheduleRequest.crew === null){
            setError("ERROR_MISSING_CREW");
            return;
        }
        if(scheduleRequest.lots.length === 0){
            setError("ERROR_MISSING_LOTS");
            return;
        }

        for(let i = 0; i < scheduleRequest.lots.length; i++){
            scheduleRequest.employees.sort((e1, e2) => e1.isLeader ? -1 : 1)
            const dbObj = {
                lot_id: scheduleRequest.lots[i].id,
                phase_id: scheduleRequest.phase.id,
                phase_task_id: scheduleRequest.phaseTask.id,
                schedule_date: dayjs(scheduleRequest.requestedDate).format('YYYY-MM-DD'),
                crew: {
                    crew_id: scheduleRequest.crew.id,
                    employees: scheduleRequest.employees.map(ae => {
                        return {
                            employee_id: ae.value
                        }
                    })
                },
                notes: scheduleRequest.notes,
                requested_by: scheduleRequest.requestedBy.id,
                deleted_at: null,
                deleted_by: null,
                requested: true,
                scheduled: false
            };

            if(scheduleRequest.id !== ''){
                dbObj.id = scheduleRequest.id;
            }
            // Assign routing number
            else{
                const scheduleRequestsWithSameCrew = scheduleRequests.filter(sr => parseInt(sr.crew.crew_id) === parseInt(dbObj.crew.crew_id));
                if(scheduleRequestsWithSameCrew.length !== 0){
                    dbObj.routing_seq = parseInt(scheduleRequestsWithSameCrew.at(-1).routing_seq) + 1 + i;
                }
                else{
                    dbObj.routing_seq = 1 + i;
                }
                scheduleRequestsWithSameCrew.sort((sr1, sr2) => parseInt(sr1.routing_seq) < parseInt(sr2.routing_seq));
            }

            await Schedules.saveSchedule(dbObj);
        }

        setOpenCreateScheduleRequestForm(false);
        setOpenEditScheduleRequestForm(false);
        setLotFilter('');
        setLots([]);
        setFilteredLots([]);
        setCrews([]);
        setPhaseTasks([]);
        setScheduleRequest({
            id: '',
            requestedDate: null,
            requestedBy: null,
            phase: null,
            phaseTask: null,
            crew: null,
            employees: [],
            notes: '',
            job: null,
            lots: []
        });
        setError({
            type: '',
            message: ''
        });

        await loadScheduleRequests(scheduleRequestsDate);

    }

    const handleScheduleRequestReset = async () => {
        setOpenCreateScheduleRequestForm(false);
        setOpenEditScheduleRequestForm(false);
        setOpenDeleteScheduleRequestForm(false);
        setLotFilter('');
        setLots([]);
        setFilteredLots([]);
        setCrews([]);
        setPhaseTasks([]);
        setScheduleRequest({
            id: '',
            requestedDate: null,
            requestedBy: null,
            phase: null,
            phaseTask: null,
            crew: null,
            employees: [],
            notes: '',
            job: null,
            lots: []
        });
        setError({
            type: '',
            message: ''
        });
    }

    const handlePopulateScheduleRequest = async (row) => {
        const tempScheduleRequest = {};
        tempScheduleRequest.id = row.id;

        const tempRequestedDate = row.schedule_date;
        await handleLoadSchedule(tempRequestedDate)
      //  setSchedule(await Schedules.loadSchedule(tempRequestedDate, '', null));
        tempScheduleRequest.requestedDate = tempRequestedDate;
        tempScheduleRequest.requestedBy = row.requestedBy !== null ? supers.find(s => parseInt(s.id) === parseInt(row.requested_by)) : {...user}

        // Phase
        const tempPhaseId = row.task.phases[0].id;
        const tempPhase = phases.find(p => parseInt(p.id) === parseInt(tempPhaseId));
        tempScheduleRequest.phase = tempPhase;

        let date = dayjs(tempRequestedDate).format('YYYY-MM-DD')
       // let tempCrews = await Auth.hitEndpointNew("GET", "crews-new", `jsonString=crew.company='${tempPhase.company}'`);
        let tempCrews = await Auth.hitEndpointNew("GET", "crews-new", null);
        tempCrews = tempCrews.map(c => {
            const leader = c.members.find(m => m.position === "Leader");
            if(leader === undefined){
                c.leader = "No Members"
            }
            else{
                c.leader = `${leader.employee.first_name} ${leader.employee.last_name}`;
            }
            c.members.map(m => {
                m.employee.scheduled_off = m.employee.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
                return (m)
            })
            return c;
        }).sort((c1, c2) => parseInt(c1.number) < parseInt(c2.number) ? -1 : 1);

        setCrews(tempCrews);

        // Phase Task
        const tempPhaseTasks = await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task_phases.phase_id=${tempPhase.id}`)
        const tempPhaseTask = tempPhaseTasks.find(pt => parseInt(pt.id) === parseInt(row.task.id));
        tempScheduleRequest.phaseTask = tempPhaseTask;

        let whereStr = 'jsonString=';
        for(let i = 0; i < tempPhaseTask.phases.length; i++){
            whereStr += `${tempPhaseTask.phases[i].company.toLowerCase()}=1`;
            if(i + 1 !== tempPhaseTask.phases.length){
                whereStr += " and ";
            }
        }
        const tempEmployees = await Auth.hitEndpointNew("GET", "employees-new", whereStr);
        setEmployees(tempEmployees.map(e => {
            e.scheduled_off = e.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
            return(e)
        }).sort((e1, e2) => e1.first_name < e2.first_name ? -1 : 1));

        // Crew
        const tempCrew = tempCrews.find(c => parseInt(c.id) === parseInt(row.crew.crew_id));
        tempCrew.value = tempCrew.id;
        tempCrew.label = `${tempCrew.number} - ${tempCrew.leader}`;
        tempScheduleRequest.crew = tempCrew;

        // Employees
        const tempEmployeesForScheduleRequest = [...row.crew.employees].map(e => {
            e.value = e.employee.id;
            e.label = `${e.employee.first_name} ${e.employee.last_name}`;
            return e;
        });
        tempScheduleRequest.employees = tempEmployeesForScheduleRequest;

        // Notes
        tempScheduleRequest.notes = row.notes;

        // Job
        const tempJob = jobs.find(j => parseInt(j.id) === parseInt(row.lot.job.id));
        tempScheduleRequest.job = tempJob;

        // Lots
        const tempLot = {...row.lot};
        tempLot.value = tempLot.id;
        tempLot.label = `${tempLot.number} - ${tempLot.phase} - ${tempLot.address}`
        tempScheduleRequest.lots = [tempLot];
        setScheduleRequest(tempScheduleRequest);
    }

    const handleRequestDeleteScheduleRequest = async (row) => {
        await handlePopulateScheduleRequest(row);
        setOpenDeleteScheduleRequestForm(true);
    }

    const handleDeleteScheduleRequest = async () => {
        if(!scheduleRequest.id){
            await handleScheduleRequestReset();
            return;
        }

        const dbObj = {
            id: scheduleRequest.id,
            deleted_at: Globals.formatDateForDatabase(new Date()),
            deleted_by: await Auth.getUserId(),
        };

        await Schedules.saveSchedule(dbObj);

        await handleScheduleRequestReset();
        await loadScheduleRequests(scheduleRequestsDate);
    }

    const handlePopulateMultiRowEdit = async (row) => {
        const tempFirstRow = {};

        tempFirstRow.requestedDate = row.schedule_date;
        tempFirstRow.requestedBy = row.requestedBy !== null ? supers.find(s => parseInt(s.id) === parseInt(row.requested_by)) : {...user}

        let date = dayjs(row.schedule_date).format('YYYY-MM-DD')
        // Phase
        const tempPhase = phases.find(p => parseInt(p.id) === parseInt(row.task.phase_id));

       // let tempCrews = await Auth.hitEndpointNew("GET", "crews-new", `jsonString=crew.company='${tempPhase.company}'`);
        let tempCrews = await Auth.hitEndpointNew("GET", "crews-new", null);
        tempCrews = tempCrews.map(c => {
            const leader = c.members.find(m => m.position === "Leader");
            if(leader === undefined){
                c.leader = "No Members"
            }
            else{
                c.leader = `${leader.employee.first_name} ${leader.employee.last_name}`;
            }
            c.members.map(m => {
                m.employee.scheduled_off = m.employee.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
                return (m)
            })
            return c;
        }).sort((c1, c2) => parseInt(c1.number) < parseInt(c2.number) ? -1 : 1);
        setCrews(tempCrews);

        // Phase Task
        const tempPhaseTasks = await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task.phase_id=${tempPhase.id}`)
        const tempPhaseTask = tempPhaseTasks.find(pt => parseInt(pt.id) === parseInt(row.task.id));

        let whereStr = 'jsonString=';
        for(let i = 0; i < tempPhaseTask.phases.length; i++){
            whereStr += `${tempPhaseTask.phases[i].company.toLowerCase()}=1`;
            if(i + 1 !== tempPhaseTask.phases.length){
                whereStr += " and ";
            }
        }
        const tempEmployees = await Auth.hitEndpointNew("GET", "employees-new", whereStr);
        setEmployees(tempEmployees.map(e => {
            e.scheduled_off = e.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
            return(e)
        }).sort((e1, e2) => e1.first_name < e2.first_name ? -1 : 1));
        // Crew
        const tempCrew = tempCrews.find(c => parseInt(c.id) === parseInt(row.crew.crew_id));
        tempCrew.value = tempCrew.id;
        tempCrew.label = `${tempCrew.number} - ${tempCrew.leader}`;
        tempFirstRow.crew = tempCrew;

        // Employees
        const tempEmployeesForScheduleRequest = [...row.crew.employees].map(e => {
            e.value = e.employee.id;
            e.label = `${e.employee.first_name} ${e.employee.last_name}`;
            return e;
        });
        tempFirstRow.employees = tempEmployeesForScheduleRequest;

        setMultiRowEditBoxDisplayData(tempFirstRow);
    }

    return (
        <Grid sx={{
            margin: '10px'
        }}>
            <MainHeader/>
            <MaterialReactTable
                autoResetPageIndex={false}
                columns={columns}
                data={scheduleRequests !== undefined ? scheduleRequests : []}
                enableRowOrdering
                editDisplayMode='cell'
                enableCellActions={true}
                enableEditing={true}
                enableSorting={false}
                filterFns={{
                    filterCrews: (row, id, filterValue) => {
                        const values = filterValue.split('-');
                        const crewNumStr = row.original.crew.crew.number;
                        const crewNum = parseInt(row.original.crew.crew.number);
                        if(values.length === 1){
                            return crewNumStr.includes(values[0]);
                        }
                        if(values.length === 2){
                            const v1 = parseInt(values[0]);
                            const v2 = parseInt(values[1]);
                            if(v1 > v2 && crewNum >= v2 && crewNum <= v1){
                                return true;
                            }
                            else if(crewNum >= v1 && crewNum <= v2){
                                return true;
                            }
                        }
                        return false;
                    }
                }}
                muiRowDragHandleProps={({table}) => {
                    return {
                        onDragEnd: () => {
                            const { draggingRow, hoveredRow } = table.getState();
                            resequenceAndSaveFromMove(draggingRow, hoveredRow);
                        }
                    }
                }}
                muiTableBodyRowProps={({row, table}) => {
                    return {
                        hover: false,
                        sx: {
                            backgroundColor: `${row.original.bgColor} !important`,
                        }
                    }
                }}
                enableStickyHeader
                enablePagination={false}
                initialState={{
                    hoveredRow: null,
                    // pagination: {pageSize: 100, pageIndex: 0},
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Grid
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "repeat(10, 1fr)",
                                width: "100%",
                                margin: "5px 0",
                            }}
                        >
                            <Grid
                                sx={{
                                    gridColumn: "1/3",
                                    textAlign: "center",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    cursor: "default",
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={handleScheduleRequestsDateSelect}
                                        label={"Schedule Requests Date"}
                                        slotProps={{
                                            textField: {
                                                helperText: '',
                                                sx: {
                                                    width: '100%',
                                                    minWidth: '120px',
                                                },
                                            },
                                        }}
                                        defaultValue={dayjs(scheduleRequestsDate)}
                                        selected={scheduleRequestsDate}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                sx={{
                                    gridColumn: "3/9",
                                    textAlign: "center",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    cursor: "default",
                                }}
                            >
                                SVC Schedule Requests
                            </Grid>
                            <Grid
                                sx={{
                                    gridColumn: "9/10",
                                    display: 'grid',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={() => setOpenCreateScheduleRequestForm(true)}
                                >Add Request</Button>
                            </Grid>
                            <Grid
                                sx={{
                                    gridColumn: "10/11",
                                    display: 'grid',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={async () => {
                                        const selectedRows = table.getSelectedRowModel().rows;
                                        if(selectedRows.length > 0){
                                            await handlePopulateMultiRowEdit(selectedRows[0].original);
                                            setScheduleRequestRowsToEdit(selectedRows.map(r => r.original))
                                            setOpenMultiRowChangeForm(true);
                                            setRowSelection({});
                                        }
                                    }}
                                >Edit Selected</Button>
                            </Grid>
                        </Grid>
                    );
                }}
                positionToolbarAlertBanner='hide'
                enableRowSelection={true}
                getRowId={(row) => row.id}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowActions={true}
                renderRowActions={({ row, table }) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip title="Edit">
                                <IconButton onClick={async () => {
                                    await handlePopulateScheduleRequest(row.original);
                                    setOpenEditScheduleRequestForm(true);
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => handleRequestDeleteScheduleRequest(row.original)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                }}

            />
            {openCreateScheduleRequestForm &&
            <Dialog 
                open={openCreateScheduleRequestForm}
                sx={{maxWidth: 'xl'}}
                maxWidth={'xl'}
            >
                <DialogTitle>SVC Schedule Request Form</DialogTitle>
                <DialogContent sx={{width: "800px", maxWidth: 'xl'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={handleDateSelect}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {
                                        width: '50%',
                                        minWidth: '120px',
                                        backgroundColor: error === "ERROR_MISSING_DATE" ? 'rgb(230, 100, 100)' : 'white',
                                    },
                                },
                            }}
                            defaultValue={dayjs(scheduleRequest.requestedDate)}
                            selected={scheduleRequest.requestedDate}
                        />
                    </LocalizationProvider>
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    width: '50%',
                                    height: '50px',
                                    margin: '10px 0',
                                    backgroundColor: error === "ERROR_MISSING_SUPER" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            }
                        }}
                        id="super-id"
                        placeholder="Select A Superintendent"
                        value={scheduleRequest.requestedBy}
                        onChange={(superintendent) => setScheduleRequest({...scheduleRequest, requestedBy: superintendent})}
                        options={isSuper ? [user] : supers}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    width: '50%',
                                    height: '50px',
                                    margin: '10px 0',
                                    backgroundColor: error === "ERROR_MISSING_PHASE" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            }
                        }}
                        id="phase-id"
                        placeholder="Select A Phase"
                        value={scheduleRequest.phase}
                        onChange={handlePhaseSelect}
                        options={phases}
                        isClearable
                        isSearchable
                    />
                    <FormControlLabel
                        control={<Switch
                            checked={useEWAPhaseTasks}
                            onChange={async (e) => {
                                setUseEWAPhaseTasks(e.target.checked)
                                if(scheduleRequest.phase !== null){
                                    if(e.target.checked){
                                        setPhaseTasks(await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task_phases.phase_id=${scheduleRequest.phase.id} and type='ewa'`));
                                    }
                                    else{
                                        setPhaseTasks(await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task_phases.phase_id=${scheduleRequest.phase.id} and type='work'`));
                                    }
                                }
                            }}/>}
                        label="Use EWA Phase Tasks"
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    width: '50%',
                                    height: '50px',
                                    margin: '10px 0',
                                    backgroundColor: error === "ERROR_MISSING_PHASE_TASK" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            }
                        }}
                        id="phase-task-id"
                        placeholder="Select Phase Task"
                        value={scheduleRequest.phaseTask}
                        onChange={handlePhaseTaskSelect}
                        options={phaseTasks.filter(pt => {
                            return pt.description !== 'Placeholder';
                        }).map(pt => {
                            return ({...pt, value: pt.id, label: pt.code !== null ? `${pt.code} - ${pt.description}` : pt.description})
                        })}
                        noOptionsMessage={() => 'Please select phase first'}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    width: '50%',
                                    height: '50px',
                                    margin: '10px 0',
                                    backgroundColor: error === "ERROR_MISSING_CREW" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            }
                        }}
                        id="crew-id"
                        placeholder="Select A Crew"
                        value={scheduleRequest.crew}
                        onChange={handleCrewSelect}
                        options={crews.map(c => ({...c, value: c.id, label: `${c.number} - ${c.leader}`}))}
                        isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    width: '50%',
                                    minHeight: '50px',
                                    backgroundColor: 'white',
                                    margin: '10px 0',
                                }
                            },
                        }}
                        id="added-employees-id"
                        placeholder="Add Employee(s)"
                        value={scheduleRequest.employees}
                        onChange={(employees => setScheduleRequest({...scheduleRequest, employees: employees}))}
                        options={employees.map(e => ({...e, value: e.id, label: `${e.first_name} ${e.last_name}`, isLeader: false}))}
                        isOptionDisabled={(option) => option.scheduled_off === 1 || option.scheduled_off === true}
                        noOptionsMessage={() => 'Please select crew first'}
                        isMulti
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    width: '50%',
                                    height: '50px',
                                    margin: '10px 0',
                                }
                            }
                        }}
                        id="job-id"
                        placeholder="Select Job"
                        value={scheduleRequest.job}
                        onChange={handleJobSelect}
                        options={scheduleRequest.phase && scheduleRequest.phaseTask ?
                            jobs.map(j => ({...j, value: j.value, label: j.label})) : []}
                        noOptionsMessage={() => 'Please select phase and phase task first'}
                        isClearable
                        isSearchable
                    />
                    <TextField
                        sx={{
                            width: '40%',
                            margin: '10px auto 10px'
                        }}
                        placeholder="Fitler Lot List"
                        value={lotFilter}
                        onChange={(e) => {
                            if(lots.length === 0){
                                return;
                            }

                            setFilteredLots(lots.filter(l => {
                                return l.label.includes(e.target.value);
                            }))
                            setLotFilter(e.target.value);
                        }}
                    />
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            columnGap: '5px'
                        }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridColumn: '1/2',
                            }}>
                            <Box sx={{margin: '0 0 3px'}}>Lot List</Box>
                            <Box
                                id="lot-select-box"
                                sx={{
                                    height: '150px',
                                    padding: '8px 0',
                                    overflowY: 'scroll',
                                    border: error === "ERROR_MISSING_LOTS" ? '5px solid red' : '1px solid rgb(200, 200, 200)',
                                    borderRadius: '4px'
                                }}
                            >
                                {filteredLots.length > 0 ?
                                filteredLots.map((lot, index) => {
                                    const backgroundColor = lot.selected ? 'rgb(0, 150, 255)' : 'white';
                                    const hoverBackgroundColor = lot.selected ? 'rgb(0, 150, 255)' : 'rgb(125, 249, 255)';
                                    return (
                                        <Box
                                            id={lot.label}
                                            key={lot.id}
                                            sx={{
                                                cursor: 'default',
                                                padding: '8px 15px',
                                                backgroundColor: backgroundColor,
                                                userSelect: 'none',
                                                '&:hover': {
                                                    backgroundColor: hoverBackgroundColor
                                                }
                                            }}
                                            onClick={(e) => {
                                                if(e.shiftKey && lastIndex !== ''){
                                                    let tempLastIndex = lastIndex;
                                                    let currentIndex = index;
                                                    if(tempLastIndex > currentIndex){
                                                        const tempIndex = currentIndex;
                                                        currentIndex = tempLastIndex;
                                                        tempLastIndex = tempIndex;
                                                    }

                                                    const lotsToAdd = [];
                                                    for(let i = tempLastIndex; i <= currentIndex; i++){
                                                        if(!scheduleRequest.lots.find(l => parseInt(l.id) === parseInt(filteredLots[i].id))){
                                                            lotsToAdd.push(filteredLots[i]);
                                                            filteredLots[i].selected = true;
                                                        }
                                                    }
                                                    setScheduleRequest({...scheduleRequest, lots: [...scheduleRequest.lots, ...lotsToAdd]});
                                                    
                                                    setLastIndex(index);
                                                    return;
                                                }

                                                if(lot.selected){
                                                    lot.selected = false;
                                                    const tempLots = scheduleRequest.lots.filter(l => parseInt(l.id) !== parseInt(lot.id));
                                                    setScheduleRequest({...scheduleRequest, lots: tempLots});
                                                    return;
                                                }

                                                lot.selected = true;
                                                setScheduleRequest({...scheduleRequest, lots: [...scheduleRequest.lots, lot]});
                                                setLastIndex(index);
                                            }}
                                        >
                                            {lot.label}
                                        </Box>
                                    );
                                }) :
                                <Box
                                    sx={{
                                        padding: '5px 10px',
                                        color: 'rgb(120, 120, 120)'
                                    }}
                                >
                                    Select Lot(s)
                                </Box>}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gridColumn: '2/3',
                            }}>
                            <Box sx={{margin: '0 0 3px'}}>Selected Lot(s) - Click To Remove</Box>
                            <Box
                                id="lot-select-box"
                                sx={{
                                    height: '150px',
                                    padding: '8px 0',
                                    overflowY: 'scroll',
                                    border: error === "ERROR_MISSING_LOTS" ? '5px solid red' : '1px solid rgb(200, 200, 200)',
                                    borderRadius: '4px'
                                }}
                            >
                                {scheduleRequest.lots.length > 0 ?
                                scheduleRequest.lots.map((lot, index) => {
                                    // const backgroundColor = lot.selected ? 'rgb(255, 150, 0)' : 'white';
                                    const backgroundColor = 'white';
                                    // const hoverBackgroundColor = lot.selected ? 'rgb(255, 150, 150)' : 'rgb(125, 249, 255)';
                                    const hoverBackgroundColor = 'rgb(255, 150, 150)'
                                    return (
                                        <Box
                                            id={lot.label}
                                            key={lot.id}
                                            sx={{
                                                cursor: 'default',
                                                padding: '8px 15px',
                                                backgroundColor: backgroundColor,
                                                userSelect: 'none',
                                                '&:hover': {
                                                    backgroundColor: hoverBackgroundColor
                                                }
                                            }}
                                            onClick={(e) => {
                                                lot.selected = false;
                                                const tempLots = scheduleRequest.lots.filter(l => parseInt(l.id) !== parseInt(lot.id));
                                                setScheduleRequest({...scheduleRequest, lots: tempLots});
                                            }}
                                        >
                                            {lot.label}
                                        </Box>
                                    );
                                }) :
                                <Box
                                    sx={{
                                        padding: '5px 10px',
                                        color: 'rgb(120, 120, 120)'
                                    }}
                                >
                                    Selected Lot(s)
                                </Box>}
                            </Box>
                        </Box>
                    </Box>
                    <TextField
                        sx={{
                            width: '50%',
                            margin: '10px auto',
                            zIndex: '0',
                        }}
                        placeholder="Enter Notes"
                        value={scheduleRequest.notes}
                        onChange={(e) => setScheduleRequest({...scheduleRequest, notes: e.target.value})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleScheduleRequestFormOK}
                    >OK</Button>
                    <Button
                        variant='contained'
                        onClick={handleScheduleRequestReset}
                    >Cancel</Button>
                </DialogActions>
            </Dialog>}
            {openEditScheduleRequestForm && scheduleRequest &&
            <Dialog open={openEditScheduleRequestForm}>
                <DialogTitle sx={{borderBottom: '2px solid black'}}>Edit Schedule Request</DialogTitle>
                <DialogContent sx={{width: "600px"}}>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0 10px'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Super:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.requestedBy.label}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Phase:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.phase.description}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Phase Task:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.phaseTask.code} - {scheduleRequest.phaseTask.description}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Job:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.job.number} - {scheduleRequest.job.builder.name} - {scheduleRequest.job.project_name}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Lot:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.lots[0].phase ? `${scheduleRequest.lots[0].number} - ${scheduleRequest.lots[0].phase}` : `${scheduleRequest.lots[0].number}`}
                        </Box>
                    </Box>
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    margin: '10px auto',
                                    minHeight: '50px',
                                    backgroundColor: error === "ERROR_MISSING_CREW" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            }
                        }}
                        id="crew-id"
                        placeholder="Select A Crew"
                        value={scheduleRequest.crew}
                        onChange={handleCrewSelect}
                        options={crews.map(c => ({...c, value: c.id, label: `${c.number} - ${c.leader}`}))}
                        isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    minHeight: '50px',
                                    backgroundColor: 'white',
                                    margin: '10px auto',
                                }
                            },
                        }}
                        id="added-employees-id"
                        placeholder="Add Employee(s)"
                        value={scheduleRequest.employees}
                        onChange={(employees => setScheduleRequest({...scheduleRequest, employees: employees}))}
                        options={employees.map(e => ({...e, value: e.id, label: `${e.first_name} ${e.last_name}`, isLeader: false}))}
                        noOptionsMessage={() => 'Please select crew first'}
                        isOptionDisabled={(option) => option.scheduled_off === 1 || option.scheduled_off === true}
                        isMulti
                        isClearable
                        isSearchable
                    />
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '0 auto 10px',
                            zIndex: '0',
                        }}
                        placeholder="Enter Notes"
                        value={scheduleRequest.notes}
                        onChange={(e) => setScheduleRequest({...scheduleRequest, notes: e.target.value})}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={handleDateSelect}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {
                                        width: '100%',
                                        minWidth: '120px',
                                        backgroundColor: error === "ERROR_MISSING_DATE" ? 'rgb(230, 100, 100)' : 'white',
                                    },
                                },
                            }}
                            defaultValue={dayjs(scheduleRequest.requestedDate)}
                            selected={scheduleRequest.requestedDate}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleScheduleRequestFormOK}>OK</Button>
                    <Button variant='contained' onClick={() => handleScheduleRequestReset(null)}>Cancel</Button>
                </DialogActions>
            </Dialog>}
            {openDeleteScheduleRequestForm && scheduleRequest.requestedBy.label !== undefined &&
            <Dialog open={openDeleteScheduleRequestForm !== null}>
                <DialogTitle sx={{borderBottom: '1px solid black'}}>Delete Schedule Request</DialogTitle>
                <DialogContent sx={{width: "600px"}}>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '25px 0 15px'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Super:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.requestedBy.label}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Phase:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.phase.description}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Phase Task:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.phaseTask.code} - {scheduleRequest.phaseTask.description}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Job:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.job.number} - {scheduleRequest.job.builder.name} - {scheduleRequest.job.project_name}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Lot:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.lots[0].phase ? `${scheduleRequest.lots[0].number} - ${scheduleRequest.lots[0].phase}` : `${scheduleRequest.lots[0].number}`}
                        </Box>
                    </Box>

                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Crew:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleRequest.crew.number} - {scheduleRequest.crew.leader}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Scheduled Date:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {Globals.formatDateToDisplay(scheduleRequest.requestedDate)}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleDeleteScheduleRequest}>OK</Button>
                    <Button variant='contained' onClick={handleScheduleRequestReset}>Cancel</Button>
                </DialogActions>
            </Dialog>}
            {openMultiRowChangeForm &&
            <Dialog open={openMultiRowChangeForm}>
                <DialogTitle>Selected Rows Edit</DialogTitle>
                <DialogContent sx={{width: "600px", height: '500px'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => {
                                const tempDate = new Date(date);
                                setMultiRowEditBoxDisplayData({...multiRowEditBoxDisplayData, requestedDate: tempDate})
                            }}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {
                                        width: '100%',
                                        minWidth: '120px',
                                    },
                                },
                            }}
                            defaultValue={dayjs(multiRowEditBoxDisplayData.requestedDate)}
                            selected={multiRowEditBoxDisplayData.requestedDate}
                        />
                    </LocalizationProvider>
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    margin: '10px auto',
                                    minHeight: '50px',
                                }
                            }
                        }}
                        id="crew-id"
                        placeholder="Select A Crew"
                        value={multiRowEditBoxDisplayData.crew}
                        onChange={(crew) => {
                            setMultiRowEditBoxDisplayData({
                                ...multiRowEditBoxDisplayData,
                                crew: crew,
                                employees: crew !== null ? crew.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).map(m => {
                                    return {
                                        original: m,
                                        value: m.employee.id,
                                        label: `${m.employee.first_name} ${m.employee.last_name}`,
                                        isLeader: m.position === "Leader"
                                    }
                                }) : []
                            });
                        }}
                        options={crews.map(c => ({...c, value: c.id, label: `${c.number} - ${c.leader}`}))}
                        isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    minHeight: '50px',
                                    backgroundColor: 'white',
                                    margin: '10px auto',
                                }
                            },
                        }}
                        id="added-employees-id"
                        placeholder="Add Employee(s)"
                        value={multiRowEditBoxDisplayData.employees}
                        onChange={(employees => setMultiRowEditBoxDisplayData({...multiRowEditBoxDisplayData, employees: employees}))}
                        options={employees.map(e => ({...e, value: e.id, label: `${e.first_name} ${e.last_name}`, isLeader: false}))}
                        noOptionsMessage={() => 'Please select crew first'}
                        isOptionDisabled={(option) => option.scheduled_off === 1 || option.scheduled_off === true}
                        isMulti
                        isClearable
                        isSearchable
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleMultiRowChange}
                    >OK</Button>
                    <Button
                        variant='contained'
                        onClick={handleMultiRowChangeCancel}
                    >Cancel</Button>
                </DialogActions>
            </Dialog>}
        </Grid>
    );
}

export default SVCScheduleRequest;
