import Auth from "../components/authentication/Auth";
import moment from "moment/moment";

// Crew data from database goal
// {
//     _id: "",
//     active: true,
//     number: "",
//     scope: "",
//     company: "",
//     phoneNumber: "",
//     members: [
//         {
//             memberId: "",
//             position: "",
//             name: "",            // Set at load
//             phoneNumber: "",     // Set at load
//             onCrew: true         // Set at load
//         }
//     ],
//     editedBy: {
//         name: "",  
//         date: new Date()
//     }
// }

class Crews{
    static async loadCrews(queryObj = null, employees = null){
        let data = [];
        let dbData;
        if(queryObj){
            const jsonString = JSON.stringify(queryObj)
            dbData = await Auth.hitEndpoint("GET", "CREWS", `jsonString=${jsonString}`);
        }
        else{
            dbData = await Auth.hitEndpoint("GET", "CREWS");
        }
        data = [...data, ...dbData];

        // Convert all dates from strings to date objects
        // Add extra member data
        data.forEach(crew => {
            crew.members.forEach(member => {
                const employee = employees !== undefined && employees !== null ? employees.find(employeeToFind => employeeToFind.id === member.employee_id) : {first_ame: '', last_name: '', alias: '', phone_number: ''  };
                member.name = `${employee.first_ame} ${employee.last_name}`;
                member.phone_number = employee.phone_number;
                member.onCrew = true;
            })
            crew.modified_at = new Date(crew.modified_at);
        })

        return data;
    }

    static async loadCrewsForDropdown(queryObj, date = null) {
        const limit = 50;
        let skip = 0;
        let data = [];
        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj)
            dbData = await Auth.hitEndpoint("GET", "CREWS", `selector=three&jsonString=${jsonString}&skip=${skip}&limit=${limit}`);
        } else {
            dbData = await Auth.hitEndpoint("GET", "CREWS", `selector=two&skip=${skip}&limit=${limit}`);
        }
        data = [...data, ...dbData];
        skip += limit;

        // Convert all dates from strings to date objects
        // Add extra member data
        data.forEach(crew => {
            crew.members.forEach(member => {
                if (member.position === 'Leader') {
                    crew.display = member.employee.alias !== null && member.employee.alias !== '' ?
                        `${crew.number} - ${member.employee.alias} - (${member.employee.first_name} ${member.employee.last_name})` :
                        `${crew.number} - ${member.employee.first_name} ${member.employee.last_name}`
                    crew.label = member.employee.alias !== null && member.employee.alias !== '' ?
                        `${crew.number} - ${member.employee.alias} - (${member.employee.first_name} ${member.employee.last_name})` :
                        `${crew.number} - ${member.employee.first_name} ${member.employee.last_name}`
                    crew.value = crew.id;
                }
                if(date !== null) {
                    member.employee.scheduled_off = member.employee.time_off.filter(x => x.start_date <= date && x.end_date >= date && x.approved === 1).length > 0
                }
            })
        })
        return data;
    }

    static async saveNewCrew(crew){
        crew.modified_at = moment(new Date(crew.modified_at)).format('YYYY-MM-DD H:mm:ss');
        const result = await Auth.hitEndpointNew("POST", "crew", "", crew); 
        crew.id = result.id;

        return crew;
    }

    static createCrewDBObject(crew){
        const dbObject = {};
        if(crew.id !== undefined){ dbObject.id = crew.id; }
        if(crew.active !== undefined){ dbObject.active = crew.active; }
        if(crew.modified_at !== undefined){ dbObject.modified_at = moment(new Date(crew.modified_at)).format('YYYY-MM-DD H:mm:ss'); }
        if(crew.modified_by !== undefined){ dbObject.modified_by = crew.modified_by; }
        if(crew.number !== undefined){ dbObject.number = crew.number; }
        if(crew.phone_number !== undefined){ dbObject.phone_number = crew.phone_number; }
        if(crew.scope !== undefined){ dbObject.crew_scope_id = crew.scope.id; }
        if(crew.scope !== undefined){ dbObject.scope = crew.scope; }
        if(crew.version !== undefined){ dbObject.version = crew.version; }
        if(crew.company !== undefined){ dbObject.company = crew.company; }
        if(crew.members !== undefined){
            const members = [];
            for(let i = 0; i < crew.members.length; i++){
                const member = {};
                if(crew.members[i].crew_id !== undefined){ member.crew_id = crew.members[i].crew_id; }
                if(crew.members[i].employee_id !== undefined){ member.employee_id = crew.members[i].employee_id; }
                if(crew.members[i].position !== undefined){ member.position = crew.members[i].position; }
                members.push(member);
            }
            dbObject.members = members;
        }
        dbObject.scheduleReports = crew.scheduleReports

        return dbObject;
    }

    static async updateCrew(crew, user){
        crew.modified_by = await Auth.getUserId();
        crew.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');

        await Auth.hitEndpoint("PATCH", "CREWS", "", crew); 

        return crew;
    }
    static async deleteCrewScheduleReport(scheduleReportIds){
        await Auth.hitEndpointNew("POST", "crew/schedule-reports", "", scheduleReportIds);
        return scheduleReportIds;
    }

    static async deleteCrew(crew){
        await Auth.hitEndpoint("DELETE", "CREWS", "", crew); 
        return crew;
    }

    static sortCrews(sortBy, order, crews){
        const firstValue = order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        return crews.sort((c1, c2) => {
            if(sortBy === "number"){
                return Number.parseInt(c1.number) < Number.parseInt(c2.number) ? firstValue : secondValue;
            }

            return firstValue;
        })
    }

    static async createNewCrew(user){
        return {
            active: 1,
            number: "",
            scope: "",
            company: "",
            phone_number: "",
            members: [],
            scheduleReports: [],
            modified_by: await Auth.getUserId(),
            modified_at: new Date()
        };
    }

    static async checkCrewForErrors(crew){
        if(crew.number === ""){return "ERROR_NO_CREW_NUMBER";}
        if(crew.scope === ""){return "ERROR_NO_SCOPE";}

        // Create temp filtered members array
        const tempMembers = crew.members.filter(memberToFilter => memberToFilter.onCrew);
        if(tempMembers.length > 0){

            let hasLeader = false;
            for(let i = 0; i < tempMembers.length; i++){
                if(hasLeader && tempMembers[i].position === "Leader"){return "ERROR_MORE_THAN_ONE_LEADER";}
                if(tempMembers[i].position === "Leader"){hasLeader = true;}
                if(tempMembers[i].position === ""){return "ERROR_CREW_MEMBER_POSITION_EMPTY";}
            }
            if(!hasLeader){return "ERROR_NO_CREW_LEADER";}
        }



        // Check for duplicate crew numbers
        const jsonString = JSON.stringify({number: crew.number});
        let dupData = await Auth.hitEndpoint("GET", "CREWS", `jsonString=${jsonString}`);
        if(crew.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id !== crew.id);
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_CREW_NUMBER";}

        if(crew.scheduleReports.length === 0){
            return "ERROR_NO_HOME_REPORT_SELECTED"
        }
        return "SUCCESS";
    }

    static async getCrewScopes(){
        let dbData;
        dbData = await Auth.hitEndpointNew("GET", `crew-scopes`);
        return dbData
    }

    static getCrewMemberPositions(){
        return [
            'Leader',
            'Member'
        ]
    }

    static addCrewMember(employee_id, crew){
        if(crew.members.find(memberToFind => parseInt(memberToFind.employee_id) === Number.parseInt(employee_id))){
            return crew;
        }
        
        crew.members.push({
            crew_id: null,
            employee_id: Number.parseInt(employee_id),
            position: "",
            onCrew: true,
        })
        
        return {...crew};
    }

    static removeCrewMember(employee_id, crew){
        crew.members.forEach(member => {
            if(member.employee_id === employee_id){
                member.onCrew = !member.onCrew;
            }
        })
        return {...crew};
    }

    static setCrewMemberPosition(position, employee_id, crew){
        crew.members.forEach(member => {
            if(member.employee_id === employee_id){
                member.position = position;
            }
        })
        return {...crew};
    }
}

export default Crews;