import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
// import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete'

const CrewGrid = ({selectedEmployeesEdit, setSelectedEmployeesEdit, empsToRemove, setEmpsToRemove, minHeight, marginTop, isExisting}) => {

    const handleRemoveEmployee = async (item) => {

        let current = [...selectedEmployeesEdit]
        let index = current.indexOf(item)
        current.splice(index, 1)
        let remove = [...empsToRemove]
        remove.push(item)
        setEmpsToRemove(remove)
        setSelectedEmployeesEdit(current)
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        padding: "20px",
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        border: "1pt solid",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        marginTop: marginTop,
        width: "100%",
        minHeight: minHeight,
    });

    function handleOnDragEnd(result) {
        const items = Array.from(selectedEmployeesEdit);
        let sourceItem = items[result.source.index]
        if (!result.destination || (sourceItem.approved === 0 || sourceItem.approved === false)) {
            return
        }
        else {
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setSelectedEmployeesEdit(items);
        }
    }

    return (
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable">
                    {(droppableProvided, droppableSnapshot) => (
                        <div
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                        >
                            {selectedEmployeesEdit.map((item, index) => {
                                return (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(draggableProvided, draggableSnapshot) => (
                                        <div
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            style={getItemStyle(
                                                draggableSnapshot.isDragging,
                                                draggableProvided.draggableProps.style,
                                            )}>
                                            {index === 0 && <div style={{width: "10%", float: "left", marginTop:"-5px"}}>{item.crews}</div>}
                                            {index > 0 && <div style={{width: "10%", float: "left", marginTop:"-5px", color: "white"}}>{item.crews}</div>}
                                            {(item.alias === undefined || item.alias === null || item.alias === '') && <div style={{width: "70%", float: "left", marginTop:"-5px"}}>{item.first_name} {item.last_name}</div>}
                                            {item.alias !== undefined && item.alias !== null && item.alias !== '' && <div style={{width: "70%", float: "left", marginTop:"-5px"}}>{item.alias} - ({item.first_name} {item.last_name})</div>}
                                            <div>{item.approved}</div>
                                            {(isExisting || item.approved === 1 || item.approved === true) && <Button style={{width: "10%", float: "right", marginTop:"-15px"}} onClick={() => handleRemoveEmployee(item)}><DeleteIcon/></Button>}
                                        </div>
                                    )}
                                </Draggable>
                            )})}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
    )
}

export default CrewGrid;
