import {useEffect, useState} from 'react';
import moment from "moment/moment.js";
import config from '../../config'
import FileSaver from 'file-saver';

import { FileUploader } from "react-drag-drop-files";

import Auth from '../authentication/Auth.js';

import MainHeader from './MainHeader';
import Popup, {PopupWarningColor} from './Popup.js';
import PopupOk, {PopupOkWarningColor} from './PopupOk.js';
import DailyScheduleContainer from './styles/DailyScheduleContainer';
import * as AWS from "aws-sdk";
//import * as fs from "fs";

AWS.config.update(config.S3_INFO());

const DailySchedule = (props) => {
    const [documents, setDocuments] = useState([]);
    const [didLoadDocuments, setDidLoadDocuments] = useState(false);

    const [errorNoFilesSelected, setErrorNoFilesSelected] = useState(false);
    const [errorWrongFileType, setErrorWrongFileType] = useState(false);
    const [errorFileSizeToBig, setErrorFileSizeToBig] = useState(false);
    const [errorFileNameExists, setErrorFileNameExists] = useState(false);
    const [submitDeletePDFs, setSubmitDeletePDFs] = useState(false);

    const [message, setMessage] = useState(null);
    const [devMessage, setDevMessage] = useState(null);

    // Permissions
    const canLoadFiles = props.isAuthorized('dailySchedule', 'WRITE');
    const canDeleteFiles = props.isAuthorized('dailySchedule', 'WRITE');

    const fileTypes = ["PDF"];

    useEffect(() => {
        // Load current daily schedules
        const loadDocuments = async () => {
            // Get any message from database
            const result = await Auth.hitEndpointNew("GET", "messages");
            for(let i = 0; i < result.length; i++){
                if(result[i].type === 'message' && result[i].message.length > 0){
                    // Checks if messages are expired
                    if(result[i].expires){
                        let date = result[i].expires
                        result[i].expires = new Date(date);
                        const today = new Date();

                        if(result[i].expires < today){
                            result[i].expires = null;
                            result[i].message = '';
                            result[i].modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
                            await Auth.hitEndpointNew("PATCH", "message", "", result[i]);
                        }
                    }
                    setMessage(result[i]);
                }
                if(result[i].type === 'dev-message' && result[i].message.length > 0){
                    // Checks if messages are expired
                    if(result[i].expires){
                        let date = result[i].expires
                        result[i].expires = new Date(date);
                        const today = new Date();
                        if(result[i].expires < today){
                            result[i].expires = null;
                            result[i].message = '';
                            result[i].modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
                            await Auth.hitEndpointNew("PATCH", "message", "", result[i]);
                        }
                    }
                    setDevMessage(result[i]);
                }
            }

            if(document.getElementById('docs-message')){
                document.getElementById('docs-message').innerHTML = 'Looking For Documents...'
            }

            const schedules = await Auth.hitEndpoint("GET", "SCHEDULES");

            if(schedules.length === 0){
                document.getElementById('docs-message').innerHTML = 'No Documents In Database'
            }

            // Create individual pdfs
            const tempDocs = [];
          //  console.log(schedules)
            for(let i = 0; i < schedules.length; i++){
                const pdf = {
                    isChecked: false,
                    id: schedules[i].id,
                    name: schedules[i].name,
                    data: null,
                    url: schedules[i].aws_url
                }
                tempDocs.push(pdf);
            }
            setDocuments([...tempDocs]);
        }

        if(!didLoadDocuments){
            loadDocuments();
            setDidLoadDocuments(true);
        }
    }, [didLoadDocuments])

    const loadDocuments = async () => {
        const tempDocs = [];
        const schedules = await Auth.hitEndpoint("GET", "SCHEDULES");
        for(let i = 0; i < schedules.length; i++){
            const pdf = {
                isChecked: false,
                id: schedules[i].id,
                name: schedules[i].name,
                data: null,
                url: schedules[i].aws_url
            }
            tempDocs.push(pdf);
        }
        setDocuments([...tempDocs]);
    }

    const loadPDFToDatabase = async (name, url) => {
        // Create object for database
        const pdf = {
            name: name,
            aws_url: url
        }

        // Save created object to database
        return await Auth.hitEndpoint("POST", "SCHEDULES", "", pdf);
    }

    const uploadToAws = async (filename, file) =>{
        const s3 = new AWS.S3(config.S3_INFO);

        const params = {
            Bucket: 'svcts-schedules-pdf',
            Key: filename, // File name you want to save as in S3
            Body: file,
            ACL: "public-read",
            ContentType: "application/pdf"
        };

        return await new Promise((resolve) => {
            s3.upload(params, async function (error, data) {
                return resolve(data.Location);
            });
        });
    }

    const handleDownloadPDF = () => {
        const s3 = new AWS.S3(config.S3_INFO);

        for (let i = 0; i < documents.length; i++) {
            if (documents[i].isChecked) {
                const url = documents[i].aws_url
                const params = {
                    Bucket: "svcts-schedules-pdf",
                    Key: documents[i].name,
                }

                // Retrieve the PDF file from S3
                s3.getObject(params, (err, data) => {
                    if (err) {
                        console.log(err, err.stack);
                    } else {
                        let blob = new Blob([data.Body], {
                            type: 'application/pdf;charset=utf-8;',
                        });
                        FileSaver.saveAs(blob,documents[i].name);
                    }
                });
            }
        }
    }

    const deletePDFs = async (e) => {
        if(e.target.value === 'cancel'){
            toggleOffWarnings();
            return;
        }
        // Delete marked documents
        let count = 0;
        for(let i = 0; i < documents.length; i++){
            if(documents[i].isChecked){
                const response = await Auth.hitEndpoint("DELETE", "SCHEDULES", "", documents[i]);
                if(response.status === 'SUCCESS'){
                    count++;
                }
            }
        }
        // Check if all marked documents were deleted, if so remove them from state
        if(count === documents.filter(doc => doc.isChecked).length){
            const tempDocs = documents.filter(doc => !doc.isChecked);
            setDocuments([...tempDocs]);
        }
        else{
            console.log('Failed to delete all marked documents');
        }

        await loadDocuments()
        toggleOffWarnings();
    }

    const handleDeletePDF = (e) => {
        // Check if any files have been selected
        const pdfs = documents.filter(doc => doc.isChecked);
        if(pdfs.length === 0){
            setErrorNoFilesSelected(true);
            return;
        }

        // delete files
        setSubmitDeletePDFs(true);
    }

    const toggleDocSelect = (index) => {
        const tempDocs = documents;
        tempDocs[index].isChecked = tempDocs[index].isChecked ? false : true;
        setDocuments([...tempDocs]);
    }

    const toggleOffWarnings = () => {
        setErrorNoFilesSelected(false);
        setSubmitDeletePDFs(false);
        setErrorWrongFileType(false);
        setErrorFileSizeToBig(false);
        setErrorFileNameExists(false);
    }

    const handleChange = async (files) => {
        for(let f=0;f<files.length; f++){
            let existing = documents.filter(d => d.name === files[f].name)
            if(existing.length === 0) {
                let s3response = await uploadToAws(files[f].name, files[f])
                await loadPDFToDatabase(files[f].name, s3response);
            }
        }
        await loadDocuments()
    }

    const dropError = (e) =>{
        alert(e + " No files were uploaded. Please select ONLY PDF files")
    }
    
    return (
        <DailyScheduleContainer>
            <MainHeader/>
            <div className='messaging'>
                {message && message.message.length > 0 &&
                <div className='main-header-message reg-message'>
                    <div className='scrolling-message'>{message.message}</div>
                </div>}
                {devMessage && devMessage.message.length > 0 &&
                <div className='main-header-message dev-message'>
                    <div className='scrolling-message'>{devMessage.message}</div>
                </div>}
            </div>
            <div className='daily-schedules'>
                <div className='daily-schedules-header'>Daily Schedule</div>
                <div className='daily-schedules-container'>
                    {documents.length === 0 ? 
                    <div id='docs-message' className='no-docs-message'>No Documents In Database</div> : 
                    documents.map((doc, index) => {
                        const trimmedName = doc.name.substring(0, doc.name.indexOf('.pdf'));
                        return (
                            <div key={index} className='name-list'>
                                <div className='check-box'>
                                    <input className="check-box-input" type="checkbox" name='schedule' checked={doc.isChecked}
                                        onChange={(e) => toggleDocSelect(index)}/>
                                    <label className="check-box-label" htmlFor='schedule'>{trimmedName}</label>
                                </div>
                            </div>
                        )
                    })}
                    <div className='daily-schedule-buttons'>
                        <div className='submit'>
                            <div className='button' onClick={handleDownloadPDF}>Download</div>
                        </div>
                        {canDeleteFiles && 
                        <div className='submit'>
                            <div className='button' onClick={handleDeletePDF}>Delete</div>
                        </div>}
                    </div>
                </div>
            </div>
            {canLoadFiles &&
            <div className='drop-area'>
                <div className='drop-area-header'>Drag N Drop PDF</div>
                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={true} label="Upload or Drop Files Here. PDF Format Only" onTypeError={dropError} classes='drop-area-area' />
            </div>}
            {errorWrongFileType && <Popup color={PopupWarningColor} message={'Wrong file type. All files must be .pdf format'} handlePopup={toggleOffWarnings}/>}
            {errorFileSizeToBig && <Popup color={PopupWarningColor} message={'File size to large. Max file size is 10MB'} handlePopup={toggleOffWarnings}/>}
            {errorFileNameExists && <Popup color={PopupWarningColor} message={'Please check file names. File may already exists in database'} handlePopup={toggleOffWarnings}/>}
            {errorNoFilesSelected && <Popup color={PopupWarningColor} message={'No files have been selected'} handlePopup={toggleOffWarnings}/>}
            {submitDeletePDFs && <PopupOk color={PopupOkWarningColor} message={`Deleting the following document(s):\n ${documents.filter(doc => doc.isChecked).map(doc => doc.name.substring(0, doc.name.indexOf(".pdf"))).join('\n')} \nDo you wish to continue?`} handlePopup={deletePDFs}/>}
        </DailyScheduleContainer>
    );
}

export default DailySchedule;
