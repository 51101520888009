import {useState} from 'react';
import { useNavigate } from 'react-router';

import Can from "../authentication/Can.js";

import './styles/navbar.css'
import Auth from "../authentication/Auth.js";
import {
    faBuildingUser, faToolbox, faUsers, faHouse,
    faCalendar, faGears, faFile, faTruckPickup
} from "@fortawesome/free-solid-svg-icons";

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import MainNavLink from "./MainNavLink";
import SubNavLink from "./SubNavLink";
import SingleNavLink from "./SingleNavLink";

export const Navbar = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [selectables, setSelectables] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [didLoadData, setDidLoadData] = useState(false);

    const loadData = async () => {

        const tempPermissions = await Auth.getPermissions();
        setPermissions(tempPermissions)
        setDidLoadData(true);
    }

    if (!didLoadData) {
        loadData();
    }


    const toolbarStyle = {
        minHeight: '1px'
    }

    const appbarStyle = {
        gridColumn: '1/6',
        background: 'rgb(58, 64, 67)'
       // background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(4,115,58,1) 100%)'
    }

    return (
        <AppBar position="static" color="primary" style={appbarStyle} className={"appbar"}>
            <Toolbar className={"toolbar"}>
                {Can.isAuthorizedRead(permissions, 'builders') && <SingleNavLink route="/builders-page" label="Builders" icon={faBuildingUser}/>}
                {Can.isAuthorizedRead(permissions, 'jobs') && <SingleNavLink route="/jobs-page" label="Jobs" icon={faToolbox}/>}

                {Can.isAuthorizedRead(permissions, 'lots') &&
                    <MainNavLink label={"Lots"} route={"#"} icon={faHouse} className="no-active">
                        {Can.isAuthorizedRead(permissions, 'lots') && <SubNavLink route={"/lots-page"} label={"Lots"}/>}
                        {/* <SubNavLink route={"/lots/view"} label={"View Lots"}/> */}
                        {/* <SubNavLink route={"/lots/create"} label={"Create Lots"}/> */}
                        {/* <SubNavLink route={"/lots/edit"} label={"Edit Lots"}/> */}
                        {/* <SubNavLink route={"/lots/load"} label={"Load Lots"}/> */}
                        {/* {Can.isAuthorizedRead(permissions, 'startsLots') && <SubNavLink route={"/lots/starts"} label={"Starts"}/>} */}
                        {Can.isAuthorizedRead(permissions, 'evenFlow') && <SubNavLink route={"/lots/evenflow"} label={"Evenflow"}/>}
                </MainNavLink>}

                {(Can.isAuthorizedRead(permissions, 'schedule') || Can.isAuthorizedRead(permissions, 'schedule') || Can.isAuthorizedRead(permissions, 'pourLog')) &&
                    <MainNavLink label={"Schedule"} route={"#"} icon={faCalendar} className="no-active">
                        <SubNavLink route={"/schedule"} label={"Schedule Work"}/>
                        {Can.isAuthorizedRead(permissions, 'dailySchedule') &&<SubNavLink route={"/daily-schedule"} label={"Daily Schedule"}/>}
                        {Can.isAuthorizedRead(permissions, 'pourLog') &&<SubNavLink route={"/pour-log"} label={"Pour Log"}/>}
                    </MainNavLink>}

                {(Can.isAuthorizedRead(permissions, 'crews') || Can.isAuthorizedRead(permissions, 'employees') || Can.isAuthorizedRead(permissions, 'prospectiveEmployees')) &&
                    <MainNavLink label={"Employees"} route={"#"} icon={faUsers}>
                        {Can.isAuthorizedRead(permissions, 'crews') && <SubNavLink label={"Crews Page"} route={"/crews-page"}/>}
                        {Can.isAuthorizedRead(permissions, 'employees') && <SubNavLink label={"Employees Page"} route={"/employees-page"}/>}
                        {Can.isAuthorizedRead(permissions, 'employeeTimeOff') && <SubNavLink label={"Time Off Requests"} route={"/employee/time-off"}/>}
                        {Can.isAuthorizedRead(permissions, 'prospectiveEmployees') && <SubNavLink label={"Prospective Employees"} route={"/prospective-employees"}/>}
                </MainNavLink>}

                {Can.isAuthorizedRead(permissions, 'fieldForms') &&
                    <MainNavLink label={"Field"} route={"#"} icon={faTruckPickup}>
                        <SubNavLink label={"Field Forms"} route={"/field-forms"}/>
                        {Can.isAuthorizedRead(permissions, 'fieldForms') && <SubNavLink label={"EWAs"} route={"/ewa"}/>}
                        {Can.isAuthorizedRead(permissions, 'ewa') && <SubNavLink label={"Task Adjustments"} route={"/task-adjustment"}/>}
                        {Can.isAuthorizedRead(permissions, 'gateInformation') && <SubNavLink label={"Gates"} route={"/gates"}/>}
                        {Can.isAuthorizedRead(permissions, 'scheduleRequest') && <SubNavLink label={"SVC Schedule Request"} route={"/svc-schedule-request"}/>}
                        {Can.isAuthorizedRead(permissions, 'scheduleUpdates') && <SubNavLink label={"Schedule Updates"} route={"/schedule-updates"}/>}
                        {Can.isAuthorizedRead(permissions, 'employeeTimeOff') && <SubNavLink label={"Time Off Requests"} route={"/employee/time-off"}/>}
                        {/* {Can.isAuthorizedRead(permissions, 'scheduleRequest') && <SubNavLink label={"Schedule Request"} route={"/schedule-request"}/>} */}
                        {/*<SubNavLink label={"Hours Approval"} route={"/approval/hours"}/>*/}
                        {/*<SubNavLink label={"PO Admin"} route={"/po/admin"}/>*/}
                </MainNavLink>}

                {Can.isAuthorizedRead(permissions, 'reports') &&
                    <SingleNavLink label={"Reports"} route={"/reports"} icon={faFile}/>}

                {Can.isAdmin(permissions) && <SingleNavLink route={"/admin-page"} icon={faGears} label={"Admin"}/>}
            </Toolbar>
        </AppBar>
    );
}

// export default Navbar;
