import {useMemo, useState, useEffect} from "react";
import Auth from "../authentication/Auth";
import dayjs from "dayjs";
import {MaterialReactTable} from "material-react-table";
import {createTheme} from "@mui/material/styles";
import {darken, IconButton, lighten, Tooltip} from "@mui/material";
import Schedules from "../../classes/Schedules";
import { EditLocation } from "@mui/icons-material";

const ScheduleRequestTable = ({selectedSuper, date, schedule, userId, setIsLoading, setLoadingWhat, setSaveStatus}) => {

    const [tableData, setTableData] = useState([]);

    const gridColumns = useMemo(
        () => [
            {
                accessorFn: (row) => row.notMoveableReason !== undefined  ? row.notMoveableReason : '',
                id: 'availability',
                header: 'Availability',
                size: 20,
                enableEditing: false,
            },
            {
                accessorFn: (row) => row.task.code !== null && row.task.code !== "" ? `${row.task.code} ${row.task.name}` : row.task.name,
                id: 'task',
                header: 'Task',
                size: 20,
                enableEditing: false,
            },
            {
                accessorFn: (row) => {
                    let crewNumber = '-1'
                    try {
                        crewNumber = row.crew !== null && row.crew.crew !== null ? row.crew.crew.number : '-1';
                    }
                    catch(e){
                        console.log('error', e, row)
                    }
                    let name = '';
                    if (row.crew !== null && row.crew.employees.length > 0 && row.crew.employees[0].employee !== null) {
                        let emp = row.crew.employees[0].employee
                        name = emp.alias !== undefined && emp.alias !== null ? `${emp.alias} ${emp.first_name} ${emp.last_name}` : `${emp.first_name} ${emp.last_name}`
                    } else {
                        name = 'Not Assigned'
                    }
                    return (`${crewNumber} - ${name}`)
                },
                id: 'crew',
                header: 'Crew',
                size: 20,
                enableEditing: false
            },
            {
                accessorFn: (row) => row.lot.job.project_name,
                header: 'Project',
                enableEditing: false,
                size: 20,
            },
            {
                accessorFn: (row) => row.lot.job.number,
                header: 'Job #',
                enableEditing: false,
                size: 20,
            },
            {
                accessorFn: (row) => row.lot.phase === "" ? row.lot.number : `${row.lot.phase} - ${row.lot.number}`,
                header: 'Lot',
                enableEditing: false,
                size: 20,
            },
            {
                accessorFn: (row) => `${row.lot.address}`,
                header: 'Address',
                enableEditing: false,
                enableClickToCopy: true,
                size: 55,
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
                enableEditing: false,
                size: 155,
            }
        ],
        [],
    );

    useEffect(() => {
        const colorScheduleRequests = (sr) => {
            const colors = ['rgb(137, 207, 240)', 'rgb(125, 249, 255)'];
            let currentColor = 0;
            sr.forEach((r, index, arr) => {
                if(index > 0){
                    if(parseInt(arr[index - 1].crew.crew_id) !== parseInt(r.crew.crew_id)){
                        currentColor++;
                        if(currentColor > 1){
                            currentColor = 0;
                        }
                    }
                }
                r.bgColor = colors[currentColor];
    
                return r;
            })
        }

        const load = async () => {

            const filteredSchedule = schedule.filter(x => {
                return x.requested
                && (selectedSuper !== null ? x.requested_by === selectedSuper.id : true) 
                && (x.deleted_at === undefined || x.deleted_at === null);
            });

            console.log(schedule);

            colorScheduleRequests(filteredSchedule);
            setTableData([...filteredSchedule]);
        }

        load();

    }, [schedule, selectedSuper]);

    // Needed for showing crew schedule together per task
    let colorGroupCounter = 1

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'
    const baseCompleteColor = theme.palette.mode === 'dark' ? 'rgba(143, 248, 144, 1)' : 'rgba(143, 248, 144, 1)'

    return (
        <div style={{overflow: "auto", width: "100%"}}>
            <MaterialReactTable
                id="schedule-table"
                columns={gridColumns}
                data={schedule}
                enableRowSelection={true}
                enableSelectAll={false}
                enableBatchRowSelection={true}
                enableStickyHeader
                enableSorting={false}
                displayColumnDefOptions={{
                    'mrt-row-expand': {
                        header: '', //change header text
                    }}}
                muiTableProps={{
                    sx: () => ({
                        borderCollapse: 'collapse'
                    })
                }}
                muiSelectCheckboxProps = {({table,  row }) => ({
                    disabled: row.original.notMovable,
                    // onClick: e => {
                    //     if (e.shiftKey) {
                    //         //Will come back to this later
                    //         let rows = table.getSelectedRowModel().rows
                    //         let lastSelectedIndex = rows[rows.length - 1].index
                    //         let index = row.index
                    //         let start = index > lastSelectedIndex ? lastSelectedIndex : index
                    //         let end = index > lastSelectedIndex ? index : lastSelectedIndex
                    //         for (let i = start; i < end; i++) {
                    //             rows.push(table.getRowModel().rows[i])
                    //         }
                    //     }
                    // }
                })}
                muiTableBodyCellProps={({cell, row, table}) => {
                    let columnId = cell.column.id
                    let rows = table.getRowModel().rows
                    let index = rows.indexOf(row)
                    let prev = rows[index - 1]
                    let next = rows[index + 1]
                    let prevTask = prev !== undefined ? prev.original.task.id : null
                    let curTask = row.original.task.id
                    let prevCrew = prev !== undefined && prev.original.crew !== undefined && prev.original.crew !== null ? prev.original.crew.crew_id : -1
                    let curCrew = row.original.crew !== undefined && row.original.crew !== null ? row.original.crew.crew_id : -1
                    let nextCrew = next !== undefined && next.original.crew !== undefined && next.original.crew !== null ? next.original.crew.crew_id : -1

                    return ({
                    sx: {
                        color: () => {
                            let color = ''
                            if(columnId === 'task' || columnId === 'crew') {
                                if (columnId === 'task' && curTask === prevTask) {
                                    color = 'rgba(0, 0, 0, 0)'
                                }
                                if (columnId === 'crew') {
                                    if (curCrew === prevCrew && curTask === prevTask) {
                                        color = 'rgba(0, 0, 0, 0)'
                                    } else if (row.original.approved === 0) {
                                        color = '#000'
                                    } else if (row.original.approved === 1) {
                                        color = '#fff'
                                    }
                                }
                            }
                            return color
                        },
                        borderBottom: () => {
                            let border = ''
                            if (nextCrew !== curCrew) {
                                border = 'solid 1pt black'
                            }
                            return border
                        },
                        borderRight: () => {
                            if(columnId === 'notes'){
                                return 'solid 2pt black'
                            }
                        },
                        borderLeft: () => {
                            if(columnId === 'task'){
                                return 'solid 1pt black'
                            }
                        },
                        fontWeight: () => {
                            let weight = ''
                            if(columnId === 'task' || columnId === 'crew') {
                                weight = 'bold'
                            }
                            return weight
                        }

                    }
                })}}
                muiTableBodyRowProps={({row, table}) => {
                    let rows = table.getRowModel().rows
                    let index = rows.indexOf(row)
                    let prev = rows[index - 1]
                    let prevTask = prev !== undefined ? prev.original.task.id : null
                    let curTask = row.original.task.id
                    let prevCrew = prev !== undefined && prev.original.crew !== undefined && prev.original.crew !== null ? prev.original.crew.crew_id : -1
                    let curCrew = row.original.crew !== undefined && row.original.crew !== null ? row.original.crew.crew_id : -1;
                    if(curCrew !== prevCrew){
                        colorGroupCounter +=1
                    }
                    return({
                    sx: {
                        backgroundColor: () => {
                            let background = ''
                                if (colorGroupCounter % 2) {
                                    background = row.original.complete ? darken(baseCompleteColor, 0.1) : darken(baseBackgroundColor, 0.1)
                                } else {
                                    background = row.original.complete ? lighten(baseCompleteColor, 0.3) : lighten(baseBackgroundColor, 0.3)
                                }
                            return background
                        },
                        borderTop:  () => {
                            let border = ''
                            if (curTask !== prevTask) {
                                border = 'solid 1pt'
                            }
                            return border
                        },
                    }
                })}}
                enableColumnPinning={true}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {
                        left: ['mrt-row-select', 'availablility']
                    },
                    density: 'compact',
                }}
                positionToolbarAlertBanner="top"
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <div style={{display: 'flex', gap: '0.5rem'}}>
                            <Tooltip title="Move to Schedule">
                                <span>
                                    <IconButton
                                        color="success"
                                        onClick={ async () => {
                                            setSaveStatus("Moving Request To Schedule");
                                            let rows = table.getSelectedRowModel().rows
                                            if(rows.length === 0){
                                                return;
                                            }

                                            let tempTableData = [...tableData];
                                            //Lets do the call to the db once
                                            let updateArray = []
                                            for(let i = 0; i < rows.length; i++){
                                                updateArray.push({
                                                    id: rows[i].original.id,
                                                    requested: false,
                                                    scheduled: true,
                                                    scheduled_by: userId,
                                                    original_id: rows[i].original.original_id,
                                                });
                                            }
                                            await Schedules.updateRequestToSchedule(updateArray)
                                            //once the update has been made we will adjust the data on the front end.
                                            //TODO update this code to only fire if the api update call was successful
                                            for(let i = 0; i < rows.length; i++){
                                                const row = schedule.find(s => parseInt(s.id) === parseInt(rows[i].original.id));
                                                if(row !== undefined){
                                                    row.requested = false;
                                                    row.scheduled = true
                                                    row.scheduled_by = userId
                                                }
                                                tempTableData = tempTableData.filter(td => parseInt(td.id) !== parseInt(rows[i].original.id,))
                                            }
                                            setTableData([...tempTableData]);
                                            table.resetRowSelection();
                                            setSaveStatus("");
                                        }}
                                        variant="contained"
                                    >
                                        <EditLocation />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                    );
                }}
            />
        </div>
    )
}

export default ScheduleRequestTable;
