
import styled from 'styled-components';

const checkbox_Margin = "5px 0 5px 0";

const CheckboxWithLabelContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : checkbox_Margin};

    .check-box-label {
        display: grid;
        grid-column: ${props => props.labelStart}/${props => props.labelEnd};
        justify-self: end;
        align-self: center;
        padding-right: 5px;
    }

    .check-box-input {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        transform: scale(1.2);
        justify-self: start;
    }
`
// TEMPLATE
// <CheckboxWithLabel className="Class name of entire box" 
//                    name="Name of input" 
//                    label="Label to display" 
//                    checked="Is the check box checked true/false"
//                    handleCheckbox="Function that controls the input" 
//                    disabled="Is the checkbox disabled true/false"
//                    margins="Top right bottom left margins"
//                    columns="Number of columns in label/checkbox row"
//                    labelStart="Start of label, right aligned"
//                    labelEnd="End of label, right aligned"
//                    inputStart="Start of checkbox, left aligned" 
//                    inputEnd="End of checkbox, left aligned"/>
// END

export const CheckboxWithLabel = (props) => {
    const disabled = props.disabled ? props.disabled : false;
    return (
        <CheckboxWithLabelContainer className={props.className} margin={props.margin} columns={props.columns} labelStart={props.labelStart} labelEnd={props.labelEnd} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <label className="check-box-label" htmlFor={props.name}>{props.label}</label>
            <div className="check-box-input">
                <input  type="checkbox" name={props.name} checked={props.checked} onChange={props.handleCheckbox} disabled={disabled}/>
            </div>
        </CheckboxWithLabelContainer>
    );
}

const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : checkbox_Margin};

    .check-box-input {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        transform: scale(1.2);
        justify-self: start;
    }
`
// TEMPLATE
// <Checkbox className="Class name of entire box" 
//           name="Name of input" 
//           checked="Is the check box checked true/false"
//           handleCheckbox="Function that controls the input" 
//           disabled="Is the checkbox disabled true/false"
//           margins="Top right bottom left margins"
//           columns="Number of columns in label/checkbox row"
//           inputStart="Start of checkbox, left aligned" 
//           inputEnd="End of checkbox, left aligned"/>
// END

export const Checkbox = (props) => {
    const disabled = props.disabled ? props.disabled : false;
    return (
        <CheckboxContainer className={props.className} margin={props.margin} columns={props.columns} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <div className="check-box-input">
                <input  type="checkbox" name={props.name} checked={props.checked} onChange={props.handleCheckbox} disabled={disabled}/>
            </div>
        </CheckboxContainer>
    );
}
