import styled from 'styled-components';

const JobsPageContainer = styled.div`
    display: grid;
    margin: 10px 0 75px 10px;

    .jobs-top-boxes{
        display: grid;
        grid-template-columns: 500px 400px;

        .jobs-filter-box{
            display: grid;
            background: rgb(210, 200, 190);
            padding-bottom: 10px;
            margin-right: 10px;
            border-bottom: 3px solid white;

            .jobs-filter-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 30px;
                padding: 3px 0;
                margin-bottom: 10px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }
        }

        .jobs-sort-box{
            display: grid;
            background: rgb(210, 200, 190);
            padding-bottom: 10px;
            border-bottom: 3px solid white;

            .jobs-sort-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 30px;
                padding: 3px 0;
                margin-bottom: 10px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }
        }
    }

    .page-header{
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        width: 1665px;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;

        .add-new-job{
            grid-column: 1/3;
        }

        .export-to-xlsx{
            grid-column: 3/5;
        }

        .header-title{
            grid-column: 9/13;
            justify-self: center;
            align-self: center;
            display: grid;
            font-size: 18px;
            color: white;
        }
    }

    .new-job-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-bottom: 3px solid white;

        .new-job-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 3px solid white;
        }

        .optional-general-super-labels{
            display: grid;
            grid-template-columns: repeat(40, 1fr);

            .optional-label{
                display: grid;
                justify-content: center;
                grid-column: 11/14;
            }

            .general-label{
                display: grid;
                justify-content: center;
                grid-column: 15/23;
            }

            .super-label{
                display: grid;
                justify-content: center;
                grid-column: 27/35;
            }
        }

        .scope-general-super-selection-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);

            .scope-checkbox{
                display: grid;
                grid-column: 1/4;
            }

            .general-dropdown{
                display: grid;
                grid-column: 4/7;
            }

            .super-dropdown{
                display: grid;
                grid-column: 7/10;
            }
        }

        .plumbing-gas-box,
        .pavers-selection-box{
            display: grid;
            grid-template-columns: repeat(80, 1fr);

            .scope-checkbox{
                grid-column: 1/23;
            }

            .opt-checkbox{
                grid-column: 23/25;
            }

            .general-dropdown{
                display: grid;
                grid-column: 25/49;
            }

            .super-dropdown{
                display: grid;
                grid-column: 49/73;
            }
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .table-header{
        display: grid;
        width: 1665px;
        grid-template-columns: 300px 300px 120px 70px 200px 75px 75px 75px 75px 75px 75px 75px 75px 75px;
        background: rgb(210, 200, 190);

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 140px;
        z-index: 5;

        .grid-table-header-data{
            display: grid;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px 0;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        .grid-table-header-data.index0{
            border-left: 2px solid black;
        }

        .grid-table-header-data.index13{
            border-right: 2px solid black;
        }
    }

    .grid-table-row{
        display: grid;
        width: 1665px;
        grid-template-columns: 300px 300px 120px 70px 200px 75px 75px 75px 75px 75px 75px 75px 75px 75px;

        .grid-table-row-data{
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 3px 0;
            border: 1px solid black;

            .display-members-dropdown{
                width: 330px;
            }
        }

        .grid-table-row-data.first{ border-top: 2px solid black; }
        .grid-table-row-data.last{ border-bottom: 2px solid black; }
        .grid-table-row-data.far-left{ border-left: 2px solid black; }
        .grid-table-row-data.far-right{ border-right: 2px solid black; }

        .grid-table-row-data.left-align{
            display: grid;
            justify-content: start;
            padding-left: 5px;
        }
    
        .grid-table-row-data.bold{
            font-weight: bold;
        }

        :nth-child(even) { 
            background: rgb(230, 230, 220); 

            .drop-down-members{
                background: rgb(230, 230, 220);
            }
        }
    }

    .grid-table-row.in-active{
        background: rgb(180, 180, 180);
    }

    .edit-job-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-top: 3px solid white;
        border-bottom: 3px solid white;

        .edit-job-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 3px solid white;
        }

        .optional-general-super-labels{
            display: grid;
            grid-template-columns: repeat(40, 1fr);

            .optional-label{
                display: grid;
                justify-content: center;
                grid-column: 11/14;
            }

            .general-label{
                display: grid;
                justify-content: center;
                grid-column: 15/23;
            }

            .super-label{
                display: grid;
                justify-content: center;
                grid-column: 27/35;
            }
        }

        .scope-general-super-selection-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);

            .scope-checkbox{
                display: grid;
                grid-column: 1/4;
            }

            .general-dropdown{
                display: grid;
                grid-column: 4/7;
            }

            .super-dropdown{
                display: grid;
                grid-column: 7/10;
            }
        }

        .plumbing-gas-box,
        .pavers-selection-box{
            display: grid;
            grid-template-columns: repeat(80, 1fr);

            .scope-checkbox{
                grid-column: 1/23;
            }

            .opt-checkbox{
                grid-column: 23/25;
            }

            .general-dropdown{
                display: grid;
                grid-column: 25/49;
            }

            .super-dropdown{
                display: grid;
                grid-column: 49/73;
            }
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }

        .three-button-box{
            display: grid;
            justify-self: center;
            width: 450px;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
    th{
        background: rgb(210, 200, 190);
        align-items: center;
        text-align: center;
        padding: 3px 0;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black; 
        font-weight: bold;
    }
    th button{
       width: 100%
    }
    tr{
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
    }
    tr:nth-child(even) {
            background: rgb(230, 230, 220);
            font-weight: bold;
    }
    td{
         border-left: 1px solid black;
         border-right: 1px solid black;
         text-align: center;
         font-weight: bold;
    }
    
    .add-job-icon{
        align: left
    }
`;

export default JobsPageContainer;