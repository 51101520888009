import {useState} from 'react';
import moment from "moment/moment";

import Auth from '../authentication/Auth.js';

import Jobs from '../../classes/Jobs.js';
import MainHeader from '../utilities/MainHeader.js';
import JobsPageContainer from './styles/JobsPageContainer.js';
import { Button } from '../utilities/Button.js';
import Utilities from '../../classes/Utilities.js';
import { DropDownWithLabel, DropDown } from '../utilities/DropDown.js';
import { CheckboxWithLabel, Checkbox } from '../utilities/Checkbox.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import Popup, { PopupSuccessColor, PopupFailureColor } from '../utilities/Popup.js';

const JobsPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        scope: "",
        builder_id: "",
    });

    const [sortOptions, setSortOptions]= useState({
        sortBy: 'number',
        order: 'ascending'
    });

    const [usersForGeneralsDropDowns, setUsersForGeneralsDropDowns] = useState([]);
    const [usersForSupersDropDowns, setUsersForSupersDropDowns] = useState([]);

    const [buildersForDropDowns, setBuildersForDropDowns] = useState([]);

    const [jobTypes, setJobTypes] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [newJob, setNewJob] = useState(null);
    const [jobToEdit, setJobToEdit] = useState(null);

    const [errorNoBuilder, setErrorNoBuilder] = useState(false);
    const [errorNoJobNumber, setErrorNoJobNumber] = useState(false);
    const [errorNoJobType, setErrorNoJobType] = useState(false);
    const [errorNoProjectName, setErrorNoProjectName] = useState(false);
    const [errorIncorrectJobNumberFormat, setErrorIncorrectJobNumberFormat] = useState(false);
    const [errorDuplicateJobNumber, setErrorDuplicateJobNumber] = useState(false);

    const [newJobSuccess, setNewJobSuccess] = useState(false);
    const [editJobSuccess, setEditJobSuccess] = useState(false);

    let canCreateJobs = props.isAuthorized('jobs', 'WRITE');
    let canEditJobs = props.isAuthorized('jobs', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);

        const tempUsers = await Auth.hitEndpointNew("GET", "users");
        const tempGenerals = tempUsers.filter(user => user.position.name === "general").map(user => ({...user, display: `${user.field.name} - ${user.firstname} ${user.lastname}`})).sort((u1, u2) => u1.field.name < u2.field.name ? -1 : 1);
        const tempSupers = tempUsers.filter(user => user.position.name === "super").map(user => ({...user, display: `${user.field.name} - ${user.firstname} ${user.lastname}`})).sort((u1, u2) => u1.field.name < u2.field.name ? -1 : 1);
        setUsersForGeneralsDropDowns(tempGenerals);
        setUsersForSupersDropDowns(tempSupers);

        const tempBuilders = await Auth.hitEndpointNew("GET", "builders");
        setBuildersForDropDowns(tempBuilders.map(builder => ({...builder, display: builder.name})).sort((b1, b2) => b1.name < b2.name ? -1 : 1));

        setJobs(Jobs.sortJobsNew(sortOptions.sortBy, sortOptions.order, await Auth.hitEndpointNew("GET", "jobs-new")));
        setJobTypes(await Auth.hitEndpointNew("GET", "job/types"))
    }

    if(!didLoadData){
        loadData();
    }

    const applyFilters = async () => {
        let whereStr = '';
        if(filterOptions.scope !== ""){
            if(filterOptions.scope === "concrete"){ whereStr += `job_attribute.attribute_id=1 and `; }
            if(filterOptions.scope === "plumbing"){ whereStr += `(job_attribute.attribute_id=2 or job_attribute.attribute_id=3 or job_attribute.attribute_id=4) and `; }
            if(filterOptions.scope === "rfg/trash"){ whereStr += `(job_attribute.attribute_id=7 or job_attribute.attribute_id=8) and `; }
            if(filterOptions.scope === "masonry/gates"){ whereStr += `(job_attribute.attribute_id=9 or job_attribute.attribute_id=12) and `; }
            if(filterOptions.scope === "pavers"){ whereStr += `(job_attribute.attribute_id=10 or job_attribute.attribute_id=11) and `; }
            if(filterOptions.scope === "framing"){ whereStr += `job_attribute.attribute_id=13 and `; }
        }
        if(filterOptions.builder_id !== ""){ whereStr += `builder_id=${filterOptions.builder_id} and `; }

        // Trim 'and' off end of whereStr
        if(whereStr !== ''){
            whereStr = whereStr.slice(0, whereStr.length - 5);
        }

        setJobs(Jobs.sortJobsNew(sortOptions.sortBy, sortOptions.order, await Auth.hitEndpointNew("GET", "jobs-new", `queryStr=${whereStr}`)));
    }

    const hasAttribute = (job, attribute_id) => {
        return job.attributes.find(att => parseInt(att.attribute_id) === attribute_id) !== undefined;
    }

    const handleAttributeNewJob = (attribute_id) => {
        const tempNewJob = {...newJob};
        const index = tempNewJob.attributes.findIndex(attribute => attribute.attribute_id === attribute_id);
        if(index >= 0){
            tempNewJob.attributes.splice(index, 1);
        }
        else{
            tempNewJob.attributes.push({attribute_id: attribute_id});
        }
        setNewJob(tempNewJob);
    }

    const handleAssignmentNewJob = (userId, assignment_id) => {
        const tempNewJob = {...newJob};
        if(userId === ''){
            const index = tempNewJob.assignments.findIndex(assignment => assignment.assignment_id === assignment_id);
            if(index >= 0){
                tempNewJob.assignments.splice(index, 1);
            }
        }
        else{
            tempNewJob.assignments.push({assignment_id: assignment_id, user_id: userId});
        }
        setNewJob(tempNewJob);
    }

    const handleJobErrors = (errorCode) => {
        if(errorCode === "ERROR_NO_BUILDER"){ setErrorNoBuilder(true); return;}
        if(errorCode === "ERROR_NO_JOB_NUMBER"){ setErrorNoJobNumber(true); return;}
        if(errorCode === "ERROR_NO_JOB_TYPE"){ setErrorNoJobType(true); return;}
        if(errorCode === "ERROR_NO_PROJECT_NAME"){ setErrorNoProjectName(true); return;}
        if(errorCode === "ERROR_INCORRECT_JOB_NUMBER_FORMAT"){ setErrorIncorrectJobNumberFormat(true); return;}
        if(errorCode === "ERROR_DUPLICATE_JOB_NUMBER"){ setErrorDuplicateJobNumber(true); return;}
    }

    const saveNewJob = async () => {
        const result = await Jobs.checkJobForErrorsNew(newJob);
        if(result !== "SUCCESS"){
            handleJobErrors(result);
            return;
        }

        newJob.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
        await Auth.hitEndpointNew("POST", "job-new", "", newJob);
        setNewJobSuccess(true);
        setNewJob(null);
        await applyFilters();
    }

    const handleAttributeJobToEdit = (attribute_id) => {
        const tempJobToEdit = {...jobToEdit};
        const index = tempJobToEdit.attributes.findIndex(attribute => parseInt(attribute.attribute_id) === parseInt(attribute_id));
        if(index >= 0){
            tempJobToEdit.attributes.splice(index, 1);
        }
        else{
            tempJobToEdit.attributes.push({job_id: jobToEdit.id, attribute_id: attribute_id});
        }
        setJobToEdit(tempJobToEdit);
    }

    const handleAssignmentJobToEdit = (userId, assignment_id) => {
        const tempJobToEdit = {...jobToEdit};
        if(userId === ''){
            const index = tempJobToEdit.assignments.findIndex(assignment => parseInt(assignment.assignment_id) === parseInt(assignment_id));
            if(index >= 0){
                tempJobToEdit.assignments.splice(index, 1);
            }
        }
        else{
            tempJobToEdit.assignments.push({job_id: jobToEdit.id, assignment_id: assignment_id, user_id: userId});
        }
        setJobToEdit(tempJobToEdit);
    }

    const resetErrorPopups = () => {
        setErrorNoBuilder(false);
        setErrorNoJobNumber(false);
        setErrorNoJobType(false);
        setErrorNoProjectName(false);
        setErrorIncorrectJobNumberFormat(false);
        setErrorDuplicateJobNumber(false);
    }

    const resetSuccessPopups = () => {
        setNewJobSuccess(false);
        setEditJobSuccess(false);
    }

    const updateJob = async () => {
        const result = await Jobs.checkJobForErrorsNew(jobToEdit);
        if(result !== "SUCCESS"){
            handleJobErrors(result);
            return;
        }

        jobToEdit.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
        for(let i = 0; i < jobToEdit.assignments.length; i++){
            delete jobToEdit.assignments[i].user;
        }
        delete jobToEdit.builder;
        delete jobToEdit.modified;

        await Auth.hitEndpointNew("PATCH", "job-new", "", jobToEdit);
        setEditJobSuccess(true);
        setJobToEdit(null);
        await applyFilters();
    }

    const cancelUpdateJob = async () => {
        setJobToEdit(null);
        await applyFilters();
    }

    const exportJobsToXLSX = async () => {
        const exportData = [];

        document.getElementById('export-to-xlsx-id').innerHTML = 'Exporting...';

        for(let i = 0; i < jobs.length; i++){
            const numberOfLots = await Auth.hitEndpoint("GET", "STATS", `selector=eight&jobId=${jobs[i].id}`);

            let assigned = jobs[i].assignments.find(a => a.assignment.name === 'concreteGeneral');
            const concreteGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'concreteSuper');
            const concreteSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'plumbingGeneral');
            const plumbingGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'plumbingSuper');
            const plumbingSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'masonryGeneral');
            const masonryGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'masonrySuper');
            const masonrySuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'paverGeneral');
            const paverGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'paverSuper');
            const paverSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'rfgGeneral');
            const rfgGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'rfgSuper');
            const rfgSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'trashGeneral');
            const trashGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'trashSuper');
            const trashSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'gatesGeneral');
            const gatesGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'gatesSuper');
            const gatesSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'framingGeneral');
            const framingGeneral = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";
            assigned = jobs[i].assignments.find(a => a.assignment.name === 'framingSuper');
            const framingSuper = assigned ? `${assigned.user.firstname} ${assigned.user.lastname}` : "";

            exportData.push({
                'Active': jobs[i].active ? 'X' : '',
                'Builder': jobs[i].builder.name,
                'Project Name': jobs[i].project_name,
                'Type': jobs[i].type.description,
                'Job #': jobs[i].number,
                'City': jobs[i].city,
                'Cross Roads': jobs[i].cross_streets,
                'ZipCode': jobs[i].zip_code,
                'Lot Count': numberOfLots.stat,
                'Concrete General': concreteGeneral,
                'Concrete Super': concreteSuper,
                'Plumbing General': plumbingGeneral,
                'Plumbing Super': plumbingSuper,
                'Masonry General': masonryGeneral,
                'Masonry Super': masonrySuper,
                'Paver General': paverGeneral,
                'Paver Super': paverSuper,
                'RFGT General': rfgGeneral,
                'RFGT Super': rfgSuper,
                'Trash General': trashGeneral,
                'Trash Super': trashSuper,
                'Gates General': gatesGeneral,
                'Gates Super': gatesSuper,
                'Framing General': framingGeneral,
                'Framing Super': framingSuper,
                'Concrete': jobs[i].hasConcrete ? 'X' : '',
                'Plumbing Soil': hasAttribute(jobs[i], 2) ? 'X' : '',
                'Plumbing Topout': hasAttribute(jobs[i], 3) ? 'X' : '',
                'Plumbing Trim': hasAttribute(jobs[i], 4) ? 'X' : '',
                'Plumbing Gas Optional': hasAttribute(jobs[i], 6) ? 'X' : '',
                'Plumbing Gas': hasAttribute(jobs[i], 5) ? 'X' : '',
                'RFG': hasAttribute(jobs[i], 7) ? 'X' : '',
                'Trash': hasAttribute(jobs[i], 8) ? 'X' : '',
                'Masonry': hasAttribute(jobs[i], 9) ? 'X' : '',
                'Pavers Optional': hasAttribute(jobs[i], 11) ? 'X' : '',
                'Pavers': hasAttribute(jobs[i], 10) ? 'X' : '',
                'Gates': hasAttribute(jobs[i], 12) ? 'X' : '',
                'Framing': hasAttribute(jobs[i], 13) ? 'X' : '',
            });
        }

        Utilities.exportToXLSXFileFormat(exportData, 'Jobs', './Jobs.xlsx');

        document.getElementById('export-to-xlsx-id').innerHTML = 'Export To XLSX';
    }

    const columns = ['Builder', 'Project Name', 'Category', 'Job #', 'City',
                     'Concrete', 'Plumbing', 'Plumbing-Gas', 'RFG', 'Trash', 'Masonry', 'Pavers', 'Gates', 'Framing'];

    return (
        <JobsPageContainer>
            <MainHeader/>
            <div className='jobs-top-boxes'>
                <div className='jobs-filter-box'>
                    <div className='jobs-filter-header'>Filter</div>
                    <DropDownWithLabel className="scope-filter" id="scope-filter-id" name="scope" label="Scope"  handleDropDown={(e) => setFilterOptions({...filterOptions, scope: e.target.value})}  addBlankOption={true}  selectionList={Jobs.getScopeFilterOptions()} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="builder-filter" id="builder-filter-id" name="builder" label="Builder" handleDropDown={(e) => setFilterOptions({...filterOptions, builder_id: e.target.value})} addBlankOption={true} selectionList={buildersForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <Button className="filter-jobs" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await applyFilters()}})} handleClick={(async (e) => await applyFilters())} buttonText="Apply" width="120"/>
                </div>
                <div className='jobs-sort-box'>
                    <div className='jobs-sort-header'>Sort</div>
                    <DropDownWithLabel className="sort-by" id="sort-by-id" name="sort-by" label="Sort By" value={sortOptions.sortBy} 
                        handleDropDown={(e) => setSortOptions({...sortOptions, sortBy: e.target.value})} addBlankOption={true} 
                        selectionList={Jobs.getSortOptions()} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="sort-order" id="sort-order-id" name="sort-order" label="Sort Order" value={sortOptions.order} 
                        handleDropDown={(e) => setSortOptions({...sortOptions, order: e.target.value})} addBlankOption={true} 
                        selectionList={[{id: "ascending", display: "Ascending"}, {id: "descending", display: "Descending"}]} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <Button className="sort-jobs" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await applyFilters()}})} handleClick={(async (e) => await applyFilters())} buttonText="Apply" width="120"/>
                </div>
            </div>
            <div className='page-header'>
                {canCreateJobs && <Button className="add-new-job" handleClick={(async (e) => setNewJob(Jobs.createNewJobNew(await Auth.getUserId())))} buttonText="Add Job" buttonMargin="0 0 0 0" width="120" height="20"/>}
                <Button className="export-to-xlsx" handleClick={((e) => exportJobsToXLSX())} buttonText="Export To XLSX" buttonMargin="0 0 0 0" width="120" height="20"/>
                <div className='header-title'>Jobs</div>
            </div>
            {newJob &&
            <div className='new-job-box'>
                <div className='new-job-header'>Add New Job</div>
                <CheckboxWithLabel className="active" name="active" label="Active" checked={newJob.active} handleCheckbox={((e) => setNewJob({...newJob, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <DropDownWithLabel className="builder-dropdown" id="builder-dropdown-id" name="builder" label="Builder" handleDropDown={(e) => setNewJob({...newJob, builder_id: e.target.value})} addBlankOption={true} selectionList={buildersForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <TextboxWithLabel className="number-textbox" name="number" label="Job Number" placeholder="Job Number" value={newJob.number} handleTextbox={((e) => setNewJob({...newJob, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <TextboxWithLabel className="project-name-textbox" name="project-name" label="Project Name" placeholder="Project Name" value={newJob.project_name} handleTextbox={((e) => setNewJob({...newJob, project_name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <DropDownWithLabel className="type-dropdown" id="type-dropdown-id" name="type" label="Type" handleDropDown={(e) => setNewJob({...newJob, job_type_id: e.target.value})} addBlankOption={true} selectionList={jobTypes.map(type => ({...type, display: type.description}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <TextboxWithLabel className="cross-roads-textbox" name="cross-roads" label="Cross Roads" placeholder="Cross Roads" value={newJob.cross_streets} handleTextbox={((e) => setNewJob({...newJob, cross_streets: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <TextboxWithLabel className="city-textbox" name="city" label="City" placeholder="City" value={newJob.city} handleTextbox={((e) => setNewJob({...newJob, city: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <TextboxWithLabel className="zip-code-textbox" name="zip-code" label="Zip Code" placeholder="Zip Code" value={newJob.zip_code} handleTextbox={((e) => setNewJob({...newJob, zip_code: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <div className="optional-general-super-labels">
                    <div className="optional-label">Opt</div>
                    <div className="general-label">Generals</div>
                    <div className="super-label">Supers</div>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-concrete-checkbox scope-checkbox" name="has-concrete" label="Concrete" checked={hasAttribute(newJob, 1)} handleCheckbox={((e) => handleAttributeNewJob(1))} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="concrete-general-dropdown general-dropdown" id="concrete-general-dropdown-id" name="concrete-general" label="Concrete General" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 2)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="concrete-super-dropdown super-dropdown" id="concrete-super-dropdown-id" name="concrete-super" label="Concrete Super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 1)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-plumbing-checkbox scope-checkbox" name="has-plumbing" label="Plumbing" checked={hasAttribute(newJob, 2) || hasAttribute(newJob, 3) || hasAttribute(newJob, 4)} handleCheckbox={((e) => {handleAttributeNewJob(2); handleAttributeNewJob(3); handleAttributeNewJob(4);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="plumbing-general-dropdown general-dropdown" id="plumbing-general-dropdown-id" name="plumbing-general" label="Plumbing General" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 4)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="plumbing-super-dropdown super-dropdown" id="plumbing-super-dropdown-id" name="plumbing-super" label="Plumbing Super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 3)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="plumbing-gas-box">
                    <CheckboxWithLabel className="has-plumbing-gas-checkbox scope-checkbox" name="has-plumbing-gas" label="Plumbing-Gas" checked={hasAttribute(newJob, 5)} handleCheckbox={((e) => {handleAttributeNewJob(5);})} columns="62" labelStart="1" labelEnd="55" inputStart="55" inputEnd="60"/>
                    <Checkbox className="is-plumbing-gas-optional-checkbox opt-checkbox" name="is-plumbing-gas-optional" disabled={!hasAttribute(newJob, 5)} checked={hasAttribute(newJob, 6)} handleCheckbox={((e) => {handleAttributeNewJob(6);})} columns="1" inputStart="1" inputEnd="2"/>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-masonry-checkbox scope-checkbox" name="has-masonry" label="Masonry" checked={hasAttribute(newJob, 9)} handleCheckbox={((e) => {handleAttributeNewJob(9);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="masonry-general-dropdown general-dropdown" id="masonry-general-dropdown-id" name="masonry-general" label="Masonry General" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 6)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="masonry-super-dropdown super-dropdown" id="masonry-super-dropdown-id" name="masonry-super" label="Masonry Super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 5)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-gates-checkbox scope-checkbox" name="has-gates" label="Gates" checked={hasAttribute(newJob, 12)} handleCheckbox={((e) => {handleAttributeNewJob(12);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="gates-general-dropdown general-dropdown" id="gates-general-dropdown-id" name="gates-general" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 14)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="gates-super-dropdown super-dropdown" id="gates-super-dropdown-id" name="gates-super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 13)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="pavers-selection-box">
                    <CheckboxWithLabel className="has-pavers-checkbox scope-checkbox" name="has-pavers" label="Pavers" checked={hasAttribute(newJob, 10)} handleCheckbox={((e) => {handleAttributeNewJob(10);})} columns="62" labelStart="1" labelEnd="55" inputStart="55" inputEnd="60"/>
                    <Checkbox className="is-pavers-optional-checkbox opt-checkbox" name="is-pavers-optional" disabled={!hasAttribute(newJob, 10)} label="Is Pavers Optional" checked={hasAttribute(newJob, 11)} handleCheckbox={((e) => {handleAttributeNewJob(11);})} columns="1" inputStart="1" inputEnd="2"/>
                    <DropDown className="paver-general-dropdown general-dropdown" id="paver-general-dropdown-id" name="paver-general" label="Paver General" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 8)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="paver-super-dropdown super-dropdown" id="paver-super-dropdown-id" name="paver-super" label="Paver Super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 7)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-rough-final-grade-checkbox scope-checkbox" name="has-rough-final-grade" label="Rough/Final Grade" checked={hasAttribute(newJob, 7)} handleCheckbox={((e) => {handleAttributeNewJob(7);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="rfg-general-dropdown general-dropdown" id="rfg-general-dropdown-id" name="rfg-general" label="RFG General" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 10)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="rfg-super-dropdown super-dropdown" id="rfg-super-dropdown-id" name="rfg-super" label="RFG Super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 9)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-trash-checkbox scope-checkbox" name="has-trash" label="Trash" checked={hasAttribute(newJob, 8)} handleCheckbox={((e) => {handleAttributeNewJob(8);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="trash-general-dropdown general-dropdown" id="trash-general-dropdown-id" name="trash-general" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 16)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="trash-super-dropdown super-dropdown" id="trash-super-dropdown-id" name="trash-super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 15)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="scope-general-super-selection-box">
                    <CheckboxWithLabel className="has-framing-checkbox scope-checkbox" name="has-framing" label="Framing" checked={hasAttribute(newJob, 13)} handleCheckbox={((e) => {handleAttributeNewJob(13);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                    <DropDown className="framing-general-dropdown general-dropdown" id="framing-general-dropdown-id" name="framing-general" label="Framing General" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 12)} addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                    <DropDown className="framing-super-dropdown super-dropdown" id="framing-super-dropdown-id" name="framing-super" label="Framing Super" handleDropDown={(e) => handleAssignmentNewJob(e.target.value, 11)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                </div>
                <div className="two-button-box">
                    <Button className="submit-new-job" handleKeyPress={((e) => {if(e.key === 'Enter'){saveNewJob()}})} handleClick={((e) => saveNewJob())} buttonText="Submit" width="120"/>
                    <Button className="cancel-add-new-job" handleKeyPress={((e) => {if(e.key === 'Enter'){setNewJob(null)}})} handleClick={((e) => setNewJob(null))} buttonText="Cancel" width="120"/>
                </div>
            </div>}
            <div className="table-header">
                {columns.map((column, index)=> {
                    return <div key={`${column}-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                })}
            </div>
            {jobs.map((job, index) => {
                if(canEditJobs && jobToEdit && (jobToEdit.id === job.id)){
                    return(
                        <div key={`${job.id}-${index}`} className='edit-job-box'>
                            <div className='edit-job-header'>Edit Job</div>
                            <CheckboxWithLabel className="active-checkbox" name="active" label="Active" checked={jobToEdit.active} handleCheckbox={((e) => setJobToEdit({...jobToEdit, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <DropDownWithLabel className="builder-dropdown" id="builder-dropdown-id" name="builder" label="Builder" value={jobToEdit.builder_id} handleDropDown={(e) => setJobToEdit({...jobToEdit, builder_id: e.target.value})} addBlankOption={true} selectionList={buildersForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                            <TextboxWithLabel className="number-textbox" name="number" label="Job Number" placeholder="Job Number" value={jobToEdit.number} handleTextbox={((e) => setJobToEdit({...jobToEdit, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                            <TextboxWithLabel className="project-name-textbox" name="project-name" label="Project Name" placeholder="Project Name" value={jobToEdit.project_name} handleTextbox={((e) => setJobToEdit({...jobToEdit, project_name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                            <DropDownWithLabel className="category-dropdown" id="category-dropdown-id" name="category" label="Category" value={jobToEdit.job_type_id} handleDropDown={(e) => setJobToEdit({...jobToEdit, job_type_id: e.target.value})} addBlankOption={true} selectionList={jobTypes.map(type => ({...type, display: type.description}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                            <TextboxWithLabel className="cross-roads-textbox" name="cross-roads" label="Cross Roads" placeholder="Cross Roads" value={jobToEdit.cross_streets} handleTextbox={((e) => setJobToEdit({...jobToEdit, cross_streets: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                            <TextboxWithLabel className="city-textbox" name="city" label="City" placeholder="City" value={jobToEdit.city} handleTextbox={((e) => setJobToEdit({...jobToEdit, city: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                            <TextboxWithLabel className="zip-code-textbox" name="zip-code" label="Zip Code" placeholder="Zip Code" value={jobToEdit.zip_code} handleTextbox={((e) => setJobToEdit({...jobToEdit, zip_code: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                            <div className="optional-general-super-labels">
                                <div className="optional-label">Opt</div>
                                <div className="general-label">Generals</div>
                                <div className="super-label">Supers</div>
                            </div>
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-concrete-checkbox scope-checkbox" name="has-concrete" label="Concrete" checked={hasAttribute(jobToEdit, 1)} handleCheckbox={((e) => handleAttributeJobToEdit(1))} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="concrete-general-dropdown general-dropdown" id="concrete-general-dropdown-id" name="concrete-general" label="Concrete General" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 2) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 2).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 2)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="concrete-super-dropdown super-dropdown" id="concrete-super-dropdown-id" name="concrete-super" label="Concrete Super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 1) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 1).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 1)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                                
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-plumbing-checkbox scope-checkbox" name="has-plumbing" label="Plumbing" checked={hasAttribute(jobToEdit, 2) || hasAttribute(jobToEdit, 3) || hasAttribute(jobToEdit, 4)} handleCheckbox={((e) => {handleAttributeJobToEdit(2); handleAttributeJobToEdit(3); handleAttributeJobToEdit(4);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="plumbing-general-dropdown general-dropdown" id="plumbing-general-dropdown-id" name="plumbing-general" label="Plumbing General" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 4) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 4).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 4)}addBlankOption={true}selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="plumbing-super-dropdown super-dropdown" id="plumbing-super-dropdown-id" name="plumbing-super" label="Plumbing Super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 3) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 3).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 3)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="plumbing-gas-box">
                                <CheckboxWithLabel className="has-plumbing-gas-checkbox scope-checkbox" name="has-plumbing-gas" label="Plumbing-Gas" checked={hasAttribute(jobToEdit, 5)} handleCheckbox={((e) => {handleAttributeJobToEdit(5);})}columns="62" labelStart="1" labelEnd="55" inputStart="55" inputEnd="60"/>
                                <Checkbox className="is-plumbing-gas-optional-checkbox opt-checkbox" name="is-plumbing-gas-optional" disabled={!hasAttribute(jobToEdit, 5)} checked={hasAttribute(jobToEdit, 6)} handleCheckbox={((e) => {handleAttributeJobToEdit(6);})}columns="1" inputStart="1" inputEnd="2"/>
                            </div>
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-masonry-checkbox scope-checkbox" name="has-masonry" label="Masonry" checked={hasAttribute(jobToEdit, 9)} handleCheckbox={((e) => {handleAttributeJobToEdit(9);})}columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="masonry-general-dropdown general-dropdown" id="masonry-general-dropdown-id" name="masonry-general" label="Masonry General" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 6) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 6).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 6)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="masonry-super-dropdown super-dropdown" id="masonry-super-dropdown-id" name="masonry-super" label="Masonry Super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 5) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 5).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 5)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-gates-checkbox scope-checkbox" name="has-gates" label="Gates" checked={hasAttribute(jobToEdit, 12)} handleCheckbox={((e) => {handleAttributeJobToEdit(12);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="gates-general-dropdown general-dropdown" id="gates-general-dropdown-id" name="gates-general" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 14) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 14).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 14)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="gates-super-dropdown super-dropdown" id="gates-super-dropdown-id" name="gates-super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 13) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 13).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 13)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="pavers-selection-box">
                                <CheckboxWithLabel className="has-pavers-checkbox scope-checkbox" name="has-pavers" label="Pavers" checked={hasAttribute(jobToEdit, 10)} handleCheckbox={((e) => {handleAttributeJobToEdit(10);})} columns="62" labelStart="1" labelEnd="55" inputStart="55" inputEnd="60"/>
                                <Checkbox className="is-pavers-optional-checkbox opt-checkbox" name="is-pavers-optional" disabled={!hasAttribute(jobToEdit, 10)} label="Is Pavers Optional" checked={hasAttribute(jobToEdit, 11)} handleCheckbox={((e) => {handleAttributeJobToEdit(11);})}columns="1" inputStart="1" inputEnd="2"/>
                                <DropDown className="paver-general-dropdown general-dropdown" id="paver-general-dropdown-id" name="paver-general" label="Paver General" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 8) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 8).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 8)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="paver-super-dropdown super-dropdown" id="paver-super-dropdown-id" name="paver-super" label="Paver Super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 7) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 7).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 7)} addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-rough-final-grade-checkbox scope-checkbox" name="has-rough-final-grade" label="Rough/Final Grade" checked={hasAttribute(jobToEdit, 7)} handleCheckbox={((e) => {handleAttributeJobToEdit(7);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="rfg-general-dropdown general-dropdown" id="rfg-general-dropdown-id" name="rfg-general" label="RFG General" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 10) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 10).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 10)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="rfg-super-dropdown super-dropdown" id="rfg-super-dropdown-id" name="rfg-super" label="RFG Super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 9) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 9).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 9)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-trash-checkbox scope-checkbox" name="has-trash" label="Trash" checked={hasAttribute(jobToEdit, 8)} handleCheckbox={((e) => {handleAttributeJobToEdit(8);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="trash-general-dropdown general-dropdown" id="trash-general-dropdown-id" name="trash-general" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 16) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 16).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 16)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="trash-super-dropdown super-dropdown" id="trash-super-dropdown-id" name="trash-super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 15) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 15).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 15)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="scope-general-super-selection-box">
                                <CheckboxWithLabel className="has-framing-checkbox scope-checkbox" name="has-framing" label="Framing" checked={hasAttribute(jobToEdit, 13)} handleCheckbox={((e) => {handleAttributeJobToEdit(13);})} columns="10" labelStart="1" labelEnd="9" inputStart="9" inputEnd="10"/>
                                <DropDown className="framing-general-dropdown general-dropdown" id="framing-general-dropdown-id" name="framing-general" label="Framing General" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 12) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 12).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 12)}addBlankOption={true} selectionList={usersForGeneralsDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                                <DropDown className="framing-super-dropdown super-dropdown" id="framing-super-dropdown-id" name="framing-super" label="Framing Super" value={jobToEdit.assignments.find(assignment => assignment.assignment_id === 11) !== undefined ? jobToEdit.assignments.find(assignment => assignment.assignment_id === 11).user_id : ''} handleDropDown={(e) => handleAssignmentJobToEdit(e.target.value, 11)}addBlankOption={true} selectionList={usersForSupersDropDowns} columns={10} inputStart={2} inputEnd={10}/>
                            </div>
                            <div className="two-button-box">
                                <Button className="submit-edit-job" handleKeyPress={((e) => {if(e.key === 'Enter'){updateJob()}})} handleClick={((e) => updateJob())} buttonText="Submit" width="120"/>
                                <Button className="cancel-edit-job" handleKeyPress={((e) => {if(e.key === 'Enter'){cancelUpdateJob()}})} handleClick={((e) => cancelUpdateJob())} buttonText="Cancel" width="120"/>
                            </div>
                        </div>
                    );
                }
                const position = index === 0 ? 'first' : index === (jobs.length - 1) ? 'last' : '';
                return (
                    <div key={`${job.id}-${index}`} className={`grid-table-row ${job.active ? '' : 'in-active'}`}>
                        <div className={`grid-table-row-data ${position} left-align far-left`} onClick={(e) => setJobToEdit({...job})}>{job.builder.name}</div>
                        <div className={`grid-table-row-data ${position} left-align`} onClick={(e) => setJobToEdit({...job})}>{job.project_name}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setJobToEdit({...job})}>{job.type.description}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setJobToEdit({...job})}>{job.number}</div>
                        <div className={`grid-table-row-data ${position} left-align`} onClick={(e) => setJobToEdit({...job})}>{job.city}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 1) ? '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 2) || hasAttribute(job, 3) || hasAttribute(job, 4) ? '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 5) ? hasAttribute(job, 6) ? 'Opt' : '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 7) ? '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 8) ? '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 9) ? '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 10) ? hasAttribute(job, 11) ? 'Opt' : '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 12) ? '\u2713' : ''}</div>
                        <div className={`grid-table-row-data ${position} bold far-right`} onClick={(e) => setJobToEdit({...job})}>{hasAttribute(job, 13) ? '\u2713' : ''}</div>
                    </div>
                )
            })}
            {errorNoBuilder && <Popup color={PopupFailureColor} message={"Please add a builder."} handlePopup={resetErrorPopups}/>}
            {errorNoJobNumber && <Popup color={PopupFailureColor} message={"Please enter a job number."} handlePopup={resetErrorPopups}/>}
            {errorNoJobType && <Popup color={PopupFailureColor} message={"Please select a job type."} handlePopup={resetErrorPopups}/>}
            {errorNoProjectName && <Popup color={PopupFailureColor} message={"Please enter a project name."} handlePopup={resetErrorPopups}/>}
            {errorDuplicateJobNumber && <Popup color={PopupFailureColor} message={"Please enter a unique job number"} handlePopup={resetErrorPopups}/>}
            {errorIncorrectJobNumberFormat && <Popup color={PopupFailureColor} message={"Please enter a 4 digit job number"} handlePopup={resetErrorPopups}/>}
            {newJobSuccess && <Popup color={PopupSuccessColor} message={'The new job has been saved to the database'} handlePopup={resetSuccessPopups}/>}
            {editJobSuccess && <Popup color={PopupSuccessColor} message={'The job has been updated and saved to the database'} handlePopup={resetSuccessPopups}/>}
        </JobsPageContainer>
    );
}

export default JobsPage;
