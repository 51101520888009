import {useState} from 'react';
import { useNavigate } from 'react-router';

import MainHeaderContainer, {Spacer} from './styles/MainHeaderContainer.js';

import logo from './../../img/HeaderLogo.png';

import Config from '../../config'
import Auth from "../authentication/Auth.js";
import Can from "../authentication/Can.js";
import {Navbar} from "../navigation/Navbar";
import SingleNavLink from "../navigation/SingleNavLink";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import {Menu} from "@mui/material";
import {LockReset, Logout, Password, PowerOff, PowerSettingsNew, WarningAmber} from "@mui/icons-material";
import MainHeaderAlert from './MainHeaderAlert.js';

import {Tooltip, withStyles} from "@mui/material";

import 'react-tooltip/dist/react-tooltip.css'

//import { buildType } from '../../api/index.js';

const MainHeader = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [menu, setMenu] = useState([]);

    const [didLoadData, setDidLoadData] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);

        setName(await Auth.getFullName());

        const tempPermissions = await Auth.getPermissions();
        const tempMenu = [];


        // Builders
        if (Can.isAuthorizedRead(tempPermissions, 'builders')) {
            tempMenu.push('Builders');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'builders')) {
            tempMenu.push('-- Builders Page');
        }

        // // Jobs
        if (Can.isAuthorizedRead(tempPermissions, 'jobs')) {
            tempMenu.push('Jobs');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'jobs')) {
            tempMenu.push('-- Jobs Page');
        }

        // Lots
        if (Can.isAuthorizedRead(tempPermissions, 'startsLots') || Can.isAuthorizedRead(tempPermissions, 'lots') ||
            Can.isAuthorizedRead(tempPermissions, 'evenFlow')) {
            tempMenu.push('Lots');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'lots')) {
            tempMenu.push('-- View Lots');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'lots')) {
            tempMenu.push('-- Create Lot');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'lots')) {
            tempMenu.push('-- Edit Lot');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'lots')) {
            tempMenu.push('-- Load Lots');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'startsLots')) {
            tempMenu.push('-- Starts Lots');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'evenFlow')) {
            tempMenu.push('-- Even Flow');
        }

        // Schedule
        if (Can.isAuthorizedRead(tempPermissions, 'schedule') || Can.isAuthorizedRead(tempPermissions, 'dailySchedule') || Can.isAuthorizedRead(tempPermissions, 'pourLog')) {
            tempMenu.push('Schedule');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'schedule')) {
            tempMenu.push('-- Schedule');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'dailySchedule')) {
            tempMenu.push('-- Daily Schedule');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'pourLog')) {
            tempMenu.push('-- Pour Log');
        }

        // Crews
        if (Can.isAuthorizedRead(tempPermissions, 'employees') || Can.isAuthorizedRead(tempPermissions, 'crews') || Can.isAuthorizedRead(tempPermissions, 'prospectiveEmployees')) {
            tempMenu.push('Crews');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'employees')) {
            tempMenu.push('-- Employees Page');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'crews')) {
            tempMenu.push('-- Crews Page');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'prospectiveEmployees')) {
            tempMenu.push('-- Prospective Employees');
        }

        // Field Forms
        if (Can.isAuthorizedRead(tempPermissions, 'ewa') || Can.isAuthorizedRead(tempPermissions, 'fieldForms')) {
            tempMenu.push('Field');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'fieldForms')) {
            tempMenu.push('-- Field Forms');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'ewa')) {
            tempMenu.push('-- Extra Work Authorization');
        }
        // if (Can.isAuthorizedRead(tempPermissions, 'ewa')) {
        //     tempMenu.push('-- Task Adjustments');
        // }
        if (Can.isAuthorizedRead(tempPermissions, 'gateInformation')) {
            tempMenu.push('-- Gates');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'scheduleRequest')) {
            tempMenu.push('-- SVC Schedule Request');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'scheduleUpdates')) {
            tempMenu.push('-- Schedule Updates');
        }

        //Reports
        if (Can.isAuthorizedRead(tempPermissions, 'reports')) {
            tempMenu.push('Reports');
        }
        if (Can.isAuthorizedRead(tempPermissions, 'reports')) {
            tempMenu.push('-- Reports');
        }

        // Users
        if (Can.isAdmin(tempPermissions)) {
            tempMenu.push('Admin');
            tempMenu.push('-- Admin');
        }

        setMenu([...tempMenu]);
    }

    if (!didLoadData) {
        loadData();
    }

    const handleMenuSelection = (e) => {
        switch (e.target.value) {
            case '-- Change Password':
                navigate('/users/change-password');
                break;
            case '-- User Permissions':
                navigate('/user/permissions');
                break;
            case '-- View Users':
                navigate('/users/view');
                break;
            case '-- Create User':
                navigate('/users/create');
                break;
            case '-- Edit User':
                navigate('/users/edit');
                break;
            case '-- Delete User':
                navigate('/users/delete');
                break;
            case '-- Builders Page':
                navigate('/builders-page');
                break;
            case '-- Jobs Page':
                navigate('/jobs-page');
                break;
            case '-- View Lots':
                navigate('/lots/view');
                break;
            case '-- Even Flow':
                navigate('/lots/evenflow');
                break;
            case '-- Create Lot':
                navigate('/lots/create');
                break;
            case '-- Load Lots':
                navigate('/lots/load');
                break;
            case '-- Edit Lot':
                navigate('/lots/edit');
                break;
            case '-- Delete Lot':
                navigate('/lots/delete');
                break;
            case '-- Reporting':
                navigate('/reporter');
                break;
            case '-- Daily Schedule':
                navigate('/daily-schedule');
                break;
            case '-- Pour Log':
                navigate('/pour-log');
                break;
            case '-- Messaging':
                navigate('/messager');
                break;
            case '-- Field Utilities':
                navigate('/field-forms');
                break;
            case '-- Field Forms':
                navigate('/field-forms');
                break;
            case '-- Starts Lots':
                navigate('/lots/starts');
                break;
            case '-- Employees Page':
                navigate('/employees-page');
                break;
            case '-- Crews Page':
                navigate('/crews-page');
                break;
            case '-- Extra Work Authorization':
                navigate('/ewa');
                break;
            case '-- Task Adjustments':
                navigate('/task-adjustment');
                break;
            case '-- Gates':
                navigate('/gates');
                break;
            case '-- Prospective Employees':
                navigate('/prospective-employees');
                break;
            case '-- Schedule':
                navigate('/schedule');
                break;
            case '-- Reports':
                navigate('/reports');
                break;
            case '-- Admin':
                navigate('/admin-page');
                break;
            case '-- SVC Schedule Request':
                navigate('/svc-schedule-request');
                break;
            case '-- Schedule Updates':
                navigate('/schedule-updates');
                break;
            default:
                console.log('Default Case');
        }
    }

    const handleLogOut = (e) => {
        Auth.logout();
        navigate('/login');
    }

    const handleChangePassword = (e) => {
        navigate('/change-password');
    }

    return (
        <Spacer>
            <MainHeaderContainer id='main-header'>
                <div className="main-header-logo" onClick={(e) => navigate('/home')}>
                    <picture>
                        <img sizes="(max-width: 236px) 100vw, 236px"
                             srcSet={`${logo} 100w, ${logo} 236w`}
                             src={logo}
                             alt="SVC Logo"/>
                    </picture>
                </div>
                <div className={"desktop-nav"}>
                    <div className='title-name-box'>
                        {Config.BUILD_TYPE() === 'DEV' ?
                        <div className="main-header-title dev-build">### Dev Build ###</div> :
                        <div className="main-header-title">Field Management System</div>}
                        <MainHeaderAlert/>
                        <Tooltip id={"user-name-tooltip"} title={"This is You"} placement={"left-start"} arrow>
                            <div className="main-header-user-name">{name}</div>
                        </Tooltip>
                        <div className={"main-header-user-buttons-div"}>
                            <Tooltip id={"logout-tooltip"} title={"This Will Log You Out Of FMS"} placement={"left-start"} arrow>
                                <PowerSettingsNew className={"main-header-user-buttons logout"} onClick={handleLogOut}/>
                            </Tooltip>
                            <Tooltip id={"change-password-tooltip"} title={"This Will Change Your FMS Password"} placement={"right-start"} arrow>
                                <LockReset className={"main-header-user-buttons change-password"} onClick={handleChangePassword}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="main-header-drop-down">
                        {/*<label className="main-header-drop-down-label" htmlFor='menu'>Navigation</label>*/}
                        {/*<select className="main-header-drop-down-select" name='menu' onChange={handleMenuSelection}>*/}
                        {/*    <option className="main-header-drop-down-option" value={-1}></option>*/}
                        {/*    {menu.map((selectable, index) => <option className="main-header-drop-down-option"*/}
                        {/*                                             key={index}*/}
                        {/*                                             value={selectable}>{selectable}</option>)}*/}
                        {/*</select>*/}
                        <Navbar/>
                    </div>
                </div>
                <div className={"mobile-nav"}>
                    <div className='title-name-box'>
                        <div className='username-logout-box'>
                            <div className="main-header-user-name">{name}</div>
                            <div className={"main-header-user-buttons-div"}>
                                <PowerSettingsNew className={"main-header-user-buttons"} title={"Logout"}
                                                  onClick={handleLogOut}/>
                                <LockReset className={"main-header-user-buttons"} title={"Change Password"}
                                           onClick={handleChangePassword}/>
                            </div>
                        </div>
                    </div>

                    <div className="main-header-drop-down">
                        <label className="main-header-drop-down-label" htmlFor='menu'>Navigation</label>
                        <select className="main-header-drop-down-select" name='menu' onChange={handleMenuSelection}>
                            <option className="main-header-drop-down-option" value={-1}></option>
                            {menu.map((selectable, index) => <option className="main-header-drop-down-option"
                                                                     key={index}
                                                                     value={selectable}>{selectable}</option>)}
                        </select>
                        <Navbar/>
                    </div>
                </div>
            </MainHeaderContainer>
        </Spacer>
    );
}

export default MainHeader;
