import {useState} from 'react';
import { useNavigate } from 'react-router';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import styled from 'styled-components';
import dayjs from "dayjs";

import Auth from "../authentication/Auth.js";

export const createAlert = async (message, link, permissionGroupIdsArray) => {
    await Auth.hitEndpointNew("POST", "alert", "", {
        message: message,
        link: link,
        permissionGroups: permissionGroupIdsArray.map(pg => {
            return {permission_group_id: pg};
        })
    });
}

const MainHeaderAlertContainer = styled.div`
    grid-column: 10/12;
    grid-row: 1/2;
    display: grid;
    justify-content: end;
    align-items: center;
    width: 100%;
    padding-right: 10px;
    z-index: 100;

    .alert-tooltip{
        opacity: 1.0;
    }
    
    .alert-icon{
        font-size: 22px;
        cursor: default;
        color: yellow;
    }

    .alert-popup-div{
        display: grid;
        width: 300px;

        .alert-button-div{
            display: grid;
            align-content: center;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 8px;
            border-bottom: 3px solid white;

            .alert-button{
                width: 120px;
                height: 20px;
                margin: 5px auto;
            }
        }
    }
 `

const MainHeaderAlert = (props) => {
    const navigate = useNavigate();

    const [didLoadData, setDidLoadData] = useState(false);

    const [alerts, setAlerts] = useState([]);
    const [user, setUser] = useState(null);

    const loadData = async () => {
        setDidLoadData(true);

        const tempUser = (await Auth.hitEndpointNew("GET", `user/${await Auth.getUserId()}`))[0];
        setUser(tempUser);
        setAlerts(await Auth.hitEndpointNew("GET", "alerts", `jsonString=permission_group_id=${tempUser.permission_group_id} and dismissed_at is null`));
    }

    if (!didLoadData) {
        loadData();
    }

    const handleGoTo = (alert) => {
        navigate(alert.link);
    }

    const handleDismiss = async (alert) => {
        const tempAlerts = [...alerts];
        const index = tempAlerts.findIndex(a => parseInt(a.id) === parseInt(alert.id));
        if(index >= 0){
            tempAlerts[index].dismissed_by = await Auth.getUserId();
            tempAlerts[index].dismissed_at = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');

            await Auth.hitEndpointNew("PATCH", "alert", "", tempAlerts[index]);

            setAlerts(await Auth.hitEndpointNew("GET", "alerts", `jsonString=permission_group_id=${user.permission_group_id} and dismissed_at is null`));
        }
    }

    if(alerts.length === 0){
        return null;
    }

    return (
        <MainHeaderAlertContainer>
            <a data-tooltip-id={`alert-tooltip-id`} data-tooltip-place="bottom" data-tooltip-delay-hide={1000}>
                <div className="alert-icon">{'\u{026A0}'}</div>
            </a>
            <Tooltip id={`alert-tooltip-id`} className="alert-tooltip" clickable>
                {alerts.map((a, index) => {
                    const text = `${a.message}`;
                    return (
                        <div key={`${a.message}-${index}`} className="alert-popup-div">
                            <div className="alert-message">{text}</div>
                            <div className="alert-button-div">
                                <button className="alert-button goto" onClick={(e) => handleGoTo(a)}>Go To</button>
                                <button className="alert-button dimiss" onClick={(e) => handleDismiss(a)}>Dismiss</button>
                            </div>
                        </div>
                    )
                })}
            </Tooltip>
        </MainHeaderAlertContainer>
    );
}

export default MainHeaderAlert;