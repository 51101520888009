//I HATE THIS BUT HAD TO DO IT

import {useMemo, useState} from "react";
import {darken, lighten} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {createTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Schedules from "../../../../classes/Schedules";

const DetailEdit = ({schedule, setSchedule, detailRows, setEditDetails, selectedTasks, setOpen, suppliers}) => {

    const handleSaveCell = (e, newValue, cell, row, detailId) => {

        row.original[cell.column.id] = newValue === null || newValue === "" ? "" : newValue.toString()
        let details = [...detailRows]
        let detail = details.find(x => x.schedule_id === row.original.schedule_id)

        let scheduleRow = schedule.find(x => x.schedule_id === row.original.schedule_id)

        let current
        if(detail.abc !== undefined){
            current = scheduleRow.detail.find(x => x.id === detail.abc_key_detail_id)
        }
        else{
            current = scheduleRow.detail.find(x => x.id === detail.yards_key_detail_id)
        }
        current.actual_value = row.original[cell.column.id]
        detail[cell.column.id] = row.original[cell.column.id]
        setEditDetails(details)

    }

    const gridColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorFn: (row) => `${row.lot.phase} ${row.lot.number}`,
                    header: 'Lot',
                },
            ]
            for (let t = 0; t < selectedTasks.length; t++) {
                for (let d = 0; d < selectedTasks[t].detail.length; d++) {
                    if(selectedTasks[t].detail[d].type.actuals){
                        columns.push(
                            {
                                accessorKey: selectedTasks[t].detail[d].type.key,
                                header: selectedTasks[t].detail[d].type.name,
                                size: 20,
                                enableEditing: true,
                                Edit: ({ cell, row }) => {
                                    return (
                                        <TextField
                                            placeholder={selectedTasks[t].detail[d].type.name}
                                            type={selectedTasks[t].detail[d].type.type}
                                            defaultValue={cell.getValue()}
                                            // value={cell.getValue()}
                                            onBlur={(e) => handleSaveCell(e, e.target.value, cell, row, selectedTasks[t].detail[d].id)}
                                        ></TextField>
                                    )
                                }
                            }
                        )
                    }
                    else {
                        columns.push(
                            {
                                accessorKey: selectedTasks[t].detail[d].type.key,
                                header: selectedTasks[t].detail[d].type.name,
                                size: 20,
                                enableEditing: false
                            }
                        )
                    }
                }
            }
            return columns
        }
    );

    const handleDetailCancel = () => {
        setOpen(false)
    }

    const handleDetailSave = () => {

        let detail = []
        for(let r=0; r<detailRows.length; r++){
            detail.push({
                id: detailRows[r].yards_key_detail_id,
                actual_value: detailRows[r].yards //TODO need to not hard code to yards for this but for now this is the only value being updated
            })
        }
        Schedules.updateDetail({
            data: detail
        })

        setOpen(false)
    }

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'

    return (
        <div>
            <MaterialReactTable
                id="schedule-detail-table"
                columns={gridColumns}
                data={detailRows}
                rowCount={detailRows.length}
                enableGrouping
                enableExpanding={false}
                enableExpandAll={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableFilters={false}
                enableHiding={false}
                enableEditing={true}
                editDisplayMode="table"
                enableColumnActions={true}
                muiEditTextFieldProps={({cell, row}) => ({
                    onBlur: event => {
                        try {
                            handleSaveCell(event, event.target.value, cell, row, selectedTasks[0].detail[cell.column.index].id);
                        } catch (e) {

                        }
                    }
                })}
                muiTableHeadCellProps={({column}) => ({
                    sx: {
                        '& .MuiTableSortLabel-icon': {
                            color: column.getIsSorted() ? 'limegreen !important' : undefined
                        },
                        backgroundColor: '#D3D3D3'
                    }
                })}
                muiTableBodyCellProps={({cell, row}) => ({
                    sx: {
                        height: 10,
                        fontStyle: 'italic',
                        border: 'none',
                        backgroundColor:
                                    row.original.index % 2 === 0 ?
                                        darken(baseBackgroundColor, 0.1) :
                                        lighten(baseBackgroundColor, 0.3)
                        ,
                        borderTop: cell.row.depth === 1 ? 'solid 1pt' : row.depth === 0 ? 'solid 2pt' : 'none',
                        borderLeft: cell.column.id === "mrt-row-actions" ? 'solid 1pt' : 'none',
                        borderRight: cell.column.id === "employee" ? 'solid 1pt' : 'none',
                        paddingBottom: "1px",
                    }
                })}
                initialState={{
                    showColumnFilters: false,
                    pagination: {pageSize: 100, pageIndex: 0},
                    density: 'compact',
                }}
            />
        </div>
    )
}

export default DetailEdit;
