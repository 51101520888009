
import MainHeader from "../utilities/MainHeader";
import {Paper} from "@mui/material";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Can from "../authentication/Can";
import SingleNavLink from "../navigation/SingleNavLink";
import {useState} from "react";
import Auth from "../authentication/Auth";
import AdminPageContainer from './styles/AdminPageContainer';
import Sidebar from "../home-screen/Sidebar";
import moment from "moment";


const AdminPage = () => {

    const [permissions, setPermissions] = useState([]);
    const [message, setMessage] = useState(null);
    const [devMessage, setDevMessage] = useState(null);

    const [didLoadMessages, setDidLoadMessages] = useState(false);
    const [didLoadData, setDidLoadData] = useState(false);

    const loadData = async () => {

        const tempPermissions = await Auth.getPermissions();
        setPermissions(tempPermissions)
        setDidLoadData(true);
    }

    if (!didLoadData) {
        loadData();
    }

    const loadMessages = async () => {
        setDidLoadMessages(true);
        const result = await Auth.hitEndpointNew("GET", "messages");
        for (let i = 0; i < result.length; i++) {
            if (result[i].type === 'message' && result[i].message.length > 0) {
                // Checks if messages are expired
                if (result[i].expires !== undefined) {
                    let date = result[i].expires
                    result[i].expires = new Date(date);
                    const today = new Date();

                    if (result[i].expires < today) {// TODO update current message
                        result[i].expires = null;
                        result[i].message = '';
                        result[i].modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
                        await Auth.hitEndpointNew("PATCH", "message", "", result[i]);
                    }
                }
                setMessage(result[i]);
            }
            if (result[i].type === 'dev-message' && result[i].message.length > 0) {
                // Checks if messages are expired
                if (result[i].expires) {
                    let date = result[i].expires
                    result[i].expires = new Date(date);
                    const today = new Date();

                    if (result[i].expires < today) {
                        result[i].expires = null;
                        result[i].message = '';
                        result[i].modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
                        await Auth.hitEndpointNew("PATCH", "message", "", result[i]);
                    }
                }
                setDevMessage(result[i]);
            }
        }
    }

    if (!didLoadMessages) {
        loadMessages();
    }

    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <AdminPageContainer>
            <MainHeader/>
            <Sidebar/>
            <div className='info-box admin-full'>
                <Item className={"admin-users"}>
                    <Box id="category-users"
                         sx={{fontSize: '12px', textTransform: 'uppercase'}}>Users</Box>
                    <Box component="ul" aria-labelledby="category-users" sx={{pl: 2}}>
                        {Can.isAuthorizedRead(permissions, 'userPermissions') &&
                            <li><SingleNavLink className={'tile'} label={"User Permissions"}
                                               route={"/user/permissions"}/></li>}
                        {Can.isAuthorizedRead(permissions, 'users') &&
                            <div>
                                <li><SingleNavLink className={'tile'} label={"View Users"}
                                                   route={"/users/view"}/></li>
                                <li><SingleNavLink className={'tile'} label={"Create Users"}
                                                   route={"/users/create"}/></li>
                                <li><SingleNavLink className={'tile'} label={"Edit Users"}
                                                   route={"/users/edit"}/></li>
                                <li><SingleNavLink className={'tile'} label={"Reset Password"}
                                                   route={"/users/change-password"}/></li>
                            </div>}
                    </Box>
                </Item>
                <Item className={"admin-messaging"}>
                    <Box id="category-app" sx={{fontSize: '12px', textTransform: 'uppercase'}}>App
                        Settings</Box>
                    <Box component="ul" aria-labelledby="category-app" sx={{pl: 2}}>
                        {Can.isAuthorizedRead(permissions, 'messaging') &&
                            <li><SingleNavLink className={'tile'} label={"Messaging"}
                                               route={"/messager"}/>
                            </li>}
                        {Can.isAuthorizedRead(permissions, 'emailSetup') &&
                            <li><SingleNavLink className={'tile'} label={"Email Setup"} route={"/email/setup"}/></li>}
                        {Can.isAuthorizedRead(permissions, 'phaseCodes') &&
                            <li><SingleNavLink className={'tile'} label={"Phase Codes"} route={"/phase-codes"}/></li>}
                    </Box>
                </Item>
            </div>
        </AdminPageContainer>
    )
};


export default AdminPage;